import { Route } from "MainRoutes";
import { useNavigate } from "react-router";

export interface ResaleNavigate {
  to: (route: Route) => void;
  toId: (route: Route, id: string) => void;
}

const useNav = () => {
  const navigator = useNavigate();

  const navigate: ResaleNavigate = {
    to: (route: Route) => {
      navigator(route);
    },
    toId: (route: Route, id: string) => {
      navigator(route.replace(":id", id));
    },
  };

  return [navigate];
};

export default useNav;
