import { InventoryItemFormListing } from "api/GeneratedApiClients";
import Icon from "components/Icon";
import { TableSimpleSort, tableFieldsFactory } from "components/Table";
import { stringUtils } from "utils/stringUtils";

export const listingFieldsFactory = () => {
  const listingSort: TableSimpleSort<InventoryItemFormListing> = {
    field: "listDate",
    direction: "desc",
  };

  const listingFields = tableFieldsFactory<InventoryItemFormListing>({
    title: {
      label: "Name",
      sortable: true,
      width: "040",
    },
    listPrice: {
      label: "List Price",
      sortable: true,
      body: (listPrice: number | undefined) => {
        if (listPrice === undefined) {
          return "";
        }

        return stringUtils.formatAsCurrency(listPrice);
      },
      width: "010",
    },
    listDate: {
      label: "List Date",
      sortable: true,
      isDate: true,
      width: "010",
    },
    saleDate: {
      label: "End Date",
      sortable: true,
      isDate: true,
      width: "010",
    },
    isSold: {
      label: "Is Sold?",
      sortable: true,
      body: (isSold: boolean | undefined) => {
        if (isSold === undefined) {
          return "";
        }
        return isSold ? "Yes" : "No";
      },
      width: "010",
    },
    id: {
      label: "",
      body: (_: string, form: InventoryItemFormListing) => {
        return (
          <div className="flex flex-direction-row">
            <Icon
              name="external-link"
              onClick={() => window.open(form.eBayUrl, "_blank")}
              className="mr-3"
            />
          </div>
        );
      },
      width: "005",
    },
  });

  return { listingFields, listingSort };
};
