import React, { useContext, useRef, useState } from "react";
import Webcam from "react-webcam";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import styles from "./webcam.module.scss";
import "react-image-crop/dist/ReactCrop.css";
import { useDebounceEffect } from "hooks/useDebounceEffect";
import { canvasPreview } from "./canvasPreview";
import Button from "components/Button";
import { ConfigContext } from "config/ConfigProvider";

enum ScreenState {
  Capture = 1,
  Crop = 2,
  Preview = 3,
}

interface WebcamProps {
  onCapture?: (imageSrc: string) => void;
  onClose?: () => void;
}

const WebcamCapture = (props: WebcamProps) => {
  const { onCapture, onClose } = props;

  const [screenState, setScreenState] = useState<ScreenState>(ScreenState.Capture);
  const [image, setImage] = useState("");
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | undefined>(undefined);

  const config = useContext(ConfigContext);
  const cropIsEnabled = !config.disableCropForWebcam;

  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  const webcamRef = useRef<Webcam>(null);

  const videoConstraints: MediaStreamConstraints["video"] = {
    facingMode: "environment",
    // width: dimensions.width,
    // height: dimensions.height,
    // width: 358,
    // height: 641,
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop, screenState]
  );

  const hasCompletedCrop = () => {
    if (completedCrop === undefined) {
      return false;
    }

    if (completedCrop.height === 0 && completedCrop.width === 0) {
      return false;
    }

    return true;
  };

  const captureAction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const imageSrc = webcamRef?.current?.getScreenshot() ?? "";
    setImage(imageSrc);
    setScreenState(ScreenState.Crop);
  };

  const retakeAction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    resetWebcam();
  };

  const recropAction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setScreenState(ScreenState.Crop);
  };

  const acceptAction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();

    if (screenState === ScreenState.Preview && hasCompletedCrop()) {
      new Promise((resolve, reject) => {
        if (previewCanvasRef?.current === undefined) {
          reject("Preview Canvas is not defined.");
        }

        previewCanvasRef?.current?.toBlob((file: Blob | null) => {
          if (file === null) {
            reject("File contains no data.");
          }

          onCapture && onCapture(URL.createObjectURL(file as Blob));
          resetWebcam();
          resolve(file);
        }, "image/jpeg");
      });
    } else {
      fetch(image)
        .then((res) => res.blob())
        .then((blob) => {
          onCapture && onCapture(URL.createObjectURL(blob));
          resetWebcam();
        });
    }
  };

  const previewAction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setScreenState(ScreenState.Preview);
  };

  const resetWebcam = () => {
    setImage("");
    setCrop(undefined);
    setCompletedCrop(undefined);
    setScreenState(ScreenState.Capture);
  };

  const Display = () => {
    if (screenState === ScreenState.Capture) {
      return (
        <Webcam
          className={styles.camera}
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/png"
          videoConstraints={videoConstraints}
          forceScreenshotSourceSize={true}
          screenshotQuality={1}
        />
      );
    }

    if (screenState === ScreenState.Crop) {
      return (
        <ReactCrop
          className={styles.cropper}
          crop={crop}
          onChange={(c) => cropIsEnabled && setCrop(c)}
          onComplete={(c) => cropIsEnabled && setCompletedCrop(c)}
        >
          <img ref={imgRef} src={image} alt="Cropping the snapshot from the webcam" />
        </ReactCrop>
      );
    }

    return (
      <div>
        <canvas ref={previewCanvasRef} />
        <img
          ref={imgRef}
          src={image}
          style={{ display: "none" }}
          alt="Preview of the cropped snapshot"
        />
      </div>
    );
  };

  const LeftAction = () => {
    let elements = <Button type="secondary" icon="eye" label="Re-crop" onClick={recropAction} />;

    if (screenState === ScreenState.Capture) {
      elements = <div />;
    }
    if (screenState === ScreenState.Crop) {
      elements = <div />;

      if (cropIsEnabled) {
        elements = (
          <Button
            type="secondary"
            icon="eye"
            label="Crop"
            isDisabled={!hasCompletedCrop()}
            onClick={previewAction}
          />
        );
      }
    }

    return (
      <div className={styles.left}>
        <div>{elements}</div>
      </div>
    );
  };

  const MiddleAction = () => {
    if (screenState === ScreenState.Capture) {
      return <Button type="primary" isRounded icon="camera" onClick={captureAction} />;
    }

    return (
      <Button
        type="primary"
        isRounded
        icon="refresh"
        onClick={retakeAction}
        className={styles.middle}
      />
    );
  };

  const RightAction = () => {
    let elements = <Button type="success" icon="check" onClick={acceptAction} />;

    if (screenState === ScreenState.Capture) {
      elements = <div />;
    }
    if (screenState === ScreenState.Crop) {
      elements = (
        <Button
          type="success"
          icon="check"
          onClick={acceptAction}
          isDisabled={hasCompletedCrop()}
        />
      );
    }

    return (
      <div className={styles.right}>
        <div>{elements}</div>
      </div>
    );
  };

  return (
    <div className={styles.webcamContainer}>
      <div className={styles.close}>
        <Button type="secondary" isRounded icon="times" onClick={() => onClose && onClose()} />
      </div>
      <div className={styles.camera}>{Display()}</div>
      <div className={styles.actions}>
        <LeftAction />
        <MiddleAction />
        <RightAction />
      </div>
    </div>
  );
};

export default WebcamCapture;
