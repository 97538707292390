import classNames from "classnames";
import { SplitButton as PrimeReactSplitButton } from "primereact/splitbutton";
import { PrimeIcons } from "utils/PrimeReact/PrimeIcons";
import { PropsWithClassName } from "utils/PropsWithClassName";

import style from "./index.module.scss";
import { MenuItem, MenuItemCommandEvent } from "primereact/menuitem";

export type ButtonType = "primary" | "secondary" | "success" | "warning" | undefined;
export type ButtonSubtype = "fill" | "outlined" | "text-only" | undefined;

export interface SubActionProps {
  label: string;
  icon?: PrimeIcons;
  onClick?: (e: MenuItemCommandEvent) => void;
}

export interface ButtonWithSubActionsProps {
  label: string;
  icon: PrimeIcons;
  type?: ButtonType;
  subtype?: ButtonSubtype;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  subActions?: SubActionProps[];
  isDisabled?: boolean | undefined;
}

const ButtonWithSubActions = (props: PropsWithClassName<ButtonWithSubActionsProps>) => {
  const { label, icon, type, onClick, subActions, isDisabled, className } = props;

  // const roundedClass = isRounded ? "p-button-rounded" : undefined;
  const typeClass =
    type === "primary"
      ? style.primaryButton
      : type === "secondary"
      ? "p-button-secondary"
      : type === "success"
      ? "p-button-success"
      : type === "warning"
      ? "p-button-danger"
      : undefined;

  // const subtypeClass =
  //   subtype === "outlined"
  //     ? "p-button-outlined"
  //     : subtype === "text-only"
  //     ? "p-button-text"
  //     : undefined;

  // const isHidden = hidden === true ? "none" : undefined;

  const buttonIcon = icon !== undefined ? "pi pi-" + icon : undefined;

  const subActionModels: MenuItem[] | undefined = (subActions ?? []).map((s) => {
    const item: MenuItem = {
      label: s.label,
      icon: s.icon !== undefined ? "pi pi-" + s.icon : undefined,
      command: (e: MenuItemCommandEvent) => {
        if (e && s.onClick) {
          s.onClick(e);
        }
      },
    };

    return item;
  });

  return (
    <PrimeReactSplitButton
      label={label}
      icon={buttonIcon}
      onClick={(e) => {
        if (e && onClick) {
          onClick(e);
        }
      }}
      model={subActionModels}
      className={classNames("resale-minder-button", className, typeClass)}
      disabled={isDisabled}
    />
  );
};

export default ButtonWithSubActions;
