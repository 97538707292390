import { InventoryItemFormDocument } from "api/GeneratedApiClients";
import classNames from "classnames";
import WebcamCapture from "components/Camera/webcam";
import { MediaBreakpoints } from "components/Layout/MediaBreakpoints";
import { Sidebar } from "primereact/sidebar";
import { Dialog } from "primereact/dialog";
import uuid from "utils/uuid";

import style from "./index.module.scss";

export interface NewDocumentModalProps {
  isVisible: boolean;
  onHide: () => void;
  onSave: (newDocument: InventoryItemFormDocument) => void;
}

const NewDocumentModal = (props: NewDocumentModalProps) => {
  const { isVisible, onHide, onSave } = props;

  const onCapture = (imageUrl: string) => {
    const newDocument: InventoryItemFormDocument = {
      id: uuid.new(),
      imageUrl: imageUrl,
    };

    onSave(newDocument);
  };

  return (
    <>
      <MediaBreakpoints.OnMobile>
        <Sidebar
          className={classNames("newDocumentModal", style.sidebar)}
          visible={isVisible}
          fullScreen
          onHide={onHide}
          showCloseIcon={false}
        >
          <WebcamCapture onCapture={onCapture} onClose={onHide} />
        </Sidebar>
      </MediaBreakpoints.OnMobile>
      <MediaBreakpoints.OnDesktop>
        <Dialog
          className={classNames("newDocumentModal", style.sidebar)}
          visible={isVisible}
          onHide={onHide}
          blockScroll
          closable={false}
          style={{ minWidth: "500px" }}
        >
          <WebcamCapture onCapture={onCapture} onClose={onHide} />
        </Dialog>
      </MediaBreakpoints.OnDesktop>
    </>
  );
};

export default NewDocumentModal;
