// import { InventoryFormItem } from "api";
import { InventoryFormItem } from "api/GeneratedApiClients";
import Icon from "components/Icon";
import { tableFieldsFactory } from "components/Table";
import { routeFill as routeHydrate } from "MainRoutes";
import { Link } from "react-router-dom";
import dateUtils from "utils/dateUtils";
import { stringUtils } from "utils/stringUtils";
import style from "./index.module.scss";
import { Moment } from "moment";

export const inventoryTableFieldsFactory = () => {
  const fields = tableFieldsFactory<InventoryFormItem>({
    name: {
      label: "Name",
      body: (name: string | undefined, i: InventoryFormItem) => {
        const link = <Link to={routeHydrate.item(i.id ?? "unknown")}>{name}</Link>;

        if (i.saleDate !== undefined && i.saleDate !== null) {
          const soldDate = dateUtils.format(i.saleDate);
          return (
            <>
              <Link to={routeHydrate.item(i.id ?? "unknown")}>{name}</Link>
              <Icon
                disableBoop
                name="tag"
                className={style.soldTag}
                tooltipOptions={{
                  tooltip: `Sold on ${soldDate}`,
                }}
              />
            </>
          );
        }

        return link;
      },
      sortable: true,
      width: "050",
    },
    purchasePrice: {
      label: "Price",
      body: (purchasePrice: number) => {
        return stringUtils.formatAsCurrency(purchasePrice);
      },
      sortable: true,
      width: "025",
    },
    purchaseDate: {
      label: "Purchased",
      body: (purchaseDate: Moment) => {
        return dateUtils.format(purchaseDate);
      },
      width: "035",
    },
  });

  return fields;
};
