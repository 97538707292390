import classNames from "classnames";
import Icon from "components/Icon";
import useKeyboardShortcut from "hooks/useKeyboardShortcut";
import { InputText } from "primereact/inputtext";
import { useRef } from "react";

import style from "./index.module.scss";

export interface SearchProps {
  value: string;
  rightAlign?: true;
  isLoading?: boolean;
  onChange: (newValue: string) => void;
  onSubmit: (value: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFocus?: (event: any & { target: any }) => void;
}

const Search = (props: SearchProps) => {
  const { value, rightAlign, isLoading, onChange, onSubmit, onFocus } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const focusSearchBar = () => {
    const element = inputRef?.current;
    if (element) {
      element.focus();
    }
  };

  const focusSearchBarOrExecuteSearch = () => {
    if (value.length > 0) {
      onSubmit(value);
      console.log("Already focused - execute onSearch function");
      return;
    }

    focusSearchBar();
  };

  useKeyboardShortcut(["Alt", "P"], focusSearchBar, {
    ignoreInputFields: true,
  });

  const InputElement = (alignmentClass?: string | undefined) => (
    <InputText
      ref={inputRef}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onSubmit(value);
        }
      }}
      placeholder="Search"
      className={classNames(style.searchBar, alignmentClass)}
      onFocus={(e) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (onFocus) onFocus(e as any);
        e.target.select();
      }}
      disabled={isLoading}
    />
  );

  const IconElement = (
    <Icon
      name={isLoading ? "spinner" : "search"}
      isSpinning={isLoading}
      onClick={() => {
        focusSearchBarOrExecuteSearch();
      }}
    />
  );

  if (rightAlign) {
    return (
      <span className={style.searchContainer}>
        {InputElement(style.alignRight)}
        {IconElement}
      </span>
    );
  }

  return (
    <span className={style.searchContainer}>
      {IconElement}
      {InputElement()}
    </span>
  );
};

export default Search;
