import Icon from "components/Icon";
import style from "./index.module.scss";
import { useEffectOnLoad } from "hooks/useEffectOnLoad";
import useStatusIconStore from "./store/useStatusIconStore";

const StatusIcon = () => {
  const [state, actions] = useStatusIconStore();

  useEffectOnLoad(async () => {
    actions.loadPage();
  });

  if (state.isLoading) {
    return <Icon name="spinner" isSpinning disableBoop />;
  }

  if (state.form.isSuccessful) {
    return <></>;
  }

  return (
    <Icon
      name="exclamation-triangle"
      disableBoop
      size="large"
      className={style.status}
      tooltipOptions={{
        tooltip: "System is down. Please contact system admin for help.",
        mouseTrack: true,
        mouseTrackTop: 5,
        mouseTrackLeft: 15,
      }}
    />
  );
};

export default StatusIcon;
