import { HealthCheckClient, HealthCheckForm } from "api/GeneratedApiClients";
import toastUtils from "utils/toastUtils";
import { ActionMethodParameters } from "hooks/useStore";
import { StatusIconPageAction, StatusIconPageActionType } from "./statusIconActions";

export interface StatusIconActionMethods {
  loadPage: () => void;
  unloadPage: () => void;
}

const getReducerActions = function (params: ActionMethodParameters<StatusIconPageAction>) {
  const dispatch = params.dispatch;
  return {
    showLoader: () => dispatch({ type: StatusIconPageActionType.ShowLoader }),
    initialLoad: (form: HealthCheckForm, isNew: boolean) => {
      dispatch({
        type: StatusIconPageActionType.InitialLoad,
        form: form,
        isNew: isNew,
      });
    },
    showInvalid: (form: HealthCheckForm) => {
      toastUtils.showInvalidMessage();
      dispatch({
        type: StatusIconPageActionType.ShowInvalid,
        form: form,
      });
    },
    showSuccess: (form: HealthCheckForm) => {
      toastUtils.showSaveSuccessMessage();
      dispatch({
        type: StatusIconPageActionType.ShowSuccess,
        form: form,
      });
    },
    showError: (error: string) => {
      toastUtils.showErrorMessage(error);
      dispatch({
        type: StatusIconPageActionType.ShowError,
        error: error,
      });
    },
  };
};

export const getStatusIconPageActions = (
  params: ActionMethodParameters<StatusIconPageAction>
): StatusIconActionMethods => {
  const { apiErrorHandler, dataClientFactory } = params;

  if (dataClientFactory === undefined) {
    throw new Error("Unable to retrieve data without a DataClientFactory.");
  }

  const reducerActions = getReducerActions(params);

  const { showLoader, initialLoad, showInvalid, showError } = reducerActions;

  const defaultErrorHandlingOptions = (error: unknown, invalidObject: HealthCheckForm | null) => {
    const showErrorAction = () =>
      showError("System is down. Please contact system admin for help.");
    apiErrorHandler.handle({
      error: error,
      errorAction: showErrorAction,
      invalidAction: () =>
        invalidObject === null ? showErrorAction() : showInvalid(invalidObject),
    });
  };

  const unloadPage = () => undefined;

  const loadPage = async () => {
    showLoader();

    try {
      const healthCheckClient = dataClientFactory.getClient(HealthCheckClient);
      const healthCheckForm = await healthCheckClient.get();

      if (healthCheckForm !== null) {
        initialLoad(healthCheckForm, false);
      } else {
        defaultErrorHandlingOptions(
          new Error("Unable to initially retrieve inventory item"),
          healthCheckForm
        );
      }
    } catch (error: unknown) {
      defaultErrorHandlingOptions(error, null);
    }
  };

  return {
    loadPage,
    unloadPage,
  };
};
