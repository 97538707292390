import { ExpenseForm } from "api/GeneratedApiClients";
import useStore from "../../../hooks/useStore";
import objectUtils from "../../../utils/objectUtils";
import {
  ExpenseModal,
  getInventoryPageActions,
  InventoryActionMethods,
} from "./expenseActionMethods";
import { ExpensePageAction, ExpensePageActionType } from "./expenseActions";

export interface PageState {
  form: ExpenseForm | undefined;
  expenseModal?: ExpenseModal | undefined;
  deleteModal: { isVisible: boolean; title?: string; message?: string; onConfirm?: () => void };
  isLoading: boolean;
}

const initialPageState: PageState = {
  form: undefined,
  deleteModal: { isVisible: false },
  isLoading: true,
};

const pageReducer = function (readonlyState: PageState, action: ExpensePageAction): PageState {
  const mutableState = objectUtils.deepCopyObject(readonlyState);

  switch (action.type) {
    case ExpensePageActionType.ShowLoader:
      mutableState.isLoading = true;
      break;

    case ExpensePageActionType.InitialLoad:
    case ExpensePageActionType.ShowSuccess:
    case ExpensePageActionType.ShowInvalid:
      mutableState.form = action.form;
      mutableState.isLoading = false;
      break;

    case ExpensePageActionType.ShowError:
      mutableState.isLoading = false;
      break;
    case ExpensePageActionType.Save:
      if (mutableState.form) {
        action.updater(mutableState.form);
      }
      break;
    case ExpensePageActionType.Submit:
      mutableState.form = action.form;
      mutableState.isLoading = false;
      break;
    case ExpensePageActionType.ToggleExpenseModal: {
      const { options } = action;

      mutableState.expenseModal = options;
      break;
    }
    case ExpensePageActionType.ToggleDeleteModal: {
      const { isVisible, title, message, onConfirm } = action;

      mutableState.deleteModal = {
        isVisible: isVisible,
        title: title,
        message: message,
        onConfirm: onConfirm,
      };

      break;
    }
  }

  return mutableState;
};

const useExpenseStore = (): [PageState, InventoryActionMethods] => {
  return useStore(initialPageState, pageReducer, getInventoryPageActions);
};

export default useExpenseStore;
