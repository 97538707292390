import { HealthCheckForm } from "api/GeneratedApiClients";
import useStore from "hooks/useStore";
import objectUtils from "utils/objectUtils";
import { getStatusIconPageActions, StatusIconActionMethods } from "./statusIconActionMethods";
import { StatusIconPageAction, StatusIconPageActionType } from "./statusIconActions";

export interface PageState {
  form: HealthCheckForm;
  isLoading: boolean;
  isInitialized: boolean;
}

const initialPageState: PageState = {
  form: {
    isSuccessful: false,
  },
  isLoading: true,
  isInitialized: false,
};

const pageReducer = function (readonlyState: PageState, action: StatusIconPageAction): PageState {
  const mutableState = objectUtils.deepCopyObject(readonlyState);

  switch (action.type) {
    case StatusIconPageActionType.ShowLoader:
      mutableState.isLoading = true;
      break;

    case StatusIconPageActionType.InitialLoad:
      mutableState.form = action.form;
      mutableState.isInitialized = true;
      mutableState.isLoading = false;
      break;
    case StatusIconPageActionType.ShowInvalid:
    case StatusIconPageActionType.ShowSuccess:
      mutableState.form = action.form;
      mutableState.isLoading = false;
      break;
    case StatusIconPageActionType.ShowError:
      mutableState.isLoading = false;
      break;
  }

  return mutableState;
};

const useStatusIconStore = (): [PageState, StatusIconActionMethods] => {
  return useStore(initialPageState, pageReducer, getStatusIconPageActions);
};

export default useStatusIconStore;
