import { Toast } from "primereact/toast";

let growl: Toast | null;
export const ToastHost: React.FC = () => {
  return <Toast ref={(el: Toast) => (growl = el)} />;
};

export const toaster = {
  showInfo: (summary: string, detail: string) => {
    growl?.show({ severity: "info", summary: summary, detail: detail });
  },
  showSuccess: (summary: string, detail: string) => {
    growl?.show({ severity: "success", summary: summary, detail: detail });
  },
  showWarn: (summary: string, detail: string) => {
    growl?.show({ severity: "warn", summary: summary, detail: detail });
  },
  showError: (summary: string, detail: string, sticky?: true | undefined) => {
    growl?.show({
      severity: "error",
      summary: summary,
      detail: detail,
      life: sticky ? 20000 : undefined,
    });
  },
  clear: () => {
    growl?.clear();
  },
};
