import { useMedia } from "react-use";
import variables from "../styles/_variables.module.scss";

const useResaleMedia = () => {
  const mediaLarge = variables.mediaLarge;

  const isBiggerThanMobile = useMedia(`(min-width: ${mediaLarge})`);

  return {
    isMobile: !isBiggerThanMobile,
    isDesktop: isBiggerThanMobile,
  };
};

export default useResaleMedia;
