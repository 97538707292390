import { ProfileForm } from "api";
export enum ProfilePageActionType {
  ShowLoader = 0,
  InitialLoad = 1,
  ShowSuccess = 2,
  ShowError = 3,
  ShowInvalid = 4,
  ChangePageViews = 5,
  Save = 6,
  Submit = 7,
}
interface ShowLoaderAction {
  type: ProfilePageActionType.ShowLoader;
}
interface InitialLoadAction {
  type: ProfilePageActionType.InitialLoad;
  form: ProfileForm;
}
interface ShowSuccessAction {
  type: ProfilePageActionType.ShowSuccess;
  form: ProfileForm;
}
interface ShowErrorAction {
  type: ProfilePageActionType.ShowError;
  error: string;
}
interface ShowInvalidAction {
  type: ProfilePageActionType.ShowInvalid;
  form: ProfileForm;
}
interface ChangePageViewsAction {
  type: ProfilePageActionType.ChangePageViews;
  isEditing?: boolean | undefined;
}
interface SaveAction {
  type: ProfilePageActionType.Save;
  updater: (form: ProfileForm) => void;
}
interface SubmitPageAction {
  type: ProfilePageActionType.Submit;
  form: ProfileForm;
}
export type ProfilePageAction =
  | ShowLoaderAction
  | InitialLoadAction
  | ShowSuccessAction
  | ShowErrorAction
  | ShowInvalidAction
  | ChangePageViewsAction
  | SaveAction
  | SubmitPageAction;
