import HomePage from "pages/home";
import InventoryPage from "pages/inventory";
import InventoryItemPage from "pages/inventoryItem";
import LoginPage from "pages/login";
import ProfilePage from "pages/profile";
import SettingsPage from "pages/settings";
import GainLossPage from "pages/gainLoss";
import { Route as ReactRoute, Routes as ReactRoutes } from "react-router-dom";
import ExpensePage from "pages/expense";
import { ValueOf } from "utils/valueOf";
import HelpPage from "pages/help";

export const Routes = {
  home: "/",
  item: "/inventory/:id",
  inventory: "/inventory",
  expenses: "/expenses",
  invoices: "/invoices",
  login: "/login",
  user: "/user",
  settings: "/settings",
  profile: "/profile",
  gainLoss: "/gain-loss",
  help: "/help",
} as const;

export type Route = ValueOf<typeof Routes>;

const idString = ":id";

export const routeFill = {
  item: (itemId: string): string => {
    return Routes.item.replace(idString, itemId);
  },
};

export const MainRoutes = () => {
  return (
    <ReactRoutes>
      <ReactRoute path={Routes.home} element={<HomePage />} caseSensitive />
      <ReactRoute path={Routes.inventory} element={<InventoryPage />} />
      <ReactRoute path={Routes.item} element={<InventoryItemPage />} />
      <ReactRoute path={Routes.expenses} element={<ExpensePage />} />
      <ReactRoute path={Routes.gainLoss} element={<GainLossPage />} />
      <ReactRoute path={Routes.profile} element={<ProfilePage />} />
      <ReactRoute path={Routes.settings} element={<SettingsPage />} />
      <ReactRoute path={Routes.help} element={<HelpPage />} />
      <ReactRoute path={Routes.login} element={<LoginPage />} />
    </ReactRoutes>
  );
};
