import { ExpenseFormFee, ExpenseFormFeeAmount } from "api/GeneratedApiClients";
import Input, { InputType } from "components/Input";
import moment, { Moment } from "moment";
import Calendar from "components/Calendar";
import Button from "components/Button";
import Table, { TableSimpleSort, tableFieldsFactory } from "components/Table";
import uuid from "utils/uuid";
import { useState } from "react";
import objectUtils from "utils/objectUtils";
import { Dialog } from "primereact/dialog";
import Icon from "components/Icon";

import style from "./index.module.scss";
import { stringUtils } from "utils/stringUtils";
import { useEffectOnLoad } from "hooks/useEffectOnLoad";
import listUtils from "utils/listUtils";
import dateUtils from "utils/dateUtils";

interface ExpenseFeeModalProps {
  form: ExpenseFormFee | undefined;
  isVisible: boolean;
  onSave: (form: ExpenseFormFee) => void;
  onHide: () => void;
}

type PartialFee = Omit<Partial<ExpenseFormFee>, "amounts"> & { amounts: ExpenseFormFeeAmount[] };
type PartialFeeAmount = Partial<ExpenseFormFeeAmount>;

const ExpenseFeeModal = (props: ExpenseFeeModalProps) => {
  const { form: nullableForm, isVisible, onSave, onHide } = props;

  const newFee: PartialFee = {
    id: uuid.new(),
    name: "",
    description: "",
    amounts: [],
    lastUpdated: moment(),
  };

  const defaultAmountFactory = (): PartialFeeAmount => {
    return {
      amount: 0,
      reason: "",
      date: undefined,
      lastUpdated: moment(),
    };
  };

  const [state, setState] = useState<PartialFee>(newFee);
  const [newAmount, setNewAmount] = useState<PartialFeeAmount>(defaultAmountFactory);
  const [showNewAmount, setShowNewAmount] = useState<boolean>(false);

  const setNewState = (updater: (newFee: PartialFee) => void) => {
    const newState = objectUtils.deepCopyObject(state);
    updater(newState);
    setState(newState);
  };

  /**
   * TODO: Find an alternative way to intercept the value of new state on saving the form.
   */
  const addNewAmountToFeeIfSufficientlyComplete = (interceptor?: (newFee: PartialFee) => void) => {
    setNewState((newState: PartialFee) => {
      if (newAmount && newAmount.amount !== undefined && newAmount.amount > 0) {
        const newAmountForFee: ExpenseFormFeeAmount = {
          id: uuid.new(),
          amount: newAmount.amount,
          reason: newAmount.reason ?? "",
          date: newAmount.date ?? moment(),
          lastUpdated: moment(),
        };

        const updatedList = listUtils.insert(newAmountForFee, newState.amounts);
        newState.amounts = updatedList;
      }

      if (interceptor) {
        interceptor(newState);
      }
    });

    setNewAmount(defaultAmountFactory());
  };

  const setNewAmountState = (updater: (newFeeAmount: PartialFeeAmount) => void) => {
    const newAmountState = objectUtils.deepCopyObject(newAmount);
    updater(newAmountState);
    setNewAmount(newAmountState);
  };

  useEffectOnLoad(
    () => {
      setNewState((newState: PartialFee) => {
        newState.id = nullableForm?.id ?? uuid.new();
        newState.name = nullableForm?.name;
        newState.description = nullableForm?.description ?? "";
        newState.amounts = nullableForm?.amounts ?? [];
        newState.lastUpdated = nullableForm?.lastUpdated ?? moment();
      });
    },
    undefined,
    [nullableForm]
  );

  const simpleSort: TableSimpleSort<ExpenseFormFeeAmount> = {
    field: "date",
    direction: "desc",
  };

  const amountFields = tableFieldsFactory<ExpenseFormFeeAmount>({
    // TODO: Edit in place: https://primereact.org/datatable/#cell_edit
    amount: {
      label: "Amount",
      body: (amount: number) => {
        return stringUtils.formatAsCurrency(amount);
      },
      sortable: true,
      width: "025",
    },
    reason: { label: "Reason", sortable: true, width: "040" },
    date: {
      label: "Date",
      body: (lastUpdated: Moment) => {
        return moment(lastUpdated).format("M/D/yyyy");
      },
      isDate: true,
      sortable: true,
      width: "025",
    },
    id: {
      label: "",
      body: (_: string, feeForm: ExpenseFormFeeAmount) => {
        return (
          <div className="flex flex-direction-row">
            <Icon
              name="trash"
              className={style.delete}
              onClick={() => {
                setNewState((newState: PartialFee) => {
                  const amountsWithoutThisAmount = listUtils.removeItem(feeForm, newState.amounts);
                  newState.amounts = amountsWithoutThisAmount;
                });
              }}
            />
          </div>
        );
      },
      width: "010",
    },
  });

  const title = nullableForm === undefined ? "Add Fee" : "Edit Fee";

  return (
    <Dialog
      visible={isVisible}
      header={
        <>
          <span className="pr-2">{title}</span>
          <Icon name="exclamation-circle" />
        </>
      }
      footer={
        <div className="flex align-items-center justify-content-between">
          <i>Last Update: {dateUtils.format(state.lastUpdated)}</i>
          <Button
            label="Save"
            type="primary"
            icon="plus-circle"
            onClick={() => {
              addNewAmountToFeeIfSufficientlyComplete((finalState: PartialFee) => {
                const updatedFee: ExpenseFormFee = {
                  id: finalState.id ?? uuid.new(),
                  name: finalState.name ?? "",
                  description: finalState.description ?? "",
                  lastUpdated: moment(),
                  amounts: finalState.amounts,
                };

                onSave(updatedFee);
              });
            }}
          />
        </div>
      }
      closable
      closeOnEscape
      draggable
      style={{ width: "60%", minWidth: "850px" }}
      onHide={() => onHide()}
    >
      <div className="formgrid grid">
        <div className="field col-12">
          <i>
            Fees are often recurring or periodic. For multiple instances of a fee, add a new amount
            in the amounts section below
          </i>
        </div>
      </div>
      <div className="formgrid grid">
        <div className="field col-4">
          <Input
            id="name"
            type={InputType.Text}
            label="Name"
            icon="box"
            value={state.name}
            onChange={(newValue: string | undefined) => {
              setNewState((newState: PartialFee) => (newState.name = newValue ?? ""));
            }}
          />
        </div>
        <div className="field col-8">
          <Input
            id="description"
            type={InputType.Text}
            label="Description"
            icon="book"
            value={state.description}
            onChange={(newValue: string | undefined) => {
              setNewState((newState: PartialFee) => (newState.description = newValue ?? ""));
            }}
          />
        </div>
      </div>
      <div className="formgrid grid ">
        <div className="field col-12 flex justify-content-end">
          <Button
            label={showNewAmount ? "Hide new amount" : "Show new amount"}
            type="primary"
            subtype="text-only"
            icon={showNewAmount ? "eye-slash" : "eye"}
            onClick={() => {
              setShowNewAmount(!showNewAmount);
            }}
          />
        </div>
      </div>
      {showNewAmount ? (
        <>
          <div className="formgrid grid">
            <div className="field col-3">
              <Input
                id="amount"
                type={InputType.Currency}
                label="New Amount"
                value={newAmount.amount}
                onChange={(newValue: number | undefined) => {
                  setNewAmountState((updater) => (updater.amount = newValue ?? 0));
                }}
                selectOnFocus
              />
            </div>
            <div className="field col-5">
              <Input
                id="reason"
                type={InputType.Text}
                label="Reason"
                icon="question"
                value={newAmount.reason}
                onChange={(newValue: string | undefined) => {
                  setNewAmountState((updater) => (updater.reason = newValue ?? ""));
                }}
              />
            </div>
            <div className="field col-3">
              <Calendar
                id="date"
                label="Date of fee"
                iconPosition="left"
                singleValue={newAmount.date}
                maxDate={moment()}
                onChange={(newValue: Moment | undefined) => {
                  setNewAmountState((updater) => (updater.date = newValue ?? moment()));
                }}
              />
            </div>
            <div className="field col-1 flex align-items-end justify-content-end">
              <Button
                type="secondary"
                icon="plus"
                onClick={() => addNewAmountToFeeIfSufficientlyComplete()}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="formgrid grid">
        <div className="field col-12">
          <Table
            fields={amountFields}
            values={state.amounts}
            simpleSort={simpleSort}
            itemsPerPage={[5, 10, 15]}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ExpenseFeeModal;
