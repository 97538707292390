/* eslint-disable @typescript-eslint/no-namespace */
import useResaleMedia from "hooks/useResaleMedia";
import { PropsWithChildren } from "react";

// TODO: https://betterprogramming.pub/how-to-use-media-queries-programmatically-in-react-4d6562c3bc97
export namespace MediaBreakpoints {
  export const OnMobile = (props: PropsWithChildren) => {
    const { children } = props;

    const { isMobile } = useResaleMedia();

    if (isMobile) {
      return <div className="mobile">{children}</div>;
    }

    return null;
  };

  export const OnDesktop = (props: PropsWithChildren) => {
    const { children } = props;

    const { isDesktop } = useResaleMedia();

    if (isDesktop) {
      return <div className="desktop">{children}</div>;
    }

    return null;
  };
}
