import { SearchClient, SearchForm } from "api/GeneratedApiClients";
import toastUtils from "utils/toastUtils";
import { PageState } from "./useSearchStore";
import { ActionMethodParameters } from "hooks/useStore";
import { SearchPageAction, SearchPageActionType } from "./searchActions";

export interface SearchActionMethods {
  savePage: (updater: (form: SearchForm) => void) => void;
  submitPage: (page: PageState) => void;
}

const getReducerActions = function (params: ActionMethodParameters<SearchPageAction>) {
  const dispatch = params.dispatch;
  return {
    showLoader: () => dispatch({ type: SearchPageActionType.ShowLoader }),
    showInvalid: (form: SearchForm) => {
      toastUtils.showInvalidMessage();
      dispatch({
        type: SearchPageActionType.ShowInvalid,
        form: form,
      });
    },
    showSuccess: (form: SearchForm) => {
      toastUtils.showSaveSuccessMessage();
      dispatch({
        type: SearchPageActionType.ShowSuccess,
        form: form,
      });
    },
    showError: (error: string) => {
      toastUtils.showErrorMessage(error);
      dispatch({
        type: SearchPageActionType.ShowError,
        error: error,
      });
    },
    savePageAction: (updater: (form: SearchForm) => void) => {
      dispatch({
        type: SearchPageActionType.Save,
        updater: updater,
      });
    },
    submitPageAction: (form: SearchForm) => {
      dispatch({
        type: SearchPageActionType.Submit,
        form: form,
      });
    },
  };
};

export const getSearchPageActions = (
  params: ActionMethodParameters<SearchPageAction>
): SearchActionMethods => {
  const { apiErrorHandler, dataClientFactory } = params;

  if (dataClientFactory === undefined) {
    throw new Error("Unable to retrieve Inventory Items without a DataClientFactory.");
  }

  const reducerActions = getReducerActions(params);

  const { showLoader, showInvalid, showError, savePageAction, submitPageAction } = reducerActions;

  const savePage = (updater: (form: SearchForm) => void) => savePageAction(updater);

  const submitPage = async (page: PageState) => {
    showLoader();

    const { form } = page;

    try {
      const searchClient = dataClientFactory.getClient(SearchClient);
      const searchForm = await searchClient.post(form);

      if (searchForm) {
        submitPageAction(searchForm);
        return;
      }

      showError(`Unable to find search results for: "${form?.query}"`);
    } catch (error: unknown) {
      apiErrorHandler.handle({
        error: error,
        errorAction: () => showError(`Unable to find search results for: "${form?.query}"`),
        invalidAction: () => showInvalid(form),
      });
    }
  };

  return {
    savePage,
    submitPage,
  };
};
