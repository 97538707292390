import {
  GetTokenSilentlyOptions,
  LogoutOptions,
  RedirectLoginOptions,
  useAuth0,
} from "@auth0/auth0-react";
import { useMemo } from "react";

const useRmAuth = () => {
  const { loginWithRedirect, logout, getAccessTokenSilently, user, isAuthenticated, isLoading } =
    useAuth0();

  const rmLoginWithRedirect: typeof loginWithRedirect = async (
    options?: RedirectLoginOptions | undefined
  ) => {
    await loginWithRedirect(options);
  };

  const rmLogout: typeof logout = (options?: LogoutOptions | undefined) => {
    logout(options);
  };

  const rmGetAccessTokenSilently = async (
    options?: GetTokenSilentlyOptions | undefined
  ): Promise<string> => {
    return await getAccessTokenSilently(options);
  };

  const rmIsAuthenticated = useMemo(() => {
    return isAuthenticated;
  }, [isAuthenticated]);

  const rmUser = useMemo(() => {
    return user;
  }, [user]);

  const rmIsLoading = useMemo(() => {
    return isLoading;
  }, [isLoading]);

  return {
    loginWithRedirect: rmLoginWithRedirect,
    logout: rmLogout,
    getAccessTokenSilently: rmGetAccessTokenSilently,
    user: rmUser,
    isAuthenticated: rmIsAuthenticated,
    isLoading: rmIsLoading,
  };
};

export default useRmAuth;
