import Button from "components/Button";
import ProgressIndicator from "components/ProgressIndicator";
import { useEffectOnLoad } from "hooks/useEffectOnLoad";

import Input, { InputType } from "components/Input";
import LayoutContent from "components/LayoutContent";
import useProfilePageStore from "./store/useProfilePageStore";

const ProfilePage = () => {
  const [state, actions] = useProfilePageStore();

  useEffectOnLoad(() => actions.loadPage(), actions.unloadPage);

  if (state.isLoading) {
    return (
      <div>
        <ProgressIndicator isLoading={true} />
      </div>
    );
  }

  const form = state.page.form;

  return (
    <LayoutContent
      label="Profile"
      icon="user"
      primaryButton={
        <Button
          type="success"
          label="Save"
          icon="save"
          onClick={() => {
            actions.submitPage(form);
          }}
        />
      }
      secondaryButton={
        <Button
          type="warning"
          subtype="outlined"
          label="Discard"
          icon="trash"
          onClick={() => {
            actions.loadPage();
          }}
        />
      }
    >
      <h2>Details</h2>
      <div className="formgrid grid">
        <div className="field col-12 mb-3">
          <Input
            id="nickname"
            type={InputType.Text}
            label="Nickname"
            value={form.nickname}
            onChange={(newValue: string | null | undefined) =>
              actions.savePage((page) => (page.nickname = newValue))
            }
          />
        </div>
      </div>
      <div className="formgrid grid">
        <div className="field col-12 md:col-6 mb-3">
          <Input
            type={InputType.Text}
            id="firstName"
            label="Full Name"
            value={form.name}
            onChange={(newValue: string | null | undefined) =>
              actions.savePage((page) => (page.name = newValue))
            }
          />
        </div>
        <div className="field col-12 md:col-6 mb-3">
          <Input
            type={InputType.Text}
            id="email"
            label="Email"
            value={form.email}
            onChange={(newValue: string | null | undefined) =>
              actions.savePage((page) => (page.email = newValue))
            }
            isDisabled={true}
          />
        </div>
      </div>
    </LayoutContent>
  );
};

export default ProfilePage;
