import { useEffect } from "react";
export function useEffectOnLoad(
  callback: () => void,
  onUnload?: () => void,
  dependencies?: React.DependencyList | undefined
) {
  const deps = dependencies ?? [];

  const callbackWithOnLoad = () => {
    callback();

    if (onUnload) {
      return onUnload;
    }

    return;
  };

  useEffect(callbackWithOnLoad, deps);
}
