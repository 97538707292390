import classNames from "classnames";
import Boop from "components/Boop";
import { Route } from "MainRoutes";
import { Link } from "react-router-dom";
import { PrimeFontSize } from "utils/PrimeReact/PrimeFontSize";
import { PrimeIcons } from "utils/PrimeReact/PrimeIcons";
import { PropsWithClassName } from "utils/PropsWithClassName";
import style from "./index.module.scss";
import { Tooltip } from "primereact/tooltip";
import { stringUtils } from "utils/stringUtils";

type TooltipPosition = "top" | "right" | "bottom" | "left";
interface TooltipPlacementOptions {
  my?: string;
  at?: string;
}
export interface BaseIconProps {
  name: PrimeIcons;
  size?: "small" | "medium" | "large" | "xlarge" | "xxlarge" | "massive";
  isSpinning?: boolean;
  disableBoop?: true;
  linkTo?: Route;
  isVisible?: boolean;
  onClick?: () => void;
  tooltipOptions?: {
    id?: string;
    tooltip: string;
    position?: TooltipPosition | undefined;
    mouseTrack?: true;
    mouseTrackTop?: number;
    mouseTrackLeft?: number;
  };
}

type IconProps = PropsWithClassName<BaseIconProps>;

const Icon = (props: IconProps) => {
  const {
    name,
    size,
    isSpinning,
    disableBoop,
    className,
    isVisible,
    linkTo,
    onClick,
    tooltipOptions,
  } = props;

  if (isVisible === false) {
    return <></>;
  }

  const textSize: PrimeFontSize | undefined =
    size === "small"
      ? "text-xs"
      : size === "medium"
      ? "text-base"
      : size === "large"
      ? "text-2xl"
      : size === "xlarge"
      ? "text-3xl"
      : size === "xxlarge"
      ? "text-5xl"
      : size === "massive"
      ? "text-8xl"
      : undefined;

  const fortyDegrees: PrimeIcons[] = ["refresh"];

  const rotation = fortyDegrees.includes(name) ? 40 : 10;

  const route = linkTo !== undefined ? linkTo : undefined;

  const spinningClass = isSpinning === true ? "pi-spin" : undefined;

  const tooltip = tooltipOptions?.tooltip ?? "";
  const toolTipPosition = tooltipOptions?.position ?? "right";
  const tooltipIdSuffix =
    tooltipOptions?.id ?? stringUtils.convertSpecialCharactersToHyphens(tooltip) ?? "";

  const tooltipId = `tooltip_${tooltipIdSuffix}`;

  const getTooltipPlacementBasedOnPosition = (
    position: TooltipPosition
  ): TooltipPlacementOptions | undefined => {
    if (position === "bottom") {
      // TODO research how to move the tooltip around
      return {
        // at: "right top+8",
        // my: "left center",
      };
    }

    if (position === "right") {
      return {
        at: "right+7 top",
        my: "left center+5",
      };
    }

    return {};
  };

  const tooltipPlacement = getTooltipPlacementBasedOnPosition(toolTipPosition);

  let icon = (
    <i
      className={classNames("pi", `pi-${name}`, className, textSize, tooltipId, spinningClass)}
      onClick={() => onClick && onClick()}
    />
  );

  if (route !== undefined) {
    icon = (
      <Link to={route} className={style.unstyledLink}>
        {icon}
      </Link>
    );
  }

  const tooltipIcon = stringUtils.isNullOrWhitespace(tooltip) ? (
    icon
  ) : (
    <>
      <Tooltip
        target={`.${tooltipId}`}
        content={tooltip}
        position={toolTipPosition}
        at={tooltipPlacement?.at}
        my={tooltipPlacement?.my}
        mouseTrack={tooltipOptions?.mouseTrack}
        mouseTrackTop={tooltipOptions?.mouseTrackTop}
        mouseTrackLeft={tooltipOptions?.mouseTrackLeft}
      />
      {icon}
    </>
  );

  if (disableBoop || isSpinning) {
    return tooltipIcon;
  }

  return (
    <Boop rotation={rotation} timingMs={150}>
      {tooltipIcon}
    </Boop>
  );
};

export default Icon;
