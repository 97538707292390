import { PickListChangeEvent, PickList as PrimePickList } from "primereact/picklist";

export interface IPickListItem {
  display?: string | null | undefined;
}

export interface PickListProps<IPickListItem> {
  leftHeaderLabel?: string | undefined;
  rightHeaderLabel?: string | undefined;
  leftData: IPickListItem[];
  rightData: IPickListItem[];
  isDisabled: boolean;
  onChange: (newLeftData: IPickListItem[], newRightData: IPickListItem[]) => void;
}

export function PickList<T extends IPickListItem>(props: PickListProps<T>) {
  const { leftHeaderLabel, rightHeaderLabel, leftData, rightData, isDisabled, onChange } = props;

  const sourceHeaderLabel = leftHeaderLabel ?? "Source";
  const targetHeaderLabel = rightHeaderLabel ?? "Target";
  const sourceData = leftData ?? [];
  const targetData = rightData ?? [];

  return (
    <PrimePickList
      sourceHeader={`${sourceHeaderLabel} (${sourceData.length})`}
      targetHeader={`${targetHeaderLabel} (${targetData.length})`}
      showSourceControls={false}
      showTargetControls={false}
      source={sourceData}
      target={targetData}
      sourceItemTemplate={(item: IPickListItem) => {
        return <span>{item.display}</span>;
      }}
      targetItemTemplate={(item: IPickListItem) => {
        return <span>{item.display}</span>;
      }}
      sourceStyle={{ height: "342px", minWidth: "200px" }}
      targetStyle={{ height: "342px", minWidth: "200px" }}
      onChange={(item: PickListChangeEvent) => {
        if (isDisabled) {
          return;
        }

        const newLeftData = item.source;
        const newRightData = item.target;
        onChange && onChange(newLeftData, newRightData);
      }}
    />
  );
}
