import { GainLossForm } from "api/GeneratedApiClients";
import useStore from "../../../hooks/useStore";
import objectUtils from "../../../utils/objectUtils";
import { getInventoryPageActions, InventoryActionMethods } from "./gainLossActionMethods";
import { GainLossPageAction, GainLossPageActionType } from "./gainLossActions";

export interface PageState {
  page: GainLossForm | undefined;
  isLoading: boolean;
}

const initialPageState: PageState = {
  page: undefined,
  isLoading: true,
};

const pageReducer = function (readonlyState: PageState, action: GainLossPageAction): PageState {
  const mutableState = objectUtils.deepCopyObject(readonlyState);

  switch (action.type) {
    case GainLossPageActionType.ShowLoader:
      mutableState.isLoading = true;
      break;

    case GainLossPageActionType.InitialLoad:
    case GainLossPageActionType.ShowSuccess:
    case GainLossPageActionType.ShowInvalid:
      mutableState.page = action.form;
      mutableState.isLoading = false;
      break;

    case GainLossPageActionType.ShowError:
      mutableState.isLoading = false;
      break;
    case GainLossPageActionType.Save:
      if (mutableState.page) {
        action.updater(mutableState.page);
      }
      break;
  }

  return mutableState;
};

const useGainLossStore = (): [PageState, InventoryActionMethods] => {
  return useStore(initialPageState, pageReducer, getInventoryPageActions);
};

export default useGainLossStore;
