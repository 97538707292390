import BasicLink from "components/BasicLink";
import Panel from "components/Panel";
import QuestionAnswer from "./questionAnswer";
import { useContext } from "react";
import { ConfigContext } from "config/ConfigProvider";

const LoginIssues = () => {
  const config = useContext(ConfigContext);

  return (
    <Panel header="Login issues" isCollapsed={true}>
      <QuestionAnswer
        question="How do I create an account?"
        answer="Accounts are free to create and self-service. Please follow the login links to create a
      new account."
      />
      <QuestionAnswer
        question="I never received a login code. What should I do?"
        answer="Check your spam folder to see if you received an email from:"
      >
        <BasicLink url={config.supportEmail} isEmail />
      </QuestionAnswer>
      <QuestionAnswer
        question="Why am I asked to login when I already logged in?"
        answer="Try to enable third party cookies:"
      >
        <p className="m-0">
          <span>Desktop</span>
          <ul>
            <li>
              <BasicLink
                prefix="Chrome - "
                url="https://support.google.com/chrome/answer/95647"
                open="new"
              />
            </li>
            <li>
              <BasicLink
                prefix="Safari - "
                url="https://support.apple.com/guide/safari/enable-cookies-ibrw850f6c51/mac"
                open="new"
              />
            </li>
            <li>
              <BasicLink
                prefix="Edge - "
                url="https://support.microsoft.com/en-us/office/enable-cookies-6b018d22-1d24-43d9-8543-3d35ddb2cb52"
                open="new"
              />
            </li>
            <li>
              <BasicLink
                prefix="Firefox - "
                url="https://support.mozilla.org/en-US/kb/third-party-cookies-firefox-tracking-protection"
                open="new"
              />
            </li>
            <li>
              <BasicLink
                prefix="Brave - "
                url="https://support.brave.com/hc/en-us/articles/360050634931-How-Do-I-Manage-Cookies-In-Brave-"
                open="new"
              />
            </li>
            <li>
              Not listed? Please search google how to enable third party cookies for your browser
            </li>
          </ul>
          <span>Mobile</span>
          <ul>
            <li>
              <BasicLink
                prefix="Chrome (Android) - "
                url="https://support.google.com/chrome/answer/95647?hl=en&co=GENIE.Platform%3DAndroid"
                open="new"
              />
            </li>
            <li>
              <BasicLink
                prefix="Chrome (iPhone) - "
                url="https://support.google.com/chrome/answer/95647?hl=en&co=GENIE.Platform%3DiOS&oco=0"
                open="new"
              />
            </li>
            <li>
              <BasicLink
                prefix="Safari (iPhone) - "
                url="https://alvarotrigo.com/blog/third-party-cookies-safari"
                open="new"
              />
            </li>
            <li>
              Not listed? Please search google how to enable third party cookies for your browser
            </li>
          </ul>
        </p>
      </QuestionAnswer>
    </Panel>
  );
};

export default LoginIssues;
