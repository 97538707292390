import moment from "moment";
import { Moment } from "moment";
import { CalendarChangeEvent, Calendar as CalendarPrime } from "primereact/calendar";
import styles from "./index.module.scss";
import useResaleMedia from "hooks/useResaleMedia";
import { useRef, useState } from "react";
import { useEffectOnLoad } from "hooks/useEffectOnLoad";
import { Nullable } from "primereact/ts-helpers";

export enum InputType {}

export interface BaseInputProps {
  id: string;
  label: string;
  placeholder?: string | undefined;
  iconPosition?: "left" | "right";
  minDate?: Moment | undefined;
  maxDate?: Moment | undefined;
  isDisabled?: boolean | undefined;
}

export interface SingleDateProps extends BaseInputProps {
  singleValue: Moment | Moment | undefined;
  onChange: (newDate: Moment | undefined) => void;
}

export interface MultipleDateProps extends BaseInputProps {
  multipleValues: Moment[];
  onChange: (newDate: Moment[]) => void;
}

const Calendar = (props: SingleDateProps | MultipleDateProps) => {
  const { isMobile } = useResaleMedia();
  const [value, setValue] = useState<Nullable<string | Date | Date[]>>(undefined);
  const calendarRef = useRef<CalendarPrime>(null);

  const { id, label, iconPosition, isDisabled, minDate, maxDate } = props;

  const isSingle = (props as MultipleDateProps).multipleValues === undefined;
  const selectionMode = isSingle ? "single" : "multiple";

  let singleValue: moment.Moment | undefined = undefined;
  let multipleValues: moment.Moment[] = []; //useMemo<moment.Moment[]>(() => [], []);

  if (isSingle) {
    singleValue = (props as SingleDateProps).singleValue;
  } else {
    multipleValues = (props as MultipleDateProps).multipleValues;
  }

  useEffectOnLoad(() => {
    const newValue = isSingle
      ? singleValue === undefined
        ? undefined
        : moment(singleValue).toDate()
      : multipleValues.map((v) => moment(v).toDate());

    if (newValue !== value) {
      setValue(newValue);
    }
  });

  const currentYear = new Date().getFullYear();
  const startYearRange = currentYear - 3;
  const endYearRange = currentYear + 3;

  return (
    <div className={styles.input}>
      <label htmlFor={id}>{label}</label>
      <CalendarPrime
        ref={calendarRef}
        id={id}
        value={value}
        selectionMode={selectionMode}
        onChange={(e: CalendarChangeEvent) => {
          const value = e?.value;
          setValue(value);
          if (isSingle) {
            const onSingleChange = (props as SingleDateProps).onChange;
            let date: Moment | undefined = undefined;

            if (value !== undefined && !Array.isArray(value)) {
              date = moment(value as unknown as Date);
            }

            if (date?.isValid()) {
              calendarRef?.current?.hide();
              onSingleChange(date);
            }
          } else {
            const onMultipleChange = (props as MultipleDateProps).onChange;
            const dates = !Array.isArray(value) ? [] : (value as Date[]);

            const moments = dates.filter((d) => d !== undefined).map((d) => moment(d));
            onMultipleChange(moments);
          }
        }}
        showIcon
        showButtonBar
        minDate={minDate !== undefined ? moment(minDate).toDate() : undefined}
        maxDate={maxDate !== undefined ? moment(maxDate).toDate() : undefined}
        yearRange={`${startYearRange}:${endYearRange}`}
        touchUI={isMobile}
        iconPos={iconPosition ?? "right"}
        disabled={isDisabled}
      />
    </div>
  );
};

export default Calendar;
