import objectUtils from "utils/objectUtils";
import useStore from "hooks/useStore";
import { getSettingsPageActions } from "./settingsPageStoreActionMethods";
import { SettingsPageAction, SettingsPageActionType } from "./settingsPageStoreActions";
import { SettingsForm } from "api";

export interface SettingsPageState {
  form: SettingsForm;
}
export interface PageState {
  page: SettingsPageState;
  isEditing: boolean;
  isLoading: boolean;
  hasChanges: boolean;
}
const initialSettingsPageState: PageState = {
  page: { form: {} },
  isEditing: false,
  isLoading: true,
  hasChanges: false,
};
const settingsPageReducer = function (
  readonlyState: PageState,
  action: SettingsPageAction
): PageState {
  const mutableState = objectUtils.deepCopyObject(readonlyState);
  switch (action.type) {
    case SettingsPageActionType.ShowLoader:
      mutableState.isLoading = true;
      break;
    case SettingsPageActionType.InitialLoad:
    case SettingsPageActionType.ShowSuccess:
    case SettingsPageActionType.ShowInvalid:
      mutableState.page = { form: action.form };
      mutableState.isLoading = false;
      break;
    case SettingsPageActionType.ShowError:
      mutableState.isLoading = false;
      break;
    case SettingsPageActionType.ChangePageViews: {
      const { isEditing } = action;

      if (isEditing !== undefined) {
        mutableState.isEditing = isEditing;
      }
      break;
    }
    case SettingsPageActionType.Save:
      if (mutableState.page && action.updater) {
        action.updater(mutableState.page.form);
        mutableState.hasChanges = true;
      }
      break;
    case SettingsPageActionType.Submit:
      mutableState.page.form = action.form;
      mutableState.isLoading = false;
      mutableState.isEditing = false;
      mutableState.hasChanges = false;
      break;
  }
  return mutableState;
};
const useSettingsPageStore = function () {
  return useStore(initialSettingsPageState, settingsPageReducer, getSettingsPageActions);
};
export default useSettingsPageStore;
