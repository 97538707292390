import Site from "site/Site";
import Config from "../config/Config";
import { Identity } from "../auth/IdentityProvider";
import { IConfig } from "api/ApiClientExtensions";

interface DataClientConstructor<T> {
  new (
    configuration: IConfig,
    baseUrl?: string,
    http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }
  ): T;
}

const getDataService = function <TDataService>(
  dataServiceType: DataClientConstructor<TDataService>,
  config: Config,
  identity: Identity
): TDataService {
  const apiConfig = {
    getAuthorization: () => {
      return new Promise<string>((resolve, reject) => {
        identity
          .getAccessToken()
          .then((token: string | null) => {
            if (!token) {
              reject();
              return;
            }

            resolve(`Bearer ${token}`);
          })
          .catch(reject);
      });
    },
  };

  return new dataServiceType(apiConfig, config.csharpApiUrl);
};

const makeGetRequest = function <T>(
  identity: Identity,
  config: Config,
  endpoint: string
): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    identity.getAccessToken().then((token: string | null) => {
      if (!token) {
        return;
      }

      fetch(`${config.apiUrl}/${endpoint}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          resolve(data as T);
        })
        .catch((err) => {
          reject(err);
        });
    });
  });
};

const getConfig = function (): Promise<Config> {
  return new Promise<Config>((resolve, reject) => {
    fetch(`/config.json`)
      .then((response) => response.json())
      .then((data) => {
        resolve(data as Config);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getSite = function (): Promise<Site> {
  return new Promise<Site>((resolve) => {
    resolve({
      userId: "",
      categories: [],
    });
    // fetch(`/site.json`)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     const site = data as Site;

    //     const organizedSite: Site = {
    //       userId: site?.userId,
    //       categories: (site?.categories ?? []).sort(),
    //     };

    //     resolve(organizedSite);
    //   })
    //   .catch((err) => {
    //     reject(err);
    //   });
  });
};

export interface DataServiceUtility {
  getConfig: () => Promise<Config>;
  getSite: () => Promise<Site>;
  makeGetRequest: <T>(identity: Identity, config: Config, endpoint: string) => Promise<T>;
  getDataService: <TDataService>(
    dataServiceType: DataClientConstructor<TDataService>,
    config: Config,
    identity: Identity
  ) => TDataService;
}

export class DataClientFactory {
  private _identity: Identity;
  private _config: Config;

  constructor(identity: Identity, config: Config) {
    this._identity = identity;
    this._config = config;
  }

  getClient<TDataClient>(clientType: DataClientConstructor<TDataClient>): TDataClient {
    return getDataService(clientType, this._config, this._identity);
  }
}

const dataServiceUtility: DataServiceUtility = {
  getConfig,
  getSite,
  makeGetRequest,
  getDataService,
};

export default dataServiceUtility;
