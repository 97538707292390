import classNames from "classnames";
import Icon from "components/Icon";
import { MediaBreakpoints } from "components/Layout/MediaBreakpoints";
import { useEffectOnLoad } from "hooks/useEffectOnLoad";
import { Divider } from "primereact/divider";
import { PropsWithChildren } from "react";
import { PrimeIcons } from "utils/PrimeReact/PrimeIcons";
import style from "./index.module.scss";
import { stringUtils } from "utils/stringUtils";

export interface LayoutProps {
  label: string;
  icon?: PrimeIcons;
  image?: string;
  primaryButton?: JSX.Element | undefined;
  secondaryButton?: JSX.Element | undefined;
}

export interface StatsLayoutProps extends LayoutProps {
  stats?: StatProps[] | undefined;
}

export interface CustomLayoutProps extends LayoutProps {
  subElement?: () => JSX.Element | undefined;
}

export type StatProps = NormalStatProps | CurrencyStatProps;

export interface CurrencyStatProps {
  label: string;
  currency: number;
}

export interface NormalStatProps {
  label: string;
  value: number | string;
}

const LayoutContent = (props: PropsWithChildren<StatsLayoutProps | CustomLayoutProps>) => {
  const { label, icon, image, primaryButton, secondaryButton, children } = props;

  useEffectOnLoad(
    () => {
      document.title = `ResaleMinder | ${label}`;
    },
    () => {
      document.title = "ResaleMinder";
    },
    [label]
  );

  const Stats = (props: StatProps) => {
    const { label } = props;

    const value = (props as NormalStatProps).value;
    const currency = (props as CurrencyStatProps).currency;

    let finalValue = <span>{value}</span>;

    if (currency !== undefined && currency !== null) {
      const className = currency < 0 ? "negativeCurrency" : undefined;

      finalValue = <span className={className}>{stringUtils.formatAsCurrency(currency)}</span>;
    }

    return (
      <>
        <span>{label?.toLocaleUpperCase()}</span>
        {finalValue}
      </>
    );
  };

  const stats = (props as StatsLayoutProps).stats ?? [];
  const subElement = (props as CustomLayoutProps).subElement;

  const statItems = (stats ?? []).map((s: StatProps) => {
    return (
      <li key={s.label}>
        <Stats {...s} />
      </li>
    );
  });

  const hasSubComponent = subElement !== undefined || stats.length > 0;

  const subComponent = subElement === undefined ? <ul>{statItems}</ul> : subElement();
  const iconMargin = hasSubComponent ? "mr-5" : "mr-3";
  const iconSize = hasSubComponent ? "massive" : "xxlarge";
  const layoutAlignItems = hasSubComponent ? undefined : "flex justify-content-center";

  const containerClass = "flex justify-content-between flex-wrap";
  const iconClass = classNames(style.icon, iconMargin);
  const headerDetailsClass = classNames(style.headerDetails, layoutAlignItems);

  const ActionButtons = () => {
    if (!primaryButton && !secondaryButton) {
      return null;
    }

    return (
      <div className={style.actionButtons}>
        <div>
          {primaryButton ?? <></>}
          {secondaryButton ?? <></>}
        </div>
      </div>
    );
  };

  const IconOrImage = () => {
    if (image) {
      return <img className={style.image} src={image} alt={`${label} branding`} />;
    }

    return <Icon name={icon ?? "question"} className={iconClass} size={iconSize} />;
  };

  return (
    <>
      <MediaBreakpoints.OnMobile>
        <div className={containerClass}>
          <div className={style.headerLeft}>
            <IconOrImage />
            <div className={headerDetailsClass}>
              <span className={style.headerName}>{label}</span>
              <>{subComponent}</>
            </div>
          </div>
        </div>
        <div className={style.divider}>
          <Divider type="solid" />
        </div>
        {children}
        <ActionButtons />
        <div className={style.divider}>
          <Divider type="solid" />
        </div>
      </MediaBreakpoints.OnMobile>
      <MediaBreakpoints.OnDesktop>
        <div className={containerClass}>
          <div className={style.headerLeft}>
            <IconOrImage />
            <div className={headerDetailsClass}>
              <span className={style.headerName}>{label}</span>
              <>{subComponent}</>
            </div>
          </div>
          <ActionButtons />
        </div>
        <div className={style.divider}>
          <Divider type="solid" />
        </div>
        {children}
        <div className={style.divider}>
          <Divider type="solid" />
        </div>
        <ActionButtons />
      </MediaBreakpoints.OnDesktop>
    </>
  );
};

export default LayoutContent;
