import { ProfileForm, ProfileService } from "api";
import { ActionMethodParameters } from "hooks/useStore";
import toastUtils from "utils/toastUtils";
import { ProfilePageAction, ProfilePageActionType } from "./profilePageStoreActions";
const getReducerActions = function (params: ActionMethodParameters<ProfilePageAction>) {
  const dispatch = params.dispatch;
  return {
    showLoader: () => dispatch({ type: ProfilePageActionType.ShowLoader }),
    initialLoad: (form: ProfileForm) =>
      dispatch({
        type: ProfilePageActionType.InitialLoad,
        form: form,
      }),
    showInvalid: (form: ProfileForm) => {
      toastUtils.showInvalidMessage();
      dispatch({
        type: ProfilePageActionType.ShowInvalid,
        form: form,
      });
    },
    showSuccess: (form: ProfileForm) => {
      toastUtils.showSaveSuccessMessage();
      dispatch({
        type: ProfilePageActionType.ShowSuccess,
        form: form,
      });
    },
    showError: (error: string) => {
      toastUtils.showErrorMessage();
      dispatch({
        type: ProfilePageActionType.ShowError,
        error: error,
      });
    },
    editPageAction: () => {
      dispatch({
        type: ProfilePageActionType.ChangePageViews,
        isEditing: true,
      });
    },
    savePageAction: (updater: (form: ProfileForm) => void) => {
      dispatch({
        type: ProfilePageActionType.Save,
        updater: updater,
      });
    },
    submitPageAction: (form: ProfileForm) => {
      dispatch({
        type: ProfilePageActionType.Submit,
        form: form,
      });
    },
  };
};

export const getProfilePageActions = function (params: ActionMethodParameters<ProfilePageAction>) {
  const reducerActions = getReducerActions(params);
  const { apiErrorHandler, identity } = params;

  const { showLoader, initialLoad, savePageAction, editPageAction, submitPageAction } =
    reducerActions;

  const defaultErrorHandlingOptions = (error: unknown, invalidObject: ProfileForm) => {
    apiErrorHandler.handle({
      error: error,
      errorAction: () => reducerActions.showError("Unable to load Profile Page."),
      invalidAction: () => reducerActions.showInvalid(invalidObject),
    });
  };

  const loadPage = async () => {
    showLoader();

    try {
      await identity.initializeAuthorization();
      const profile = await ProfileService.getProfile();
      initialLoad(profile);
    } catch (error: unknown) {
      defaultErrorHandlingOptions(error, {});
    }
  };

  const unloadPage = () => undefined;

  const savePage = (updater: (form: ProfileForm) => void) => savePageAction(updater);
  const editPage = () => editPageAction();
  const submitPage = async (form: ProfileForm) => {
    showLoader();

    try {
      await identity.initializeAuthorization();
      const newForm = await ProfileService.postProfile({ requestBody: form });
      submitPageAction(newForm);
    } catch (error: unknown) {
      defaultErrorHandlingOptions(error, form);
    }
  };

  return {
    loadPage,
    unloadPage,
    editPage,
    savePage,
    submitPage,
  };
};
