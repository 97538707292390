import Button from "components/Button";
// import Input, { InputType } from "components/Input";
// import { useState } from "react";
// import objectUtils from "utils/objectUtils";
import style from "./index.module.scss";
import useRmAuth from "auth/useRmAuth";

// interface LoginState {
//   email: string | null | undefined;
//   password: string | null | undefined;
// }

// const defaultLoginState: LoginState = {
//   email: "",
//   password: "",
// };

const LoginPage = () => {
  const { loginWithRedirect } = useRmAuth();
  // const [loginState, setLoginState] = useState<LoginState>(defaultLoginState);

  // const updateState = (callback: (newLoginState: LoginState) => void) => {
  //   const mutableState = objectUtils.deepCopyObject(loginState);
  //   callback(mutableState);
  //   setLoginState(mutableState);
  // };

  return (
    <div className={style.container}>
      <div className={style.loginArea}>
        <h1>ResaleMinder</h1>
        {/* <Input
          id="email"
          type={InputType.Text}
          label="Email address"
          value={loginState.email}
          onChange={(newValue: string | null | undefined) => {
            updateState((newLoginState) => (newLoginState.email = newValue));
          }}
          autoFocus
        />
        <Input
          id="password"
          type={InputType.Password}
          label="Password"
          value={loginState.password}
          onChange={(newValue: string | null | undefined) => {
            updateState((newLoginState) => (newLoginState.password = newValue));
          }}
        /> */}
        <Button type="primary" label="Login" onClick={() => loginWithRedirect()} />
        <Button label="Create Account" onClick={() => loginWithRedirect()} />
      </div>
    </div>
  );
};

export default LoginPage;
