import { GainLossClient, GainLossForm } from "api/GeneratedApiClients";
import toastUtils from "utils/toastUtils";
import { ActionMethodParameters } from "../../../hooks/useStore";
import { GainLossPageAction, GainLossPageActionType } from "./gainLossActions";

export interface InventoryActionMethods {
  loadPage: () => void;
  unloadPage: () => void;
  changeSelectedYear: (newYear: string) => void;
}

const getReducerActions = function (params: ActionMethodParameters<GainLossPageAction>) {
  const dispatch = params.dispatch;
  return {
    showLoader: () => dispatch({ type: GainLossPageActionType.ShowLoader }),
    initialLoad: (form: GainLossForm) => {
      dispatch({
        type: GainLossPageActionType.InitialLoad,
        form: form,
      });
    },
    showInvalid: (form: GainLossForm) => {
      toastUtils.showInvalidMessage();
      dispatch({
        type: GainLossPageActionType.ShowInvalid,
        form: form,
      });
    },
    showSuccess: (form: GainLossForm) => {
      toastUtils.showSaveSuccessMessage();
      dispatch({
        type: GainLossPageActionType.ShowSuccess,
        form: form,
      });
    },
    showError: (error: string) => {
      toastUtils.showErrorMessage();
      dispatch({
        type: GainLossPageActionType.ShowError,
        error: error,
      });
    },
    savePageAction: (updater: (form: GainLossForm) => void) => {
      dispatch({
        type: GainLossPageActionType.Save,
        updater: updater,
      });
    },
  };
};

export const getInventoryPageActions = (
  params: ActionMethodParameters<GainLossPageAction>
): InventoryActionMethods => {
  const { apiErrorHandler, dataClientFactory } = params;
  const reducerActions = getReducerActions(params);

  if (dataClientFactory === undefined) {
    throw new Error("Unable to retrieve Gain Loss Page without a DataClientFactory.");
  }

  const { showLoader, showError, initialLoad, savePageAction } = reducerActions;

  const defaultErrorHandlingOptions = (error: unknown, invalidObject: GainLossForm) => {
    apiErrorHandler.handle({
      error: error,
      errorAction: () => reducerActions.showError("Unable to load Gain Loss Page."),
      invalidAction: () => reducerActions.showInvalid(invalidObject),
    });
  };

  const unloadPage = () => undefined;

  const loadPage = async () => {
    showLoader();

    try {
      const gainLossClient = dataClientFactory.getClient(GainLossClient);
      const gainLosses = await gainLossClient.get();

      if (gainLosses) {
        initialLoad(gainLosses);
      } else {
        showError("Unable to load Gain Loss Page.");
      }
    } catch (error: unknown) {
      const selectedYear = new Date().getFullYear();
      defaultErrorHandlingOptions(error, {
        availableYears: [selectedYear],
        selectedYear: selectedYear,
        gainLossYears: [],
      });
    }
  };

  const changeSelectedYear = (newYear: string) => {
    savePageAction((form: GainLossForm) => {
      try {
        const newYearNum = parseInt(newYear);

        if (form.availableYears.some((y) => y === newYearNum)) {
          form.selectedYear = newYearNum;
        }
      } catch {
        console.log(`Invalid year: ${newYear}`);
      }
    });
  };

  return {
    changeSelectedYear,
    loadPage,
    unloadPage,
  };
};
