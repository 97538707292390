import { InventoryForm } from "api";
import useStore from "../../../hooks/useStore";
import objectUtils from "../../../utils/objectUtils";
import { getInventoryPageActions, InventoryActionMethods } from "./inventoryActionMethods";
import { InventoryPageAction, InventoryPageActionType } from "./inventoryActions";

export interface PageState {
  page: InventoryForm;
  isLoading: boolean;
}

const initialPageState: PageState = {
  page: {},
  isLoading: true,
};

const pageReducer = function (readonlyState: PageState, action: InventoryPageAction): PageState {
  const mutableState = objectUtils.deepCopyObject(readonlyState);

  switch (action.type) {
    case InventoryPageActionType.ShowLoader:
      mutableState.isLoading = true;
      break;

    case InventoryPageActionType.InitialLoad:
    case InventoryPageActionType.ShowSuccess:
    case InventoryPageActionType.ShowInvalid:
      mutableState.page = action.form;
      mutableState.isLoading = false;
      break;

    case InventoryPageActionType.ShowError:
      mutableState.isLoading = false;
      break;
  }

  return mutableState;
};

const useInventoryStore = (): [PageState, InventoryActionMethods] => {
  return useStore(initialPageState, pageReducer, getInventoryPageActions);
};

export default useInventoryStore;
