import { SettingsForm } from "api";
export enum SettingsPageActionType {
  ShowLoader = 0,
  InitialLoad = 1,
  ShowSuccess = 2,
  ShowError = 3,
  ShowInvalid = 4,
  ChangePageViews = 5,
  Save = 6,
  Submit = 7,
}
interface ShowLoaderAction {
  type: SettingsPageActionType.ShowLoader;
}
interface InitialLoadAction {
  type: SettingsPageActionType.InitialLoad;
  form: SettingsForm;
}
interface ShowSuccessAction {
  type: SettingsPageActionType.ShowSuccess;
  form: SettingsForm;
}
interface ShowErrorAction {
  type: SettingsPageActionType.ShowError;
  error: string;
}
interface ShowInvalidAction {
  type: SettingsPageActionType.ShowInvalid;
  form: SettingsForm;
}
interface ChangePageViewsAction {
  type: SettingsPageActionType.ChangePageViews;
  isEditing?: boolean | undefined;
}
interface SaveAction {
  type: SettingsPageActionType.Save;
  updater: (form: SettingsForm) => void;
}
interface SubmitPageAction {
  type: SettingsPageActionType.Submit;
  form: SettingsForm;
}
export type SettingsPageAction =
  | ShowLoaderAction
  | InitialLoadAction
  | ShowSuccessAction
  | ShowErrorAction
  | ShowInvalidAction
  | ChangePageViewsAction
  | SaveAction
  | SubmitPageAction;
