import { useContext, useReducer } from "react";
import Config from "../config/Config";
import { ConfigContext } from "../config/ConfigProvider";
import { Identity } from "../auth/IdentityProvider";
import { ApiErrorHandler, IApiErrorHandler } from "utils/apiErrorHandler";
import { OpenAPI } from "api";
import { DataClientFactory } from "utils/DataServiceUtility";
import useNav, { ResaleNavigate } from "./useNav";
import useRmAuth from "auth/useRmAuth";

export interface ActionMethodParameters<TAction> {
  identity: Identity;
  config: Config;
  navigate: ResaleNavigate;
  dispatch: React.Dispatch<TAction>;
  apiErrorHandler: IApiErrorHandler;
  dataClientFactory?: DataClientFactory | undefined;
}

const useStore = function <TState, TAction, TActionMethods>(
  initialState: TState,
  reducer: (readonlyState: TState, action: TAction) => TState,
  getActions: (params: ActionMethodParameters<TAction>) => TActionMethods
): [TState, TActionMethods] {
  const [navigate] = useNav();

  const { getAccessTokenSilently } = useRmAuth();

  const getAccessToken = async (): Promise<string> => {
    try {
      return await getAccessTokenSilently({ scope: "openid email name", ignoreCache: false });
    } catch (e) {
      console.log("failed to retrieve token:", e);
      throw e;
    }
  };

  const identity: Identity = {
    getAccessToken: getAccessToken,
    initializeAuthorization: async (): Promise<void> => {
      const token = await getAccessToken();
      OpenAPI.HEADERS = {
        Authorization: `Bearer ${token}`,
      };
    },
  };

  const config = useContext(ConfigContext);

  const [state, dispatch] = useReducer(reducer, initialState);

  const apiErrorHandler = new ApiErrorHandler(navigate);
  const dataClientFactory = new DataClientFactory(identity, config);

  const actions = getActions({
    identity,
    config,
    navigate,
    dispatch,
    apiErrorHandler,
    dataClientFactory,
  });

  return [state, actions];
};

export default useStore;
