import { ExpenseFormOther } from "api/GeneratedApiClients";
import Input, { InputType } from "components/Input";
import moment, { Moment } from "moment";
import Button from "components/Button";
import uuid from "utils/uuid";
import { useState } from "react";
import objectUtils from "utils/objectUtils";
import { Dialog } from "primereact/dialog";
import Icon from "components/Icon";

import { useEffectOnLoad } from "hooks/useEffectOnLoad";
import Calendar from "components/Calendar";
import dateUtils from "utils/dateUtils";
import { stringUtils } from "utils/stringUtils";

interface ExpenseOtherModalProps {
  form: ExpenseFormOther | undefined;
  isVisible: boolean;
  onSave: (form: ExpenseFormOther) => void;
  onHide: () => void;
}

type ExpenseFormOtherPartial = Partial<ExpenseFormOther>;

const ExpenseOtherModal = (props: ExpenseOtherModalProps) => {
  const { form: nullableForm, isVisible, onSave, onHide } = props;

  const newForm: ExpenseFormOtherPartial = {
    id: uuid.new(),
    lastUpdated: moment(),
  };

  const title = nullableForm === undefined ? "Add Other" : "Edit Other";

  const [state, setState] = useState<ExpenseFormOtherPartial>(newForm);

  const setNewState = (updater: (newState: ExpenseFormOtherPartial) => void) => {
    const newState = objectUtils.deepCopyObject(state);
    updater(newState);
    setState(newState);
  };

  useEffectOnLoad(
    () => {
      setNewState((newState: ExpenseFormOtherPartial) => {
        newState.id = nullableForm?.id ?? uuid.new();
        newState.name = nullableForm?.name;
        newState.description = nullableForm?.description ?? "";
        newState.amount = nullableForm?.amount;
        newState.date = nullableForm?.date;
        newState.lastUpdated = nullableForm?.lastUpdated ?? moment();
      });
    },
    undefined,
    [nullableForm]
  );

  return (
    <Dialog
      visible={isVisible}
      header={
        <>
          <span className="pr-2">{title}</span>
          <Icon name="exclamation-circle" />
        </>
      }
      footer={
        <div className="flex align-items-center justify-content-between">
          <i>Last Update: {dateUtils.format(state.lastUpdated)}</i>
          <Button
            label="Save"
            type="primary"
            icon="plus-circle"
            onClick={() => {
              if (!state.id || stringUtils.isNullOrWhitespace(state.id)) {
                return;
              }

              const newForm: ExpenseFormOther = {
                id: state.id,
                name: state.name ?? "",
                description: state.description ?? "",
                amount: state.amount ?? 0,
                date: state.date ?? moment(),
                lastUpdated: moment(),
              };

              onSave(newForm);
            }}
          />
        </div>
      }
      closable
      closeOnEscape
      draggable
      style={{ width: "60%" }}
      onHide={() => onHide()}
    >
      <div className="formgrid grid">
        <div className="field col-4">
          <Input
            id="name"
            type={InputType.Text}
            label="Name"
            icon="box"
            value={state.name}
            onChange={(newValue: string | undefined) => {
              setNewState((newState: ExpenseFormOtherPartial) => (newState.name = newValue));
            }}
          />
        </div>
        <div className="field col-4">
          <Input
            id="amount"
            type={InputType.Currency}
            label="Amount"
            value={state.amount}
            onChange={(newValue: number | undefined) => {
              setNewState((newState: ExpenseFormOtherPartial) => (newState.amount = newValue));
            }}
            selectOnFocus
          />
        </div>
        <div className="field col-4">
          <Calendar
            id="date"
            label="Date"
            singleValue={state.date}
            maxDate={moment()}
            iconPosition="left"
            onChange={(newValue: Moment | undefined) =>
              setNewState((newState: ExpenseFormOtherPartial) => (newState.date = newValue))
            }
          />
        </div>
      </div>
      <div className="formgrid grid">
        <div className="field col-12">
          <Input
            id="description"
            type={InputType.Text}
            label="Description"
            icon="book"
            value={state.description}
            onChange={(newValue: string | undefined) => {
              setNewState((newState: ExpenseFormOtherPartial) => (newState.description = newValue));
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ExpenseOtherModal;
