import React, { PropsWithChildren, useContext } from "react";

import { ConfigContext } from "config/ConfigProvider";
import { Auth0Provider } from "@auth0/auth0-react";

const AuthProvider: React.FC<PropsWithChildren> = function (props: PropsWithChildren) {
  const config = useContext(ConfigContext);

  const domain = config.authDomain;
  const clientId = config.authClientId;

  if (domain === undefined || clientId === undefined) {
    return <span>Bad auth configuration</span>;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={config.baseUrl}
      audience={config.csharpApiUrl}
      useRefreshTokens
    >
      {props.children}
    </Auth0Provider>
  );
};

export default AuthProvider;
