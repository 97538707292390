import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { PrimeIcons } from "utils/PrimeReact/PrimeIcons";

import style from "./index.module.scss";
import { PropsWithClassName } from "utils/PropsWithClassName";
import classNames from "classnames";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import Icon from "components/Icon";

export enum InputType {
  Text = 1,
  Number = 2,
  Currency = 3,
  Password = 4,
}

export interface InputProps {
  id: string;
  label: string;
  placeholder?: string | undefined;
  isDisabled?: boolean | undefined;
  showClear?: boolean | undefined;
  autoFocus?: boolean | undefined;
  selectOnFocus?: boolean | undefined;
  helpIndicator?: {
    onOpen: () => void;
    onClose: () => void;
  };
}

export interface InputTextProps extends InputProps {
  value: string | null | undefined;
  type: InputType.Text | InputType.Password;
  icon?: PrimeIcons | undefined;
  onChange:
    | ((newValue: string | null | undefined) => void)
    | ((newValue: string | undefined) => void);
}

export interface InputNumberProps extends InputProps {
  value: number | undefined;
  type: InputType.Number | InputType.Currency;
  hideIcon?: boolean | undefined;
  decimalPlaces?: number | undefined;
  onChange: (newValue: number | undefined) => void;
}

const Input = (props: PropsWithClassName<InputTextProps | InputNumberProps>) => {
  const { type, value, isDisabled, showClear, autoFocus, selectOnFocus, className, helpIndicator } =
    props;

  const onTextChange = (props as InputTextProps)?.onChange;
  const onNumberChange = (props as InputNumberProps)?.onChange;

  const { id, label, placeholder } = props;

  let icon = (props as InputTextProps)?.icon ?? undefined;

  let mode: "decimal" | "currency" | undefined = undefined;
  let decimalPlaces: number | undefined = (props as InputNumberProps)?.decimalPlaces;

  if (type === InputType.Currency || type === InputType.Number) {
    mode = "decimal";
    decimalPlaces = decimalPlaces === undefined ? 2 : decimalPlaces;
  }

  if (type === InputType.Currency) {
    icon = "dollar";
  }
  if (type === InputType.Number) {
    icon = "hashtag";
  }

  const hideIcon = (props as InputNumberProps)?.hideIcon ?? undefined;

  const isNumberOrCurrency = type === InputType.Number || type === InputType.Currency;
  const isPassword = type === InputType.Password;

  let input = (
    <InputText
      id={id}
      className={classNames(style.inputStyle, className)}
      value={value as string | undefined}
      placeholder={placeholder}
      onChange={(e) => {
        onTextChange && onTextChange(e.target.value);
      }}
      disabled={isDisabled}
      autoFocus={autoFocus}
      onFocus={(e) => {
        if (selectOnFocus) {
          e.target.select();
        }
      }}
    />
  );

  if (isNumberOrCurrency) {
    input = (
      <InputNumber
        id={id}
        className={classNames(style.inputNumberStyle, className)}
        value={value as number | null | undefined}
        placeholder={placeholder}
        mode={mode}
        minFractionDigits={decimalPlaces}
        maxFractionDigits={decimalPlaces}
        onChange={(e) => {
          onNumberChange && onNumberChange(e.value ?? undefined);
        }}
        disabled={isDisabled}
        autoFocus={autoFocus}
        onFocus={(e) => {
          if (selectOnFocus) {
            e.target.select();
          }
        }}
      />
    );
  }

  if (isPassword) {
    input = (
      <Password
        id={id}
        className={classNames(style.inputNumberStyle, className)}
        value={value as string | number | readonly string[] | undefined}
        placeholder={placeholder}
        onChange={(e) => {
          onTextChange && onTextChange(e.target.value);
        }}
        toggleMask
        disabled={isDisabled}
        autoFocus={autoFocus}
        onFocus={(e) => {
          if (selectOnFocus) {
            e.target.select();
          }
        }}
      />
    );
  }

  if (icon === undefined || hideIcon === true) {
    return isNumberOrCurrency ? (
      <div className={style.inputNumber}>
        <label htmlFor={id} className={style.label}>
          {label}{" "}
          {helpIndicator != null ? (
            <Icon name="question-circle" onClick={helpIndicator.onOpen} />
          ) : (
            <></>
          )}
        </label>
        {input}
      </div>
    ) : (
      <div className={classNames(style.input, "flex flex-column")}>
        <label htmlFor={id} className={classNames(style.label, "mb-2")}>
          {label}{" "}
          {helpIndicator != null ? (
            <Icon name="question-circle" onClick={helpIndicator.onOpen} />
          ) : (
            <></>
          )}
        </label>
        {input}
      </div>
    );
  }

  return (
    <>
      <label htmlFor={id} className={style.label}>
        {label}{" "}
        {helpIndicator != null ? (
          <Icon name="question-circle" onClick={helpIndicator.onOpen} />
        ) : (
          <></>
        )}
      </label>
      <div className="p-inputgroup">
        <span className="p-inputgroup-addon">
          <i className={`pi pi-${icon}`}></i>
        </span>
        {input}
        {showClear ? (
          <Button
            disabled={isDisabled}
            icon="pi pi-times"
            className="p-button-secondary p-button-outlined"
            onClick={() => {
              if (onTextChange) {
                onTextChange("");
              }

              if (onNumberChange) {
                onNumberChange(undefined);
              }
            }}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Input;
