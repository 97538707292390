import { OAuthError } from "@auth0/auth0-react";
import { Routes } from "MainRoutes";
import { ApiError } from "api";
import { ResaleNavigate } from "hooks/useNav";

export interface IApiErrorHandler {
  handle: (options: ApiErrorOptions) => void;
}

export interface ApiErrorOptions {
  error: unknown;
  invalidAction: () => void;
  errorAction: () => void;
  overrideUnauthorizedAction?: () => void;
}

export class ApiErrorHandler implements IApiErrorHandler {
  private navigate: ResaleNavigate;

  constructor(navigate: ResaleNavigate) {
    this.navigate = navigate;
  }

  handle = (options: ApiErrorOptions): void => {
    const { error, overrideUnauthorizedAction, invalidAction, errorAction } = options;

    console.log(JSON.stringify(error));

    const unauthorizedAction = () => {
      if (overrideUnauthorizedAction) {
        return overrideUnauthorizedAction();
      }

      if (this.navigate) {
        this.navigate.to(Routes.home);
      }
    };

    const apiError = error as ApiError;
    const oauthError = error as OAuthError;

    const oauthErrorType = oauthError?.error;

    if (oauthErrorType === "login_required") {
      return unauthorizedAction();
    }

    if (apiError === undefined) {
      return oauthError !== undefined ? unauthorizedAction() : errorAction();
    }

    const status = apiError.status;

    switch (status) {
      case 401:
        return unauthorizedAction();
      case 400:
        return invalidAction();
      default:
        return errorAction();
    }
  };
}
