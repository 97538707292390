import { InventoryItemForm, InventoryItemFormDocument } from "api/GeneratedApiClients";

export enum InventoryItemPageActionType {
  ShowLoader = 0,
  InitialLoad = 1,
  ShowSuccess = 2,
  ShowError = 3,
  ShowInvalid = 4,
  ChangePageViews = 5,
  Save = 6,
  Submit = 7,
  DeleteDocument = 8,
  ConfirmDeleteDocument = 9,
  ToggleListingHelper = 10,
}

interface ShowLoaderAction {
  isVisible?: boolean;
  type: InventoryItemPageActionType.ShowLoader;
}

interface InitialLoadAction {
  type: InventoryItemPageActionType.InitialLoad;
  form: InventoryItemForm;
  isNew: boolean;
}

interface ShowSuccessAction {
  type: InventoryItemPageActionType.ShowSuccess;
  form: InventoryItemForm;
}

interface ShowInvalidAction {
  type: InventoryItemPageActionType.ShowInvalid;
  form: InventoryItemForm;
}

interface ShowErrorAction {
  type: InventoryItemPageActionType.ShowError;
  error: string;
}

interface ChangePageViewsAction {
  type: InventoryItemPageActionType.ChangePageViews;
  isEditing?: boolean | undefined;
  isUploadingFile?: boolean | undefined;
  isTakingPicture?: boolean | undefined;
  isEditingCategories?: boolean | undefined;
}

interface SavePageAction {
  type: InventoryItemPageActionType.Save;
  updater: (form: InventoryItemForm) => void;
}

interface SubmitPageAction {
  type: InventoryItemPageActionType.Submit;
  form: InventoryItemForm;
}

interface DeleteDocumentAction {
  type: InventoryItemPageActionType.DeleteDocument;
  document?: InventoryItemFormDocument | undefined;
  isConfirmingDeleteDocument: boolean;
}

interface ConfirmDeleteDocumentAction {
  type: InventoryItemPageActionType.ConfirmDeleteDocument;
}

interface ToggleEBayHelperAction {
  type: InventoryItemPageActionType.ToggleListingHelper;
  forcedOption?: boolean;
}

export type InventoryItemPageAction =
  | ShowLoaderAction
  | InitialLoadAction
  | ShowSuccessAction
  | ShowInvalidAction
  | ShowErrorAction
  | ChangePageViewsAction
  | SavePageAction
  | SubmitPageAction
  | DeleteDocumentAction
  | ConfirmDeleteDocumentAction
  | ToggleEBayHelperAction;
