// https://www.primefaces.org/primeblocks-react/#/application/dashboard
//  * https://www.primefaces.org/primereact/setup/
// See "Sidebar"

import classNames from "classnames";
import Icon from "components/Icon";
import Search from "components/Search";
import { Routes, routeFill } from "MainRoutes";
import { PropsWithChildren, useContext, useRef, useState } from "react";
import style from "./index.module.scss";
import SidebarCategory from "./SidebarCategory";
import SidebarNavItem from "./SidebarNavItem";
import { MediaBreakpoints } from "./MediaBreakpoints";
import { Sidebar } from "primereact/sidebar";
import { OverlayPanel } from "primereact/overlaypanel";
import { Link } from "react-router-dom";
import { ConfigContext } from "config/ConfigProvider";
import useSearchStore from "./store/useSearchStore";
import HorizontalLine from "components/HorizontalLine";
import useRmAuth from "auth/useRmAuth";
import ConfirmationModal from "components/ConfirmationModal";
import ProgressIndicator from "components/ProgressIndicator";
import StatusIcon from "components/StatusIcon";

const Layout = (props: PropsWithChildren) => {
  const { children } = props;

  const overlayPanelRef = useRef<OverlayPanel | null>(null);
  const [state, actions] = useSearchStore();
  const [visibleLeft, setVisibleLeft] = useState<boolean>(false);
  const [isRefreshingPage, setIsRefreshingPage] = useState<boolean>(false);
  const [logoutConfirmation, setLogoutConfirmation] = useState<boolean>(false);

  const config = useContext(ConfigContext);

  const { loginWithRedirect, logout, isLoading, isAuthenticated } = useRmAuth();

  const hideMenu = () => {
    setVisibleLeft(false);
  };

  if (isLoading) {
    return <ProgressIndicator fullPage isLoading={true} />;
  }

  const redirectRoute = config.baseUrl + Routes.help;

  const LoginLogoutSidebarNavItem = (props: { isMobile: boolean }) => {
    const { isMobile } = props;
    const onNavigationComplete = isMobile ? undefined : hideMenu;

    if (isAuthenticated) {
      return (
        <SidebarNavItem
          key="sign-out"
          icon="sign-out"
          label="Logout"
          onClick={() => setLogoutConfirmation(true)}
          onNavigationComplete={onNavigationComplete}
        />
      );
    }

    return (
      <SidebarNavItem
        key="sign-in"
        icon="sign-in"
        label="Login"
        onClick={() => loginWithRedirect()}
        onNavigationComplete={onNavigationComplete}
      />
    );
  };

  const LoginLogoutIcon = () => {
    if (isAuthenticated) {
      return (
        <Icon
          key="sign-out"
          name="sign-out"
          size="large"
          onClick={() => setLogoutConfirmation(true)}
          tooltipOptions={{ tooltip: "Logout", position: "bottom" }}
        />
      );
    }

    return (
      <Icon
        key="sign-in"
        name="sign-in"
        size="large"
        onClick={() => loginWithRedirect()}
        tooltipOptions={{ tooltip: "Login", position: "bottom" }}
      />
    );
  };

  const classes = {
    container: style.container,
    containerMobile: classNames(style.container, style.containerMobile),
    content: style.content,
    contentSearch: style.contentSearch,
    contentSearchMobile: classNames(style.contentSearch, style.contentSearchMobile),
    contentSearchRight: style.contentSearchRight,
    contentMain: style.contentMain,
    sidebar: classNames(style.sidebar, "sidebar"),
    sidebarMobile: classNames(style.sidebar, style.sidebarMobile, "sidebar"),
    sidebarLogo: style.sidebarLogo,
    sidebarLogoMobile: classNames(style.sidebarLogo, style.sidebarLogoMobile),
    sidebarNavigationItems: style.sidebarNavigationItems,
  };

  const { form } = state;
  const results = form.results.map((r) => {
    // TODO Add recent searches
    // TODO Add price, image, and date for each record
    return (
      <div className="mb-3">
        <Link to={routeFill.item(r.id)} onClick={() => overlayPanelRef.current?.hide()}>
          {r.name}
        </Link>
        {/* <span className="ml-3">{stringUtils.formatAsCurrency(r.price)}</span> */}
      </div>
    );
  });

  if (isRefreshingPage) {
    /**
     * Strictly need to show blank page immediately after refresh button is pressed then reloads page
     */
    return <></>;
  }

  return (
    <>
      <MediaBreakpoints.OnMobile key="mobile">
        <div key="mobile" className={classes.containerMobile}>
          <div className={classes.content}>
            <div className={classes.contentSearchMobile}>
              <div className={classes.sidebarLogoMobile}>
                <Icon
                  name="bars"
                  size="large"
                  disableBoop
                  onClick={() => {
                    setVisibleLeft(true);
                  }}
                />
                <Link to={Routes.home}>ResaleMinder</Link>
              </div>
              <StatusIcon />
              <Search
                value={form.query}
                isLoading={state.isLoading}
                onChange={(newValue: string) => actions.savePage((form) => (form.query = newValue))}
                onSubmit={() => actions.submitPage(state)}
                onFocus={(e) => {
                  if (form.results.length > 0 || state.hasSearchedAtLeastOnce) {
                    overlayPanelRef?.current?.show(e, e.target);
                  }
                }}
                rightAlign
              />
            </div>
            <main className={classes.contentMain}>{children}</main>
          </div>
        </div>
        <Sidebar visible={visibleLeft} onHide={hideMenu}>
          <div className={classes.sidebarMobile}>
            <div className={classes.sidebarLogoMobile}>
              <Icon name="tags" />
              <Link to={Routes.home}>ResaleMinder</Link>
            </div>
            <SidebarCategory label="MAIN" isVisible={isAuthenticated}>
              <SidebarNavItem
                icon="tags"
                label="Inventory"
                link={Routes.inventory}
                onNavigationComplete={hideMenu}
              />
              <SidebarNavItem
                icon="qrcode"
                label="Expenses"
                link={Routes.expenses}
                onNavigationComplete={hideMenu}
              />
              <SidebarNavItem
                icon="money-bill"
                label="Gains & Losses"
                link={Routes.gainLoss}
                onNavigationComplete={hideMenu}
              />
            </SidebarCategory>
            <SidebarCategory label="USER">
              <SidebarNavItem
                icon="user"
                label="Profile"
                link={Routes.profile}
                onNavigationComplete={hideMenu}
                isVisible={isAuthenticated}
              />
              <SidebarNavItem
                icon="cog"
                label="Settings"
                link={Routes.settings}
                onNavigationComplete={hideMenu}
                isVisible={isAuthenticated}
              />
              <SidebarNavItem
                icon="question"
                label="Help"
                link={Routes.help}
                onNavigationComplete={hideMenu}
              />
              <LoginLogoutSidebarNavItem isMobile={true} />
            </SidebarCategory>
            <SidebarCategory label="SHORTCUTS">
              <SidebarNavItem
                icon="refresh"
                label="Refresh"
                onClick={() => {
                  setIsRefreshingPage(true);
                  window.location.reload();
                }}
              />
            </SidebarCategory>
          </div>
        </Sidebar>
      </MediaBreakpoints.OnMobile>
      <MediaBreakpoints.OnDesktop key="desktop">
        <div key="desktop" className={classes.container}>
          <div className={classes.sidebar}>
            <div className={classes.sidebarLogo}>
              <Icon name="tags" />
              <Link to={Routes.home}>ResaleMinder</Link>
            </div>
            <div className={classes.sidebarNavigationItems}>
              <SidebarCategory label="MAIN" isVisible={isAuthenticated}>
                <SidebarNavItem icon="tags" label="Inventory" link={Routes.inventory} />
                <SidebarNavItem icon="qrcode" label="Expenses" link={Routes.expenses} />
                <SidebarNavItem icon="money-bill" label="Gains & Losses" link={Routes.gainLoss} />
              </SidebarCategory>
              <SidebarCategory label="USER">
                <SidebarNavItem
                  icon="user"
                  label="Profile"
                  link={Routes.profile}
                  isVisible={isAuthenticated}
                />
                <SidebarNavItem
                  icon="cog"
                  label="Settings"
                  link={Routes.settings}
                  isVisible={isAuthenticated}
                />
                <SidebarNavItem icon="question" label="Help" link={Routes.help} />
                <LoginLogoutSidebarNavItem isMobile={false} />
              </SidebarCategory>
            </div>
          </div>
          <div className={classes.content}>
            <div className={classes.contentSearch}>
              <Search
                value={form.query}
                isLoading={state.isLoading}
                onChange={(newValue: string) => actions.savePage((form) => (form.query = newValue))}
                onSubmit={() => actions.submitPage(state)}
                onFocus={(e) => {
                  if (form.results.length > 0 || state.hasSearchedAtLeastOnce) {
                    overlayPanelRef?.current?.show(e, e.target);
                  }
                }}
              />
              <div className={classes.contentSearchRight}>
                <StatusIcon />
                <Icon
                  name="user"
                  size="large"
                  linkTo={Routes.profile}
                  tooltipOptions={{ tooltip: "Profile", position: "bottom" }}
                  isVisible={isAuthenticated}
                />
                <Icon
                  name="question"
                  size="large"
                  linkTo={Routes.help}
                  tooltipOptions={{ tooltip: "Help", position: "bottom" }}
                />
                <LoginLogoutIcon />
              </div>
            </div>
            <main className={classes.contentMain}>{children}</main>
          </div>
        </div>
      </MediaBreakpoints.OnDesktop>
      <OverlayPanel key="overlay-panel" ref={overlayPanelRef}>
        <span>Results: {form.results.length}</span>
        <HorizontalLine size="small" />
        <div>{results}</div>
      </OverlayPanel>
      <ConfirmationModal
        isVisible={logoutConfirmation}
        onConfirm={() => logout({ returnTo: redirectRoute })}
        title="Logout?"
        message="Are you sure you want to logout?"
        onHide={() => setLogoutConfirmation(false)}
      />
    </>
  );
};

export default Layout;
