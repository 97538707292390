import React, { PropsWithChildren, useState } from "react";
import Config from "./Config";
import { useEffectOnLoad } from "../hooks/useEffectOnLoad";
import DataService from "../utils/DataServiceUtility";
import ProgressIndicator from "../components/ProgressIndicator";
import { OpenAPI } from "api";

const DefaultConfig: Config = {
  authDomain: undefined,
  authClientId: undefined,
  baseUrl: undefined,
  apiUrl: undefined,
  csharpApiUrl: undefined,
  disableCropForWebcam: false,
  supportEmail: "",
};

export const ConfigContext = React.createContext<Config>(DefaultConfig);

interface ConfigState {
  config: Config | null;
}

const ConfigProvider: React.FC<PropsWithChildren> = function (props: PropsWithChildren) {
  const [state, setState] = useState<ConfigState>({ config: null });
  useEffectOnLoad(async () => {
    const configPromise = DataService.getConfig();

    const config = await configPromise;

    if (config.csharpApiUrl) {
      OpenAPI.BASE = config.csharpApiUrl;
    }

    const newConfig: Config = {
      authDomain: config.authDomain,
      authClientId: config.authClientId,
      baseUrl: config.baseUrl,
      apiUrl: config.apiUrl,
      csharpApiUrl: config.csharpApiUrl,
      disableCropForWebcam: config.disableCropForWebcam,
      supportEmail: config.supportEmail ?? "resaleminder@proton.me",
    };

    setState({ config: newConfig });
  });

  if (!state.config) {
    return <ProgressIndicator isLoading={true} fullPage />;
  }

  return <ConfigContext.Provider value={state.config}>{props.children}</ConfigContext.Provider>;
};

export default ConfigProvider;
