import objectUtils from "utils/objectUtils";
import useStore from "hooks/useStore";
import { getProfilePageActions } from "./profilePageStoreActionMethods";
import { ProfilePageAction, ProfilePageActionType } from "./profilePageStoreActions";
import { ProfileForm } from "api";

export interface ProfilePageState {
  form: ProfileForm;
}

export interface PageState {
  page: ProfilePageState;
  /**
   * @deprecated Switched to using edit only fields and save
   */
  isEditing: boolean;
  isLoading: boolean;
  hasChanges: boolean;
}
const initialProfilePageState: PageState = {
  page: { form: {} },
  isEditing: false,
  isLoading: true,
  hasChanges: false,
};
const ProfilePageReducer = function (
  readonlyState: PageState,
  action: ProfilePageAction
): PageState {
  const mutableState = objectUtils.deepCopyObject(readonlyState);
  switch (action.type) {
    case ProfilePageActionType.ShowLoader:
      mutableState.isLoading = true;
      break;
    case ProfilePageActionType.InitialLoad:
    case ProfilePageActionType.ShowSuccess:
    case ProfilePageActionType.ShowInvalid:
      mutableState.page = { form: action.form };
      mutableState.isEditing = true;
      mutableState.isLoading = false;
      break;
    case ProfilePageActionType.ShowError:
      mutableState.isLoading = false;
      break;
    case ProfilePageActionType.ChangePageViews: {
      const { isEditing } = action;

      if (isEditing !== undefined) {
        mutableState.isEditing = isEditing;
      }
      break;
    }
    case ProfilePageActionType.Save:
      if (mutableState.page && action.updater) {
        action.updater(mutableState.page.form);
        mutableState.hasChanges = true;
      }
      break;
    case ProfilePageActionType.Submit:
      mutableState.page.form = action.form;
      mutableState.isLoading = false;
      mutableState.isEditing = true;
      mutableState.hasChanges = false;
      break;
  }
  return mutableState;
};
const useProfilePageStore = function () {
  return useStore(initialProfilePageState, ProfilePageReducer, getProfilePageActions);
};
export default useProfilePageStore;
