import { InventoryItemForm, InventoryItemFormDocument } from "api/GeneratedApiClients";
import moment from "moment";
import uuid from "utils/uuid";
import useStore from "../../../hooks/useStore";
import objectUtils from "../../../utils/objectUtils";
import {
  getInventoryItemPageActions,
  InventoryItemActionMethods,
} from "./inventoryItemActionMethods";
import { InventoryItemPageAction, InventoryItemPageActionType } from "./inventoryItemActions";

export interface PageState {
  form: InventoryItemForm;
  isNew: boolean;
  isEditing: boolean;
  isUploadingFile: boolean;
  isTakingPicture: boolean;
  documentToDelete?: InventoryItemFormDocument | undefined;
  isConfirmingDeleteDocument: boolean;
  isEditingCategories: boolean;
  isListingHelperOpen: boolean;
  hasChanges: boolean;
  isLoading: boolean;
}

const initialPageState: PageState = {
  form: {
    id: uuid.empty(),
    itemId: uuid.empty(),
    purchasePrice: 0.0,
    purchaseDate: moment(),
    isSold: false,
    categories: [],
    documents: [],
    invoices: [],
    notes: [],
    listings: [],
    availableCategories: [],
  },
  isNew: true,
  isEditing: true,
  isUploadingFile: false,
  isTakingPicture: false,
  isEditingCategories: false,
  isConfirmingDeleteDocument: false,
  isListingHelperOpen: false,
  hasChanges: false,
  isLoading: true,
};

const pageReducer = function (
  readonlyState: PageState,
  action: InventoryItemPageAction
): PageState {
  const mutableState = objectUtils.deepCopyObject(readonlyState);

  switch (action.type) {
    case InventoryItemPageActionType.ShowLoader: {
      const { isVisible } = action;

      mutableState.isLoading = isVisible !== undefined ? isVisible : true;
      break;
    }
    case InventoryItemPageActionType.InitialLoad:
    case InventoryItemPageActionType.ShowInvalid:
    case InventoryItemPageActionType.ShowSuccess: {
      mutableState.form = action.form;

      if (!mutableState.form.categories) {
        mutableState.form.categories = [];
      }

      const isNew = action.type === InventoryItemPageActionType.InitialLoad ? action.isNew : false;

      mutableState.isNew = isNew;
      mutableState.isLoading = false;
      mutableState.hasChanges = false;
      mutableState.isEditing = true; // mutableState.page.id === uuid.empty();
      break;
    }
    case InventoryItemPageActionType.ShowError:
      mutableState.isLoading = false;
      break;
    case InventoryItemPageActionType.ChangePageViews: {
      const { isEditing, isTakingPicture, isUploadingFile, isEditingCategories } = action;

      if (isEditing !== undefined) {
        mutableState.isEditing = true;
      }

      const updateStateIfEditing = (
        flag: boolean | undefined,
        ifTrue: () => void,
        ifFalse: () => void
      ) => {
        if (flag !== undefined) {
          flag === true && mutableState.isEditing ? ifTrue() : ifFalse();
        }
      };

      updateStateIfEditing(
        isUploadingFile,
        () => (mutableState.isUploadingFile = true),
        () => {
          mutableState.isUploadingFile = false;
          mutableState.isConfirmingDeleteDocument = false;
        }
      );

      updateStateIfEditing(
        isTakingPicture,
        () => (mutableState.isTakingPicture = true),
        () => (mutableState.isTakingPicture = false)
      );

      updateStateIfEditing(
        isEditingCategories,
        () => (mutableState.isEditingCategories = true),
        () => (mutableState.isEditingCategories = false)
      );

      break;
    }
    case InventoryItemPageActionType.Save:
      if (mutableState.form && action.updater) {
        action.updater(mutableState.form);
        mutableState.hasChanges = true;
      }
      break;
    case InventoryItemPageActionType.Submit:
      mutableState.form = action.form;
      mutableState.isNew = false;
      mutableState.isEditing = true;
      mutableState.hasChanges = false;
      mutableState.isLoading = false;
      break;
    case InventoryItemPageActionType.DeleteDocument: {
      const { document } = action;

      if (!mutableState.isEditing || document === undefined) {
        mutableState.isConfirmingDeleteDocument = false;
        mutableState.documentToDelete = undefined;
      } else {
        mutableState.documentToDelete = document;
        mutableState.isConfirmingDeleteDocument = true;
      }

      //TODO Add logic to actually delete the document from the page state.
      // mutableState.hasChanges = true;
      break;
    }
    case InventoryItemPageActionType.ConfirmDeleteDocument:
      if (
        mutableState.isEditing &&
        mutableState.form &&
        mutableState.documentToDelete?.id !== undefined
      ) {
        const documents = mutableState.form.documents ?? [];
        const documentToDelete = mutableState.documentToDelete;

        mutableState.form.documents = documents.filter((d) => d.id !== documentToDelete.id);

        mutableState.isConfirmingDeleteDocument = false;
        mutableState.documentToDelete = undefined;
      }
      break;
    case InventoryItemPageActionType.ToggleListingHelper: {
      const { forcedOption } = action;
      mutableState.isListingHelperOpen =
        forcedOption !== undefined ? forcedOption : !mutableState.isListingHelperOpen;
      break;
    }
  }

  return mutableState;
};

const useInventoryItemStore = (): [PageState, InventoryItemActionMethods] => {
  return useStore(initialPageState, pageReducer, getInventoryItemPageActions);
};

export default useInventoryItemStore;
