import style from "./index.module.scss";
import {
  GainLossFormExpenseType,
  GainLossFormYear,
  GainLossFormYearItem,
} from "api/GeneratedApiClients";
import Button from "components/Button";
import Dropdown, { DropdownItem } from "components/Dropdown";
import LayoutContent, { StatProps } from "components/LayoutContent";
import ProgressIndicator from "components/ProgressIndicator";
import Table, { TableSimpleSort } from "components/Table";
import { useEffectOnLoad } from "hooks/useEffectOnLoad";
import useGainLossStore from "./store/useGainLossStore";
import { gainLossTableFieldsFactory } from "./tableFields";
import IconSummary, { IconSummaryIcon } from "components/IconSummary";

const GainLossPage = () => {
  const [state, actions] = useGainLossStore();

  useEffectOnLoad(
    () => actions.loadPage(),
    () => actions.unloadPage()
  );

  if (state.isLoading) {
    return <ProgressIndicator isLoading={state.isLoading} />;
  }

  if (state.page === undefined) {
    // TODO Return <NotFound />
    return <></>;
  }

  const sort: TableSimpleSort<GainLossFormYearItem> = {
    field: "gainLossAmount",
    direction: "desc",
  };

  const page = state.page;
  const selectedYear = page.selectedYear ?? "unknown";
  const availableYears = page.availableYears ?? [];
  const fields = gainLossTableFieldsFactory();

  const currentGainLossYear: GainLossFormYear =
    page.gainLossYears.find((y) => y.year === selectedYear) || ({} as GainLossFormYear);
  const grossGainLoss = currentGainLossYear.gross ?? 0;
  const totalExpenses = currentGainLossYear.totalExpenses ?? 0;
  const netGainLoss = currentGainLossYear.net ?? 0;
  const expenseTypes = currentGainLossYear.expenseTypes ?? [];

  const stats: StatProps[] = [
    {
      label: "Gross",
      currency: grossGainLoss,
    },
    {
      label: "Expenses",
      currency: totalExpenses,
    },
    {
      label: "Net",
      currency: netGainLoss,
    },
  ];

  let selectedDropItem = null;
  const dropdownItems: DropdownItem[] = [];

  for (const year of availableYears) {
    const item: DropdownItem = {
      label: year.toString(),
      value: year.toString(),
    };

    dropdownItems.push(item);

    if (year === selectedYear) {
      selectedDropItem = item;
    }
  }

  if (selectedDropItem === null) {
    selectedDropItem = dropdownItems[0];
  }

  const icons: IconSummaryIcon[] = [];

  if (expenseTypes.includes(GainLossFormExpenseType.Fee)) {
    icons.push({ iconName: "dollar", tooltip: "Fees" });
  }
  if (expenseTypes.includes(GainLossFormExpenseType.Supply)) {
    icons.push({ iconName: "shopping-bag", tooltip: "Supplies" });
  }
  if (expenseTypes.includes(GainLossFormExpenseType.Mileage)) {
    icons.push({ iconName: "car", tooltip: "Mileage" });
  }
  if (expenseTypes.includes(GainLossFormExpenseType.Other)) {
    icons.push({ iconName: "calculator", tooltip: "Other" });
  }

  return (
    <LayoutContent
      label="Gains & Losses"
      icon="money-bill"
      primaryButton={
        <Button
          label="Refresh"
          type="primary"
          icon="refresh"
          onClick={() => {
            alert("You clicked the button");
          }}
        />
      }
      stats={stats}
    >
      <div>
        <div className="formgrid grid">
          <div className="field col-12 md:col-3 mb-3">
            <Dropdown
              label="Year"
              items={dropdownItems}
              selectedItem={selectedDropItem}
              onChange={(newItem: DropdownItem) => {
                actions.changeSelectedYear(newItem.value);
              }}
            />
          </div>
          <div className="field col-12 md:col-6 mb-9">
            <IconSummary label="Expense Types" icons={icons} />
          </div>
        </div>

        <div className={style.table}>
          <Table fields={fields} values={currentGainLossYear.items} simpleSort={sort} />
        </div>
      </div>
    </LayoutContent>
  );
};

export default GainLossPage;
