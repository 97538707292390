import useRmAuth from "auth/useRmAuth";
import LayoutContent from "components/LayoutContent";
import { ConfigContext } from "config/ConfigProvider";
import useNav from "hooks/useNav";
import { useContext, useEffect } from "react";

const HomePage = () => {
  const { user, isAuthenticated } = useRmAuth();
  const config = useContext(ConfigContext);
  const [navigate] = useNav();

  useEffect(() => {
    if (!isAuthenticated || user === undefined) {
      navigate.to("/help");
    }
  }, [navigate, isAuthenticated, user]);

  if (!isAuthenticated || user === undefined) {
    return <></>;
  }

  const penguin = `${config.baseUrl}/penguin.webp`;

  return (
    <LayoutContent label="Welcome to ResaleMinder" image={penguin}>
      <div>You are logged in as {user.email}</div>
    </LayoutContent>
  );
};

export default HomePage;
