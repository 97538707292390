/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SettingsForm } from '../models/SettingsForm';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SettingsService {

    /**
     * @returns SettingsForm Success
     * @throws ApiError
     */
    public static getSettings(): CancelablePromise<SettingsForm> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Settings',
        });
    }

    /**
     * @returns SettingsForm Success
     * @throws ApiError
     */
    public static postSettings({
requestBody,
}: {
requestBody?: SettingsForm,
}): CancelablePromise<SettingsForm> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Settings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
