import { SearchForm } from "api/GeneratedApiClients";

export enum SearchPageActionType {
  ShowLoader = 0,
  InitialLoad = 1,
  ShowSuccess = 2,
  ShowError = 3,
  ShowInvalid = 4,
  Save = 5,
  Submit = 6,
}

interface ShowLoaderAction {
  type: SearchPageActionType.ShowLoader;
}

interface InitialLoadAction {
  type: SearchPageActionType.InitialLoad;
  form: SearchForm;
  isNew: boolean;
}

interface ShowSuccessAction {
  type: SearchPageActionType.ShowSuccess;
  form: SearchForm;
}

interface ShowInvalidAction {
  type: SearchPageActionType.ShowInvalid;
  form: SearchForm;
}

interface ShowErrorAction {
  type: SearchPageActionType.ShowError;
  error: string;
}

interface SavePageAction {
  type: SearchPageActionType.Save;
  updater: (form: SearchForm) => void;
}

interface SubmitPageAction {
  type: SearchPageActionType.Submit;
  form: SearchForm;
}

export type SearchPageAction =
  | ShowLoaderAction
  | InitialLoadAction
  | ShowSuccessAction
  | ShowInvalidAction
  | ShowErrorAction
  | SavePageAction
  | SubmitPageAction;
