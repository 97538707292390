const deepCopyObject = function <T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
};

const isString = (value: unknown) => {
  return typeof value === "string";
};

const objectUtils = {
  deepCopyObject: deepCopyObject,
  isString: isString,
};

export default objectUtils;
