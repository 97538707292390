import Input, { InputType } from "components/Input";
import moment, { Moment } from "moment";
import Calendar from "components/Calendar";
import Button from "components/Button";
import Table, { TableSimpleSort, tableFieldsFactory } from "components/Table";
import uuid from "utils/uuid";
import { useState } from "react";
import objectUtils from "utils/objectUtils";
import { Dialog } from "primereact/dialog";
import Icon from "components/Icon";
import { stringUtils } from "utils/stringUtils";
import { useEffectOnLoad } from "hooks/useEffectOnLoad";
import { ExpenseFormSupply, ExpenseFormSupplyPurchase } from "api/GeneratedApiClients";

import style from "./index.module.scss";
import listUtils from "utils/listUtils";
import dateUtils from "utils/dateUtils";

interface ExpenseSupplyModalProps {
  form: ExpenseFormSupply | undefined;
  isVisible: boolean;
  onSave: (form: ExpenseFormSupply) => void;
  onHide: () => void;
}

type UndoAction = "SetUndo" | "ResetUndo" | undefined;

type PartialSupply = Omit<Partial<ExpenseFormSupply>, "purchases"> & {
  purchases: ExpenseFormSupplyPurchase[];
};

type PartialSupplyPurchase = Partial<ExpenseFormSupplyPurchase>;

const ExpenseSupplyModal = (props: ExpenseSupplyModalProps) => {
  const { form: nullableForm, isVisible, onSave, onHide } = props;

  const newSupply: PartialSupply = {
    id: uuid.new(),
    name: "",
    description: "",
    defaultPurchaseLocation: "",
    lastUpdated: moment(),
    purchases: [],
  };

  const defaultPurchaseFactory = (): PartialSupplyPurchase => {
    return {
      id: uuid.new(),
      amount: 0,
      purchaseLocation: "",
      lastUpdated: moment(),
    };
  };

  const [state, setState] = useState<PartialSupply>(newSupply);
  const [undoState, setUndoState] = useState<PartialSupply | undefined>(undefined);
  const [newPurchase, setNewPurchase] = useState<PartialSupplyPurchase>(defaultPurchaseFactory);
  const [showNewPurchase, setShowNewPurchase] = useState<boolean>(true);

  const setNewState = (updater: (newSupply: PartialSupply) => void, undoAction?: UndoAction) => {
    if (undoAction === "SetUndo") {
      setUndoState(state);
    }

    if (undoAction === "ResetUndo") {
      setUndoState(undefined);
    }

    const newState = objectUtils.deepCopyObject(state);
    updater(newState);
    setState(newState);
  };

  /**
   * TODO: Find an alternative way to intercept the value of new state on saving the form.
   */
  const addNewAmountToSupplyIfSufficientlyComplete = (
    interceptor?: (newSupply: PartialSupply) => void
  ) => {
    setNewState((newState: PartialSupply) => {
      if (newPurchase && newPurchase.amount !== undefined && newPurchase.amount > 0) {
        const purchaseLocation = stringUtils.isNullOrWhitespace(newPurchase.purchaseLocation)
          ? newState.defaultPurchaseLocation
          : newPurchase.purchaseLocation;

        const newPurchaseForSupply: ExpenseFormSupplyPurchase = {
          id: uuid.new(),
          amount: newPurchase.amount,
          purchaseLocation: purchaseLocation ?? "",
          date: newPurchase.date ?? moment(),
          lastUpdated: moment(),
        };

        newState.purchases.push(newPurchaseForSupply);

        setNewPurchase(defaultPurchaseFactory);
      }

      if (interceptor) {
        interceptor(newState);
      }
    }, "ResetUndo");
  };

  const setNewAmountState = (updater: (newSupplyAmount: PartialSupplyPurchase) => void) => {
    const newAmountState = objectUtils.deepCopyObject(newPurchase);
    updater(newAmountState);
    setNewPurchase(newAmountState);
  };

  useEffectOnLoad(
    () => {
      setNewState((newState: PartialSupply) => {
        newState.id = nullableForm?.id ?? uuid.new();
        newState.name = nullableForm?.name ?? "";
        newState.description = nullableForm?.description ?? "";
        newState.defaultPurchaseLocation = nullableForm?.defaultPurchaseLocation ?? "";
        newState.purchases = nullableForm?.purchases ?? [];
        newState.lastUpdated = nullableForm?.lastUpdated ?? moment();
      }, "ResetUndo");
    },
    undefined,
    [nullableForm]
  );

  const simpleSort: TableSimpleSort<ExpenseFormSupplyPurchase> = {
    field: "date",
    direction: "desc",
  };

  const purchasesFields = tableFieldsFactory<ExpenseFormSupplyPurchase>({
    amount: {
      label: "Purchase",
      body: (purchaseAmount: number) => {
        return stringUtils.formatAsCurrency(purchaseAmount);
      },
      sortable: true,
      width: "025",
    },
    purchaseLocation: {
      label: "Location",
      sortable: true,
      width: "040",
    },
    date: {
      label: "Date",
      body: (lastUpdated: Moment) => {
        return moment(lastUpdated).format("M/D/yyyy");
      },
      isDate: true,
      sortable: true,
      width: "025",
    },
    id: {
      label: "",
      body: (id: string, suppliesForm: ExpenseFormSupplyPurchase) => {
        return (
          <div className="flex flex-direction-row">
            <Icon
              name="trash"
              className={style.delete}
              onClick={() => {
                setNewState((newState: PartialSupply) => {
                  const purchaseWithoutThisPurchase = listUtils.removeItem(
                    suppliesForm,
                    newState.purchases
                  );
                  newState.purchases = purchaseWithoutThisPurchase;
                }, "SetUndo");
              }}
            />
          </div>
        );
      },
      width: "010",
    },
  });

  const title = nullableForm === undefined ? "Add Supply" : "Edit Supply";
  const purchaseLocationPlaceholder = stringUtils.isNullOrWhitespace(state.defaultPurchaseLocation)
    ? ""
    : `${state.defaultPurchaseLocation} (default)`;

  return (
    <Dialog
      visible={isVisible}
      header={
        <>
          <span className="pr-2">{title}</span>
          <Icon name="exclamation-circle" />
        </>
      }
      footer={
        <div className="flex align-items-center justify-content-between">
          <i>Last Update: {dateUtils.format(state.lastUpdated)}</i>
          <Button
            label="Undo delete"
            type="secondary"
            icon="replay"
            isVisible={undoState !== undefined}
            onClick={() => {
              if (undoState !== undefined) {
                setState(undoState);
                setUndoState(undefined);
              }
            }}
          />
          <Button
            label="Save"
            type="primary"
            icon="plus-circle"
            onClick={() => {
              addNewAmountToSupplyIfSufficientlyComplete((finalState: PartialSupply) => {
                const updatedSupply: ExpenseFormSupply = {
                  id: finalState.id ?? uuid.new(),
                  name: finalState.name ?? "",
                  description: finalState.description ?? "",
                  purchases: finalState.purchases,
                  defaultPurchaseLocation: finalState.defaultPurchaseLocation ?? "",
                  lastUpdated: moment(),
                };

                onSave(updatedSupply);
              });
            }}
          />
        </div>
      }
      closable
      closeOnEscape
      draggable
      style={{ width: "60%", minWidth: "850px" }}
      onHide={() => onHide()}
    >
      <div className="formgrid grid">
        <div className="field col-12">
          <i>
            Supplys are often recurring or periodic. For multiple instances of a supplies, add a new
            purchase in the purchases section below
          </i>
        </div>
      </div>
      <div className="formgrid grid">
        <div className="field col-4">
          <Input
            id="name"
            type={InputType.Text}
            label="Name"
            icon="box"
            value={state.name}
            onChange={(newValue: string | undefined) => {
              setNewState(
                (newState: PartialSupply) => (newState.name = newValue ?? ""),
                "ResetUndo"
              );
            }}
          />
        </div>
        <div className="field col-8">
          <Input
            id="defaultPurchaseLocation"
            type={InputType.Text}
            label="Default Purchase Location"
            icon="building"
            value={state.defaultPurchaseLocation}
            onChange={(newValue: string | undefined) => {
              setNewState(
                (newState: PartialSupply) => (newState.defaultPurchaseLocation = newValue ?? ""),
                "ResetUndo"
              );
            }}
          />
        </div>
      </div>
      <div className="formgrid grid ">
        <div className="field col-12">
          <Input
            id="description"
            type={InputType.Text}
            label="Description"
            icon="book"
            value={state.description}
            onChange={(newValue: string | undefined) => {
              setNewState(
                (newState: PartialSupply) => (newState.description = newValue ?? ""),
                "ResetUndo"
              );
            }}
          />
        </div>
      </div>
      <div className="formgrid grid ">
        <div className="field col-12 flex justify-content-end">
          <Button
            label={showNewPurchase ? "Hide new purchase" : "Show new purchase"}
            type="primary"
            subtype="text-only"
            icon={showNewPurchase ? "eye-slash" : "eye"}
            onClick={() => {
              setShowNewPurchase(!showNewPurchase);
            }}
          />
        </div>
      </div>
      {showNewPurchase ? (
        <>
          <div className="formgrid grid">
            <div className="field col-3">
              <Input
                id="amount"
                type={InputType.Currency}
                label="New Purchase"
                value={newPurchase.amount}
                onChange={(newValue: number | undefined) => {
                  setNewAmountState((updater) => (updater.amount = newValue ?? 0));
                }}
                selectOnFocus
              />
            </div>
            <div className="field col-5">
              <Input
                id="purchaseLocation"
                type={InputType.Text}
                label="Location"
                icon="building"
                value={newPurchase.purchaseLocation}
                onChange={(newValue: string | undefined) => {
                  setNewAmountState((updater) => (updater.purchaseLocation = newValue ?? ""));
                }}
                placeholder={purchaseLocationPlaceholder}
              />
            </div>
            <div className="field col-3">
              <Calendar
                id="date"
                label="Date of purchase"
                iconPosition="left"
                singleValue={newPurchase.date}
                maxDate={moment()}
                onChange={(newValue: Moment | undefined) => {
                  setNewAmountState((updater) => (updater.date = newValue ?? moment()));
                }}
              />
            </div>
            <div className="field col-1 flex align-items-end justify-content-end">
              <Button
                type="secondary"
                icon="plus"
                onClick={() => addNewAmountToSupplyIfSufficientlyComplete()}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="formgrid grid">
        <div className="field col-12">
          <Table
            fields={purchasesFields}
            values={state.purchases}
            simpleSort={simpleSort}
            itemsPerPage={[5, 10, 15]}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ExpenseSupplyModal;
