import Icon from "components/Icon";
import { stringUtils } from "utils/stringUtils";

export interface BasicLinkProps {
  prefix?: string;
  text?: string;
  url: string;
  isEmail?: boolean;
  open?: "here" | "new" | undefined;
}

const BasicLink = (props: BasicLinkProps) => {
  const { prefix, text, url, isEmail, open } = props;

  const target = open === "here" ? undefined : open === "new" ? "_blank" : undefined;

  const PrefixText = () => {
    if (stringUtils.isNullOrWhitespace(prefix)) {
      return <></>;
    }

    return <span>{prefix}</span>;
  };
  const ExternalLinkIcon = () => {
    if (target === "_blank") {
      return <Icon name="external-link" className="ml-2" />;
    }

    return <></>;
  };

  const hrefUrl = isEmail ? `mailto:${url}` : url;

  if (stringUtils.isNullOrWhitespace(text)) {
    return (
      <>
        <PrefixText />
        <a href={hrefUrl} target={target}>
          {url}
          <ExternalLinkIcon />
        </a>
      </>
    );
  }

  return (
    <>
      <PrefixText />
      <a href={hrefUrl} target={target}>
        {text}
        <ExternalLinkIcon />
      </a>
    </>
  );
};

export default BasicLink;
