import Button from "components/Button";
import ProgressIndicator from "components/ProgressIndicator";
import Table, { TableSimpleSort } from "components/Table";
import { useEffectOnLoad } from "hooks/useEffectOnLoad";
import useInventoryStore from "./store/useInventoryStore";
import { inventoryTableFieldsFactory } from "./tableFields";

import { InventoryFormItem } from "api/GeneratedApiClients";
import { Routes } from "MainRoutes";
import inventoryConstants from "pages/inventoryConstants";
import LayoutContent, { NormalStatProps } from "components/LayoutContent";
import useNav from "hooks/useNav";

const InventoryPage = () => {
  const [state, actions] = useInventoryStore();

  const [navigate] = useNav();

  useEffectOnLoad(
    () => actions.loadPage(),
    () => actions.unloadPage()
  );

  const fields = inventoryTableFieldsFactory();

  const sort: TableSimpleSort<InventoryFormItem> = {
    field: "purchaseDate",
    direction: "desc",
  };

  if (state.isLoading) {
    return <ProgressIndicator isLoading={state.isLoading} />;
  }

  const items = state.page?.inventoryItems ?? [];
  const itemCount = state.page.inventoryCount ?? 0;
  const documentCount = state.page.documentCount ?? 0;

  const stats: NormalStatProps[] = [
    { label: "Items", value: itemCount },
    { label: "Documents", value: documentCount },
  ];

  return (
    <LayoutContent
      label="Inventory"
      stats={stats}
      icon="tag"
      primaryButton={
        <Button
          label="Add"
          type="primary"
          icon="plus-circle"
          onClick={() => {
            navigate.toId(Routes.item, inventoryConstants.newInventoryItemId);
          }}
        />
      }
    >
      <div>
        <Table fields={fields} values={items} simpleSort={sort} />
      </div>
    </LayoutContent>
  );
};

export default InventoryPage;
