import { Carousel } from "primereact/carousel";
import style from "./index.module.scss";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import Button from "components/Button";

const responsiveOptions = [
  {
    breakpoint: "2800px",
    numVisible: 3,
    numScroll: 3,
  },
  {
    breakpoint: "900px",
    numVisible: 2,
    numScroll: 2,
  },
  {
    breakpoint: "655px",
    numVisible: 1,
    numScroll: 1,
  },
];

export interface GalleryImage {
  id: string;
  image: string;
  name: string;
  altText: string;
}

export interface GalleryProps {
  images: GalleryImage[];
  noItemsDisplay?: string;
  isDisabled?: boolean | undefined;
  onDelete: (id: string) => void;
}

export interface PreviewDocument {
  isVisible: boolean;
  imageUrl?: string;
}

const Gallery = (props: GalleryProps) => {
  const { images, isDisabled, noItemsDisplay, onDelete } = props;

  const [previewDocument, setShowPreviewDoc] = useState<PreviewDocument>({
    isVisible: false,
  });

  const galleryImages = images.length > 0 ? images : [{}];

  const template = (item: GalleryImage, itemCount: number) => {
    const { id, image, name, altText } = item;

    const imageSource = image;

    if (itemCount <= 0) {
      return (
        <div className={style["productItem"]}>
          <div className={style["productItemContent"]}>
            <div className={style.productImageContainer}>
              <div className={style.productImageEmpty}>{noItemsDisplay ?? "No items."}</div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={style.productItem}>
        <div className={style.productItemContent}>
          <div className={style.galleryItemClose}>
            <Button
              icon="trash"
              type="warning"
              subtype="outlined"
              isRounded={true}
              isDisabled={isDisabled}
              onClick={() => onDelete(id)}
            />
          </div>
          <div className={style.productImageContainer}>
            <img
              src={imageSource}
              onError={(e) =>
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ((e.target as any).src =
                  "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
              }
              alt={altText}
              className={style.productImage}
              onClick={() => {
                setShowPreviewDoc({
                  isVisible: true,
                  imageUrl: imageSource,
                });
              }}
            />
          </div>
          <div>
            <h4 className="mb-1">{name}</h4>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={style.carouselContainer}>
      <div className="resale-minder-gallery">
        <Carousel
          value={galleryImages}
          responsiveOptions={responsiveOptions}
          className="custom-carousel"
          style={{ maxWidth: "1280px" }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          itemTemplate={(item: any) => template(item, images.length)}
        />
      </div>
      <Dialog
        visible={previewDocument.isVisible}
        header="Preview Document"
        onHide={() => {
          setShowPreviewDoc({
            isVisible: false,
          });
        }}
        breakpoints={{ "960px": "90vw", "640px": "100vw" }}
        style={{ width: "60vw" }}
      >
        <div className={style.previewDocument}>
          <img src={previewDocument.imageUrl} alt="Preview document" />
        </div>
      </Dialog>
    </div>
  );
};

export default Gallery;
