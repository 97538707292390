import objectUtils from "./objectUtils";

type IKeyable = {
  key: string;
};

/**
 * Read as `Id-able`
 */
type Idable = {
  id: string;
};

function firstOrDefaultByKey<T extends IKeyable>(key: string, list: T[]): T | undefined {
  const item = list.find((l) => l.key === key);

  return item;
}

function firstOrDefault<T>(list: T[], searchCriteria: (t: T) => boolean): T | undefined {
  const item = list.find((l) => searchCriteria(l));
  return item;
}

/**
 * This function is designed to reduce the primary list of items when the same item exists in the secondary list of items. The filtering will occur based on the user's matching criteria.
 *
 * @param primary
 * @param secondary
 * @param filterCriteria
 * @returns
 */
function outersect<T>(primary: T[], secondary: T[], filterCriteria: (a: T, b: T) => boolean): T[] {
  if (primary === undefined) {
    return [];
  }

  const copyOfPrimary = objectUtils.deepCopyObject(primary);

  const outerSectionOfItems = copyOfPrimary.filter((value: T) => {
    const matchingValue = firstOrDefault(secondary, (secondaryValue: T) =>
      filterCriteria(value, secondaryValue)
    );
    return matchingValue === undefined;
  });

  return outerSectionOfItems ?? [];
}

const removeItem = <T extends Idable>(item: T, list: T[]): T[] => {
  return list.filter((i) => item.id !== i.id);
};

const replaceItemById = <T extends Idable>(item: T, list: T[]): T[] => {
  const copyOfList = objectUtils.deepCopyObject(list);
  const index = copyOfList.findIndex((i) => i.id === item.id);

  copyOfList[index] = item;
  return copyOfList;
};

const insert = <T>(item: T, list: T[]): T[] => {
  list.unshift(item);
  return list;
};

export interface ListUtils {
  firstOrDefaultByKey: <T extends IKeyable>(key: string, list: T[]) => T | undefined;
  firstOrDefault: <T>(list: T[], searchCriteria: (t: T) => boolean) => T | undefined;
  outersect: <T>(primary: T[], secondary: T[], filterCriteria: (a: T, b: T) => boolean) => T[];
  removeItem: <T extends Idable>(item: T, list: T[]) => T[];
  replaceItemById: <T extends Idable>(item: T, list: T[]) => T[];
  insert: <T>(item: T, list: T[]) => T[];
}

const listUtils: ListUtils = {
  firstOrDefaultByKey,
  firstOrDefault,
  outersect,
  removeItem,
  replaceItemById,
  insert,
};

export default listUtils;
