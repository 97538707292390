import { Divider } from "primereact/divider";
import style from "./index.module.scss";

export interface HorizontalLineProps {
  size?: "small" | "medium" | "large";
}

const HorizontalLine = (props: HorizontalLineProps) => {
  const { size } = props;

  if (size === "small") {
    return (
      <div className={style.smallDivider}>
        <Divider type="solid" />
      </div>
    );
  }

  return (
    <div className={style.divider}>
      <Divider type="solid" />
    </div>
  );
};

export default HorizontalLine;
