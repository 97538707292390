import { InventoryForm } from "api";

export enum InventoryPageActionType {
  ShowLoader = 0,
  InitialLoad = 1,
  ShowSuccess = 2,
  ShowError = 3,
  ShowInvalid = 4,
}

interface ShowLoaderAction {
  type: InventoryPageActionType.ShowLoader;
}

interface InitialLoadAction {
  type: InventoryPageActionType.InitialLoad;
  form: InventoryForm;
}

interface ShowSuccessAction {
  type: InventoryPageActionType.ShowSuccess;
  form: InventoryForm;
}

interface ShowInvalidAction {
  type: InventoryPageActionType.ShowInvalid;
  form: InventoryForm;
}

interface ShowErrorAction {
  type: InventoryPageActionType.ShowError;
  error: string;
}

export type InventoryPageAction =
  | ShowLoaderAction
  | InitialLoadAction
  | ShowSuccessAction
  | ShowInvalidAction
  | ShowErrorAction;
