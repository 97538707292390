import BasicLink from "components/BasicLink";
import Panel from "components/Panel";
import QuestionAnswer from "./questionAnswer";
import { useContext } from "react";
import { ConfigContext } from "config/ConfigProvider";

const UploadMultipleInventoryItems = () => {
  const config = useContext(ConfigContext);

  return (
    <Panel header={"How to add multiple inventory items at once?"} isCollapsed={true}>
      <QuestionAnswer
        question="How to add multiple inventory items at once?"
        answer="Bulk upload for inventory items and expenses is coming soon! If you can add items one at a time for now, that's great. Otherwise we're here to support you. Please follow these steps to help provide the data to support:"
      >
        <p className="m-0">
          <ol>
            <li>Create an new inventory item titled "Multiple Items Upload"</li>
            <li>Go to document section to upload a new document</li>
            <li>Add your spreadsheet with inventory and expenses</li>
            <li>
              Send an email to <BasicLink url={config.supportEmail} isEmail /> including your login
              email address
            </li>
            <li>
              Support will review your spreadsheet and add your inventory items and expenses in
              24-48hrs
            </li>
            <li>Support will also remove the inventory item titled "Multiple Items Upload"</li>
          </ol>
        </p>
      </QuestionAnswer>
    </Panel>
  );
};

export default UploadMultipleInventoryItems;
