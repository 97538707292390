import Button from "components/Button";
import LayoutContent from "components/LayoutContent";
import useExpenseStore from "./store/useExpenseStore";
import { useEffectOnLoad } from "hooks/useEffectOnLoad";
import ButtonWithSubActions from "components/ButtonWithSubActions";
import {
  ExpenseForm,
  ExpenseFormFee,
  ExpenseFormMileage,
  ExpenseFormOther,
  ExpenseFormSupply,
} from "api/GeneratedApiClients";
import Table, { TableSimpleSort } from "components/Table";
import {
  feesFieldsFactory,
  mileageFieldsFactory,
  otherFieldsFactory,
  suppliesFieldsFactory,
} from "./tableFields";
import ProgressIndicator from "components/ProgressIndicator";
import { ExpensePageActionTypeModalType } from "./store/expenseActions";
import ExpenseFeeModal from "./expenseFeeModal";
import ConfirmationModal from "components/ConfirmationModal";
import listUtils from "utils/listUtils";
import ExpenseMileageModal from "./expenseMileageModal";
import { stringUtils } from "utils/stringUtils";
import ExpenseOtherModal from "./expenseOtherModal";
import dateUtils from "utils/dateUtils";
import ExpenseSupplyModal from "./expenseSupplyModal";
import Icon from "components/Icon";

const ExpensePage = () => {
  const [state, actions] = useExpenseStore();

  useEffectOnLoad(
    () => actions.loadPage(),
    () => actions.unloadPage()
  );

  if (state.isLoading) {
    return <ProgressIndicator isLoading={state.isLoading} />;
  }

  const { form } = state;

  if (!form) {
    return <div>Unable to load Expenses</div>;
  }

  const feesAndSuppliesSort: TableSimpleSort<ExpenseFormFee> = {
    field: "lastUpdated",
    direction: "desc",
  };

  const mileageAndOtherSort: TableSimpleSort<ExpenseFormMileage> = {
    field: "date",
    direction: "desc",
  };

  const itemsPerPage = [5, 10, 15];

  const feesCount = form.fees?.length ?? 0;
  const suppliesCount = form.supplies?.length ?? 0;
  const mileageCount = form.mileage?.length ?? 0;
  const otherCount = form.other?.length ?? 0;

  const feesFields = feesFieldsFactory({
    onEdit: (feeForm: ExpenseFormFee) => {
      actions.toggleModal({
        modalType: ExpensePageActionTypeModalType.Fees,
        fee: feeForm,
        onSave: (updatedFee: ExpenseFormFee) => {
          actions.savePage((newState: ExpenseForm) => {
            newState.fees = listUtils.replaceItemById(updatedFee, newState.fees);
          });
        },
      });
    },
    onDelete: (feeForm: ExpenseFormFee) => {
      actions.toggleDeleteModal({
        title: "Delete Fee",
        message: `Are you sure you want to delete expense: "${feeForm.name}"?`,
        onConfirm: () =>
          actions.savePage((form: ExpenseForm) => {
            form.fees = listUtils.removeItem(feeForm, form.fees);
          }),
      });
    },
  });

  const suppliesFields = suppliesFieldsFactory({
    onEdit: (suppliesForm: ExpenseFormSupply) => {
      actions.toggleModal({
        modalType: ExpensePageActionTypeModalType.Supplies,
        supplies: suppliesForm,
        onSave: (updatedSupply: ExpenseFormSupply) => {
          actions.savePage((newState: ExpenseForm) => {
            newState.supplies = listUtils.replaceItemById(updatedSupply, newState.supplies);
          });
        },
      });
    },
    onDelete: (suppliesForm: ExpenseFormSupply) => {
      actions.toggleDeleteModal({
        title: "Delete Supply",
        message: `Are you sure you want to delete expense: "${suppliesForm.name}" with ${suppliesForm.purchases.length} purchases?`,
        onConfirm: () =>
          actions.savePage((form: ExpenseForm) => {
            form.supplies = listUtils.removeItem(suppliesForm, form.supplies);
          }),
      });
    },
  });

  const otherFields = otherFieldsFactory({
    onEdit: (otherForm: ExpenseFormOther) => {
      actions.toggleModal({
        modalType: ExpensePageActionTypeModalType.Other,
        other: otherForm,
        onSave: (updatedOther: ExpenseFormOther) => {
          actions.savePage((newState: ExpenseForm) => {
            newState.other = listUtils.replaceItemById(updatedOther, newState.other);
          });
        },
      });
    },
    onDelete: (otherForm: ExpenseFormOther) => {
      actions.toggleDeleteModal({
        title: "Delete Fee",
        message: `Are you sure you want to delete expense: "${
          otherForm.name
        }" on ${dateUtils.format(otherForm.date)} for ${stringUtils.formatAsCurrency(
          otherForm.amount
        )}?`,
        onConfirm: () =>
          actions.savePage((form: ExpenseForm) => {
            form.other = listUtils.removeItem(otherForm, form.other);
          }),
      });
    },
  });

  const mileageFields = mileageFieldsFactory({
    onEdit: (mileageForm: ExpenseFormMileage) => {
      actions.toggleModal({
        modalType: ExpensePageActionTypeModalType.Mileage,
        mileage: mileageForm,
        onSave: (updatedMileage: ExpenseFormMileage) => {
          actions.savePage((newState: ExpenseForm) => {
            newState.mileage = listUtils.replaceItemById(updatedMileage, newState.mileage);
          });
        },
      });
    },
    onDelete: (mileageForm: ExpenseFormMileage) => {
      actions.toggleDeleteModal({
        title: "Delete Fee",
        message: `Are you sure you want to delete milage: "${
          mileageForm.vehicle
        }" from ${stringUtils.formatAsCommaDelimitedNumber(
          mileageForm.startMileage
        )}mi. to ${stringUtils.formatAsCommaDelimitedNumber(mileageForm.endMileage)}mi.?`,
        onConfirm: () =>
          actions.savePage((form: ExpenseForm) => {
            form.mileage = listUtils.removeItem(mileageForm, form.mileage);
          }),
      });
    },
  });

  let feeFormForModal = undefined;
  let supplyFormForModal = undefined;
  let mileageFormForModal = undefined;
  let otherFormForModal = undefined;

  switch (state.expenseModal?.modalType) {
    case ExpensePageActionTypeModalType.Fees:
      feeFormForModal = state.expenseModal.fee;
      break;
    case ExpensePageActionTypeModalType.Mileage:
      mileageFormForModal = state.expenseModal.mileage;
      break;
    case ExpensePageActionTypeModalType.Supplies:
      supplyFormForModal = state.expenseModal.supplies;
      break;
    case ExpensePageActionTypeModalType.Other:
      otherFormForModal = state.expenseModal.other;
      break;
  }

  return (
    <LayoutContent
      label="Expenses"
      icon="qrcode"
      primaryButton={
        <Button
          label="Save"
          type="primary"
          icon="save"
          onClick={() => {
            actions.submitPage(state);
          }}
        />
      }
      secondaryButton={
        <ButtonWithSubActions
          label="Add"
          icon="plus-circle"
          type="secondary"
          subActions={[
            {
              label: "Fees",
              onClick: () => {
                actions.toggleModal({
                  modalType: ExpensePageActionTypeModalType.Fees,
                  fee: undefined,
                  onSave: (newFee: ExpenseFormFee) => {
                    actions.savePage((newState: ExpenseForm) => {
                      newState.fees.push(newFee);
                    });
                  },
                });
              },
            },
            {
              label: "Supplies",
              onClick: () => {
                actions.toggleModal({
                  modalType: ExpensePageActionTypeModalType.Supplies,
                  supplies: undefined,
                  onSave: (newSupply: ExpenseFormSupply) => {
                    actions.savePage((newState: ExpenseForm) => {
                      newState.supplies.push(newSupply);
                    });
                  },
                });
              },
            },
            {
              label: "Mileage",
              onClick: () => {
                actions.toggleModal({
                  modalType: ExpensePageActionTypeModalType.Mileage,
                  mileage: undefined,
                  onSave: (newMileage: ExpenseFormMileage) => {
                    actions.savePage((newState: ExpenseForm) => {
                      newState.mileage.push(newMileage);
                    });
                  },
                });
              },
            },
            {
              label: "Other",
              onClick: () => {
                actions.toggleModal({
                  modalType: ExpensePageActionTypeModalType.Other,
                  other: undefined,
                  onSave: (newOther: ExpenseFormOther) => {
                    actions.savePage((newState: ExpenseForm) => {
                      newState.other.push(newOther);
                    });
                  },
                });
              },
            },
          ]}
        />
      }
    >
      <h2>
        <Icon name="dollar" /> Fees: ({feesCount})
      </h2>
      <div>
        <Table
          fields={feesFields}
          values={form.fees}
          simpleSort={feesAndSuppliesSort}
          itemsPerPage={itemsPerPage}
        />
      </div>
      <h2>
        <Icon name="shopping-bag" /> Supplies: ({suppliesCount})
      </h2>
      <div>
        <Table
          fields={suppliesFields}
          values={form.supplies}
          simpleSort={feesAndSuppliesSort}
          itemsPerPage={itemsPerPage}
        />
      </div>
      <h2>
        <Icon name="car" /> Mileage: ({mileageCount})
      </h2>
      <div>
        <Table
          fields={mileageFields}
          values={form.mileage}
          simpleSort={mileageAndOtherSort}
          itemsPerPage={itemsPerPage}
        />
      </div>
      <h2>
        <Icon name="calculator" /> Other: ({otherCount})
      </h2>
      <div>
        <Table
          fields={otherFields}
          values={form.other}
          simpleSort={mileageAndOtherSort}
          itemsPerPage={itemsPerPage}
        />
      </div>
      <ExpenseFeeModal
        form={feeFormForModal}
        isVisible={state.expenseModal?.modalType === ExpensePageActionTypeModalType.Fees ?? false}
        onSave={(newFee: ExpenseFormFee) => {
          if (state.expenseModal?.modalType === ExpensePageActionTypeModalType.Fees) {
            state.expenseModal.onSave(newFee);
          }
          actions.toggleModal();
        }}
        onHide={() => actions.toggleModal()}
      />
      <ExpenseSupplyModal
        form={supplyFormForModal}
        isVisible={
          state.expenseModal?.modalType === ExpensePageActionTypeModalType.Supplies ?? false
        }
        onSave={(newSupply: ExpenseFormSupply) => {
          if (state.expenseModal?.modalType === ExpensePageActionTypeModalType.Supplies) {
            state.expenseModal.onSave(newSupply);
          }
          actions.toggleModal();
        }}
        onHide={() => actions.toggleModal()}
      />
      <ExpenseMileageModal
        form={mileageFormForModal}
        isVisible={
          state.expenseModal?.modalType === ExpensePageActionTypeModalType.Mileage ?? false
        }
        onSave={(newMileage: ExpenseFormMileage) => {
          if (state.expenseModal?.modalType === ExpensePageActionTypeModalType.Mileage) {
            state.expenseModal.onSave(newMileage);
          }
          actions.toggleModal();
        }}
        onHide={() => actions.toggleModal()}
      />
      <ExpenseOtherModal
        form={otherFormForModal}
        isVisible={state.expenseModal?.modalType === ExpensePageActionTypeModalType.Other ?? false}
        onSave={(newOther: ExpenseFormOther) => {
          if (state.expenseModal?.modalType === ExpensePageActionTypeModalType.Other) {
            state.expenseModal.onSave(newOther);
          }
          actions.toggleModal();
        }}
        onHide={() => actions.toggleModal()}
      />

      <ConfirmationModal
        isVisible={state.deleteModal.isVisible}
        title={state.deleteModal.title}
        message={state.deleteModal.message}
        onConfirm={() => {
          if (state.deleteModal.onConfirm) {
            state.deleteModal.onConfirm();
          }
        }}
        onHide={() => actions.toggleDeleteModal()}
      />
    </LayoutContent>
  );
};

export default ExpensePage;
