import Button from "components/Button";
import ProgressIndicator from "components/ProgressIndicator";
import { useEffectOnLoad } from "hooks/useEffectOnLoad";
import useTypedParams from "hooks/useTypedParams";
import { Dialog } from "primereact/dialog";
import { FileUpload, FileUploadHandlerEvent } from "primereact/fileupload";
import useInventoryItemStore from "./store/useInventoryItemStore";
import Calendar from "components/Calendar";
import Gallery, { GalleryImage } from "components/Gallery";
import Input, { InputType } from "components/Input";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useContext, useState } from "react";
import CategorySelector, { Category } from "components/CategorySelector";
import { InputTextarea } from "primereact/inputtextarea";

import style from "./index.module.scss";
import Chip, { IHasName } from "components/Chip";
import classNames from "classnames";
import { stringUtils } from "utils/stringUtils";
import moment, { Moment } from "moment";
import Panel from "components/Panel";
import InventoryNote from "./inventoryNote";
import uuid from "utils/uuid";
import LayoutContent from "components/LayoutContent";
import NewDocumentModal from "components/NewDocumentModal";
import { InventoryItemFormDocument } from "api/GeneratedApiClients";
import ButtonWithSubActions, { SubActionProps } from "components/ButtonWithSubActions";
import Table from "components/Table";
import { listingFieldsFactory } from "./tableFields";
import { ConfigContext } from "config/ConfigProvider";

interface InventoryItemProps {
  id: string;
}

const InventoryItemPage = () => {
  const [state, actions] = useInventoryItemStore();
  const [unsavedNote, setUnsavedNote] = useState<string>("");
  const [unsavedEBayUrl, setUnsavedEBayUrl] = useState<string>("");
  const config = useContext(ConfigContext);

  const { id } = useTypedParams<InventoryItemProps>();

  useEffectOnLoad(() => actions.loadPage(id), undefined, [id]);

  if (state.isLoading) {
    return <ProgressIndicator isLoading={state.isLoading} />;
  }

  const form = state?.form;

  if (form === undefined) {
    return <span>Page not found</span>;
  }

  const inventoryItemName: string = stringUtils.isNullOrWhitespace(form.name)
    ? "Unnamed item"
    : (form.name as string);

  const categories = form.categories ?? [];
  const documents = form.documents ?? [];
  const notes = form.notes ?? [];
  const listings = form.listings ?? [];

  const { listingFields, listingSort } = listingFieldsFactory();

  const categoryChips =
    categories.length <= 0 && !state.isEditing ? (
      <i>No categories</i>
    ) : (
      categories
        .filter((c) => !stringUtils.isNullOrWhitespace(c.display))
        .map((c) => {
          const chipValue: IHasName = {
            display: c.display as string,
            isNew: c.isNew,
          };

          return <Chip key={c.id} value={chipValue} />;
        })
    );

  const subHeader = () => {
    return (
      <div className={classNames(style.categories, "mt-2")}>
        <>
          {state.isEditing ? (
            <Button
              icon="pencil"
              label="Category"
              type="secondary"
              subtype="text-only"
              className="mr-2"
              onClick={() => actions.showCategoryEditor()}
            />
          ) : (
            <></>
          )}
          {categoryChips}
        </>
      </div>
    );
  };

  const InventoryNotes = () => {
    const inventoryNotes = notes.map((n) => {
      return (
        <InventoryNote
          key={n.id}
          name={n.authorName ?? "Unknown author"}
          date={moment(n.date ?? new Date()).toDate()}
          dateAsText={n.dateAsGeneratedDynamicDescription ?? "now"}
          noteText={n.note ?? ""}
        />
      );
    });

    return <div>{inventoryNotes}</div>;
  };

  const galleryItems = documents.map((d) => {
    const galleryItem: GalleryImage = {
      id: d.id,
      image: d.imageUrl,
      altText: "The name of this alt text needs to be changed in the future.",
      name: "",
    };

    return galleryItem;
  });

  const itemSellingActionToggle: SubActionProps = form.isSold
    ? {
        label: "Item Returned",
        icon: "truck",
        onClick: () => {
          actions.savePage((form) => {
            form.isSold = false;
            form.saleDate = undefined;
            form.salePrice = undefined;
          });
        },
      }
    : {
        label: "Sold Item!",
        icon: "dollar",
        onClick: () => {
          actions.savePage((form) => {
            form.isSold = true;
            form.saleDate = moment();
            form.salePrice = 0.0;
          });
        },
      };

  return (
    <LayoutContent
      label={inventoryItemName}
      icon="tag"
      subElement={subHeader}
      primaryButton={
        <ButtonWithSubActions
          type="success"
          label="Save"
          icon="save"
          onClick={() => {
            actions.submitPage(state);
          }}
          subActions={[
            itemSellingActionToggle,
            {
              label: "Discard",
              icon: "trash",
              onClick: () => {
                actions.loadPage(id);
              },
            },
          ]}
        />
      }
    >
      <h2>Details</h2>
      <div className="formgrid grid">
        <div className="field col-12 md:col-6 mb-3">
          <Input
            id="name"
            type={InputType.Text}
            label="Name"
            icon="box"
            value={form.name}
            onChange={(newValue: string | undefined) =>
              actions.savePage((form) => (form.name = newValue))
            }
            isDisabled={!state.isEditing}
            autoFocus={form?.id === uuid.empty() ?? false}
          />
        </div>
      </div>
      <div className="formgrid grid">
        <div className="field col-12 mb-3">
          <Input
            type={InputType.Text}
            id="description"
            label="Description"
            icon="book"
            value={form.description ?? ""}
            onChange={(newValue: string | undefined) =>
              actions.savePage((form) => (form.description = newValue))
            }
            isDisabled={!state.isEditing}
          />
        </div>
      </div>
      <div className="formgrid grid">
        <div className="field col-12 md:col-6 mb-3">
          <Input
            type={InputType.Currency}
            id="purchaseAmount"
            label="Purchase Amount"
            value={form.purchasePrice}
            onChange={(newValue: number | undefined) =>
              actions.savePage(
                (form) => (form.purchasePrice = newValue === undefined ? 0 : newValue)
              )
            }
            isDisabled={!state.isEditing}
            selectOnFocus
          />
        </div>
        <div className="field col-12 md:col-6 mb-3">
          <Calendar
            id="purchaseDate"
            label="Purchase Date"
            singleValue={form.purchaseDate}
            maxDate={moment()}
            onChange={(date: Moment | undefined) =>
              actions.savePage((form) => (form.purchaseDate = date ?? moment()))
            }
            iconPosition="left"
          />
        </div>
      </div>
      {form.isSold ? (
        <div className="formgrid grid">
          <div className="field col-12 md:col-6 mb-3">
            <Input
              type={InputType.Currency}
              id="salePrice"
              label="Sale Price"
              value={form.salePrice}
              onChange={(newValue: number | undefined) =>
                actions.savePage((form) => (form.salePrice = newValue))
              }
              isDisabled={!state.isEditing}
              selectOnFocus
            />
          </div>
          <div className="field col-12 md:col-6 mb-3">
            <Calendar
              id="saleDate"
              label="Sale Date"
              singleValue={form.saleDate}
              maxDate={moment()}
              onChange={(date: Moment | undefined) =>
                actions.savePage((form) => (form.saleDate = date))
              }
              iconPosition="left"
              isDisabled={!state.isEditing}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <h2>Documents ({documents.length})</h2>
      <div className={style.galleryControls}>
        <Button
          type="secondary"
          subtype="outlined"
          icon="camera"
          label="Camera"
          aria-label="Add document using camera"
          isDisabled={!state.isEditing}
          onClick={() => {
            actions.showCamera();
          }}
        />
        <Button
          type="secondary"
          subtype="outlined"
          icon="file"
          label="Browse"
          isDisabled={!state.isEditing}
          aria-label="Add document by browsing local computer"
          onClick={() => {
            actions.showFileUploader();
          }}
        />
      </div>
      <div className={style.gallery}>
        <Gallery
          images={galleryItems}
          noItemsDisplay="No documents."
          isDisabled={!state.isEditing}
          onDelete={(id: string) => {
            const documentToDelete = documents.find((i) => i.id === id);

            if (documentToDelete) {
              actions.showDeleteDocumentConfirmation(documentToDelete);
            }
          }}
        />
      </div>
      <h2>Listings ({listings.length})</h2>
      <div className="formgrid grid">
        <div className="field col-8">
          <Input
            type={InputType.Text}
            id="listingUrl"
            label="Link "
            icon="link"
            value={unsavedEBayUrl}
            onChange={(newValue: string | undefined) => setUnsavedEBayUrl(newValue ?? "")}
            helpIndicator={{
              onOpen: () => {
                actions.toggleListingHelper();
              },
              onClose: () => {
                actions.toggleListingHelper();
              },
            }}
          />
        </div>
        <div className="field col-4 flex align-items-end">
          <Button
            type="primary"
            icon="plus-circle"
            label="Add listing"
            onClick={() => {
              const ebayUrl = unsavedEBayUrl.split("?")[0];
              const finalUrl = unsavedEBayUrl.startsWith("https://www.ebay.com/")
                ? ebayUrl
                : unsavedEBayUrl;

              actions.savePage((form) => {
                form.listings.push({
                  id: uuid.empty(),
                  eBayUrl: finalUrl,
                  eBayId: "",
                  eBayLegacyId: 0,
                  title: "Please save to preview details",
                  created: moment(new Date()),
                  lastModified: moment(new Date()),
                });
              });
              setUnsavedEBayUrl("");
            }}
          />
        </div>
      </div>
      {listings.length > 0 ? (
        <div>
          <Table fields={listingFields} values={listings} simpleSort={listingSort} />
        </div>
      ) : (
        <></>
      )}
      <h2>Other</h2>
      <Panel header={`Notes (${notes.length})`} isCollapsed={true}>
        <div className="flex flex-column">
          <InputTextarea
            rows={3}
            cols={30}
            className={style.inputTextArea}
            value={unsavedNote}
            onChange={(e) => {
              setUnsavedNote(e.target.value);
            }}
          />
          <div className="mt-3 mb-2">
            <Button
              type="primary"
              icon="plus-circle"
              label="Add note"
              onClick={() => {
                setUnsavedNote("");
                actions.savePage((form) => {
                  form.notes?.unshift({
                    id: uuid.empty(),
                    authorName: "(me)",
                    note: unsavedNote,
                    date: moment(new Date()),
                    dateAsGeneratedDynamicDescription: "unsaved",
                  });
                });
              }}
              isDisabled={!state.isEditing}
            />
          </div>
          <InventoryNotes />
        </div>
      </Panel>
      <NewDocumentModal
        isVisible={state.isTakingPicture}
        onHide={actions.hideCamera}
        onSave={(newDocument: InventoryItemFormDocument) => {
          actions.savePage((form) => {
            form.documents = form.documents ?? [];
            form.documents.unshift(newDocument);
          });
          actions.hideCamera();
        }}
      />
      <Dialog
        visible={state.isUploadingFile}
        header="Browse Image"
        footer={<></>}
        onHide={() => {
          actions.hideFileUploader();
        }}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
      >
        <div style={{ width: "100%" }}>
          <FileUpload
            name="demo[]"
            multiple={false}
            customUpload={true}
            uploadHandler={(event: FileUploadHandlerEvent) => {
              const { files } = event;

              const fileString = URL.createObjectURL(files[0] as Blob);

              const newDocument: InventoryItemFormDocument = {
                id: uuid.new(),
                imageUrl: fileString,
              };
              actions.savePage((form) => {
                form.documents = form.documents ?? [];
                form.documents.unshift(newDocument);
              });
              actions.hideFileUploader();
            }}
            chooseOptions={{
              label: "Browse",
            }}
            uploadOptions={{
              label: "Use",
              className: "p-button-success",
            }}
            cancelOptions={{
              label: "Clear",
              className: "p-button-warning",
            }}
            accept="image/*"
            maxFileSize={15000000}
            emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>}
          />
        </div>
      </Dialog>
      <ConfirmDialog
        className="resale-minder-confirmDialog"
        visible={state.isConfirmingDeleteDocument}
        header="Delete Document"
        message="Are you sure you want to delete the document?"
        onHide={() => {
          actions.hideDeleteDocumentConfirmation();
        }}
        accept={() => {
          actions.confirmDeleteDocument();
        }}
        reject={() => {
          actions.hideDeleteDocumentConfirmation();
        }}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
      >
        <div style={{ width: "100%" }}>Body</div>
      </ConfirmDialog>
      <CategorySelector
        isVisible={state.isEditingCategories}
        currentCategories={(state.form?.categories ?? []).map((c) => {
          const category: Category = {
            id: c.id ?? uuid.new(),
            display: c.display ?? "",
            isNew: c.isNew,
          };
          return category;
        })}
        allCategories={(state.form?.availableCategories ?? []).map((c) => {
          const category: Category = {
            id: c.id ?? uuid.new(),
            display: c.display ?? "",
            isNew: c.isNew,
          };
          return category;
        })}
        onConfirm={(categories: Category[]) => {
          actions.savePage((state) => (state.categories = categories));
          actions.hideCategoryEditor();
        }}
        onHide={() => actions.hideCategoryEditor()}
      />
      <Dialog
        visible={state.isListingHelperOpen}
        header="How to add eBay listing URL"
        footer={<></>}
        onHide={() => {
          actions.toggleListingHelper(false);
        }}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "95vw" }}
      >
        <div style={{ width: "100%" }}>
          <img
            className="w-full h-full"
            src={`${config.baseUrl}/eBay_get_listing_url.gif`}
            alt="How to add eBay listing URL animated gif"
          />
        </div>
      </Dialog>
    </LayoutContent>
  );
};

export default InventoryItemPage;
