import React, { PropsWithChildren, useEffect, useState } from "react";
import DataService from "../utils/DataServiceUtility";
import ProgressIndicator from "../components/ProgressIndicator";
import Site from "./Site";
import useRmAuth from "auth/useRmAuth";

const DefaultSite: Site = {
  categories: [],
};

export const SiteContext = React.createContext<Site>(DefaultSite);

interface SiteState {
  site: Site | null;
}

const SiteProvider: React.FC<PropsWithChildren> = function (props: PropsWithChildren) {
  const [state, setState] = useState<SiteState>({ site: null });

  const { isLoading } = useRmAuth();

  useEffect(() => {
    const init = async () => {
      // TODO Added Headers to Site API request
      if (!isLoading) {
        const site = await DataService.getSite();
        setState({ site: site });
      }
    };

    init();
  }, [isLoading]);

  if (!state.site || isLoading) {
    return <ProgressIndicator isLoading={true} fullPage />;
  }

  return <SiteContext.Provider value={state.site}>{props.children}</SiteContext.Provider>;
};

export default SiteProvider;
