import { ExpenseForm } from "api/GeneratedApiClients";
import { ExpenseModal } from "./expenseActionMethods";

export enum ExpensePageActionType {
  ShowLoader = 0,
  InitialLoad = 1,
  ShowSuccess = 2,
  ShowError = 3,
  ShowInvalid = 4,
  Save = 5,
  Submit = 6,
  ToggleExpenseModal = "47d5b3fa-f8e6-4304-823d-98138f9c131c",
  ToggleDeleteModal = "77b43143-102b-44a1-aa6e-d48e224653ea",
}

export enum ExpensePageActionTypeModalType {
  Fees = "3ac9cf88-ba29-402c-8b96-213e59e2e422",
  Supplies = "3735a322-87cf-415e-ac45-274454323d5d",
  Mileage = "8839272e-9067-4d5b-bef4-5f95ec6b6db5",
  Other = "5dbe82c0-7790-4689-b419-08ecbf40d04a",
}

interface ShowLoaderAction {
  type: ExpensePageActionType.ShowLoader;
}

interface InitialLoadAction {
  type: ExpensePageActionType.InitialLoad;
  form: ExpenseForm;
}

interface ShowSuccessAction {
  type: ExpensePageActionType.ShowSuccess;
  form: ExpenseForm;
}

interface ShowInvalidAction {
  type: ExpensePageActionType.ShowInvalid;
  form: ExpenseForm;
}

interface ShowErrorAction {
  type: ExpensePageActionType.ShowError;
  error: string;
}
interface SavePageAction {
  type: ExpensePageActionType.Save;
  updater: (form: ExpenseForm) => void;
}
interface SubmitPageAction {
  type: ExpensePageActionType.Submit;
  form: ExpenseForm;
}

interface ToggleModalAction {
  type: ExpensePageActionType.ToggleExpenseModal;
  options?: ExpenseModal;
}

interface ToggleDeleteModalAction {
  type: ExpensePageActionType.ToggleDeleteModal;
  isVisible: boolean;
  title?: string;
  message?: string;
  onConfirm?: () => void;
}

export type ExpensePageAction =
  | ShowLoaderAction
  | InitialLoadAction
  | ShowSuccessAction
  | ShowInvalidAction
  | ShowErrorAction
  | SavePageAction
  | SubmitPageAction
  | ToggleModalAction
  | ToggleDeleteModalAction;
