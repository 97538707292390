import classNames from "classnames";
import { Button as PrimeReactButton } from "primereact/button";
import { PrimeIcons } from "utils/PrimeReact/PrimeIcons";
import { PropsWithClassName } from "utils/PropsWithClassName";

import style from "./index.module.scss";

export type ButtonType = "primary" | "secondary" | "success" | "warning" | undefined;
export type ButtonSubtype = "fill" | "outlined" | "text-only" | undefined;

export interface ButtonProps {
  label?: string;
  icon?: PrimeIcons;
  isLowLight?: true;
  isRounded?: true;
  type?: ButtonType;
  subtype?: ButtonSubtype;
  isVisible?: boolean | undefined;
  isDisabled?: boolean | undefined;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Button = (props: PropsWithClassName<ButtonProps>) => {
  const { label, icon, isRounded, type, subtype, onClick, isDisabled, isVisible, className } =
    props;

  const hidden = isVisible === false && isVisible !== undefined;

  const roundedClass = isRounded ? "p-button-rounded" : undefined;
  const typeClass =
    type === "primary"
      ? style.primaryButton
      : type === "secondary"
      ? "p-button-secondary"
      : type === "success"
      ? "p-button-success"
      : type === "warning"
      ? "p-button-danger"
      : undefined;

  const subtypeClass =
    subtype === "outlined"
      ? "p-button-outlined"
      : subtype === "text-only"
      ? "p-button-text"
      : undefined;

  const isHidden = hidden === true ? "none" : undefined;

  const buttonIcon = icon !== undefined ? "pi pi-" + icon : undefined;

  return (
    <PrimeReactButton
      label={label}
      icon={buttonIcon}
      onClick={(e) => {
        if (e && onClick) {
          onClick(e);
        }
      }}
      className={classNames(
        "resale-minder-button",
        className,
        roundedClass,
        typeClass,
        subtypeClass
      )}
      hidden={hidden}
      style={{ display: isHidden }}
      disabled={isDisabled}
    />
  );
};

export default Button;
