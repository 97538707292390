import { HealthCheckForm } from "api/GeneratedApiClients";

export enum StatusIconPageActionType {
  ShowLoader = 0,
  InitialLoad = 1,
  ShowSuccess = 2,
  ShowError = 3,
  ShowInvalid = 4,
}

interface ShowLoaderAction {
  type: StatusIconPageActionType.ShowLoader;
}

interface InitialLoadAction {
  type: StatusIconPageActionType.InitialLoad;
  form: HealthCheckForm;
  isNew: boolean;
}

interface ShowSuccessAction {
  type: StatusIconPageActionType.ShowSuccess;
  form: HealthCheckForm;
}

interface ShowInvalidAction {
  type: StatusIconPageActionType.ShowInvalid;
  form: HealthCheckForm;
}

interface ShowErrorAction {
  type: StatusIconPageActionType.ShowError;
  error: string;
}

export type StatusIconPageAction =
  | ShowLoaderAction
  | InitialLoadAction
  | ShowSuccessAction
  | ShowInvalidAction
  | ShowErrorAction;
