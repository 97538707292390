import { PrimeIcons } from "utils/PrimeReact/PrimeIcons";

export interface IHasName {
  display: string;
  isNew?: boolean;
}

export interface ChipProps {
  key: string | undefined;
  value: IHasName;
  icon?: PrimeIcons;
  removable?: boolean;
  removeIcon?: PrimeIcons;
  newChipSuffix?: string;
  onRemove?(removedItem: IHasName): void;
}

const Chip = function (props: ChipProps) {
  const { key, value, icon, removable, removeIcon, newChipSuffix, onRemove } = props;

  const { isNew } = value;

  const iconComponent = () => {
    if (!icon) {
      return <></>;
    }

    return <span className={`p-chip-icon pi pi-${icon}`}></span>;
  };

  const removeComponent = () => {
    if (!removable || !onRemove) {
      return <></>;
    }

    const customRemoveIcon = removeIcon ?? "times-circle";

    return (
      <span
        key={key}
        tabIndex={0}
        className={`p-chip-remove-icon pi pi-${customRemoveIcon}`}
        onClick={() => {
          onRemove && onRemove(value);
        }}
      ></span>
    );
  };

  const label = isNew ? `${value.display}${newChipSuffix ?? "**"}` : value.display;

  return (
    <div key={key} className="p-chip p-component mr-2">
      {iconComponent()}
      <span className="p-chip-text">{label}</span>
      {removeComponent()}
    </div>
  );
};
export default Chip;
