// https://www.primefaces.org/primeblocks-react/#/application/dashboard
// See "Sidebar"

import Icon from "components/Icon";
import Ripple from "components/Ripple";
import { PrimeIcons } from "utils/PrimeReact/PrimeIcons";
import style from "./index.module.scss";
import { Route, Routes } from "../../../MainRoutes";
import { NavLink, useLocation } from "react-router-dom";

export interface SidebarNavItemProps {
  icon: PrimeIcons;
  label: string;
  link?: Route | undefined;
  isVisible?: boolean | undefined;
  onClick?: () => void;
  onNavigationComplete?: () => void;
}

const SidebarNavItem = (props: SidebarNavItemProps) => {
  const { icon, label, link, isVisible, onClick, onNavigationComplete } = props;

  const location = useLocation();

  if (isVisible === false) {
    return <></>;
  }

  const toLink = link !== undefined ? link : Routes.home;

  if (onClick !== undefined) {
    return (
      <NavLink
        to={location.pathname}
        onClick={onClick}
        onMouseUp={onNavigationComplete}
        className={style.sidebarLink}
      >
        <Ripple className={style.sidebarNavItem}>
          <Icon name={icon} />
          <span>{label}</span>
        </Ripple>
      </NavLink>
    );
  }

  return (
    <NavLink to={toLink} className={style.sidebarLink} onMouseUp={onNavigationComplete}>
      <Ripple className={style.sidebarNavItem}>
        <Icon name={icon} />
        <span>{label}</span>
      </Ripple>
    </NavLink>
  );
};

export default SidebarNavItem;
