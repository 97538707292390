/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProfileForm } from '../models/ProfileForm';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProfileService {

    /**
     * @returns ProfileForm Success
     * @throws ApiError
     */
    public static getProfile(): CancelablePromise<ProfileForm> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Profile',
        });
    }

    /**
     * @returns ProfileForm Success
     * @throws ApiError
     */
    public static postProfile({
requestBody,
}: {
requestBody?: ProfileForm,
}): CancelablePromise<ProfileForm> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Profile',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
