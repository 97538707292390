import { GainLossFormYearItem, GainLossType } from "api/GeneratedApiClients";
import Icon from "components/Icon";
import { tableFieldsFactory } from "components/Table";
import { routeFill } from "MainRoutes";
import moment, { Moment } from "moment";
import { Link } from "react-router-dom";

import style from "./index.module.scss";
import { stringUtils } from "utils/stringUtils";

export const gainLossTableFieldsFactory = () => {
  const fields = tableFieldsFactory<GainLossFormYearItem>({
    name: {
      label: "Name",
      body: (name: string, i: GainLossFormYearItem) => {
        return <Link to={routeFill.item(i.id ?? "unknown")}>{name}</Link>;
      },
      sortable: true,
      width: "050",
    },
    gainLossAmount: {
      label: "Gain/Loss",
      body: (gainLossAmount: number, form: GainLossFormYearItem) => {
        const gainLossType = form.gainLossType;

        const icon =
          gainLossType === GainLossType.Decrease ? (
            <Icon name="arrow-down" className={style.negativeGainLoss} />
          ) : gainLossType === GainLossType.Increase ? (
            <Icon name="arrow-up" className={style.positiveGainLoss} />
          ) : (
            <Icon name="minus" className={style.neutralGainLoss} />
          );

        return (
          <>
            {icon}
            <span>{stringUtils.formatAsCurrency(gainLossAmount)}</span>
          </>
        );
      },
      sortable: true,
      width: "025",
    },
    saleDate: {
      label: "Sold Date",
      body: (saleDate: Moment) => {
        return moment(saleDate).format("M/D/yyyy");
      },
      width: "025",
      sortable: true,
      isDate: true,
    },
  });

  return fields;
};
