import moment from "moment";
import { Moment } from "moment";

type DateFormats = "M/D/yyyy";

const dateUtils = {
  format: (date: Moment | undefined, format?: DateFormats | undefined): string => {
    if (date === undefined) {
      return "";
    }

    return moment(date).format(format || "M/D/yyyy");
  },
};

export default dateUtils;
