import {
  ExpenseClient,
  ExpenseForm,
  ExpenseFormFee,
  ExpenseFormMileage,
  ExpenseFormOther,
  ExpenseFormSupply,
} from "api/GeneratedApiClients";
import toastUtils from "utils/toastUtils";
import { ActionMethodParameters } from "../../../hooks/useStore";
import {
  ExpensePageAction,
  ExpensePageActionType,
  ExpensePageActionTypeModalType,
} from "./expenseActions";
import { PageState } from "./useExpenseStore";

export interface DeleteModal {
  title?: string;
  message?: string;
  onConfirm?: () => void;
}

export type ExpenseModal =
  | {
      modalType: ExpensePageActionTypeModalType.Fees;
      fee: ExpenseFormFee | undefined;
      onSave: (fee: ExpenseFormFee) => void;
    }
  | {
      modalType: ExpensePageActionTypeModalType.Mileage;
      mileage: ExpenseFormMileage | undefined;
      onSave: (mileage: ExpenseFormMileage) => void;
    }
  | {
      modalType: ExpensePageActionTypeModalType.Supplies;
      supplies: ExpenseFormSupply | undefined;
      onSave: (supplies: ExpenseFormSupply) => void;
    }
  | {
      modalType: ExpensePageActionTypeModalType.Other;
      other: ExpenseFormOther | undefined;
      onSave: (other: ExpenseFormOther) => void;
    };

export interface InventoryActionMethods {
  loadPage: () => void;
  unloadPage: () => void;
  savePage: (updater: (form: ExpenseForm) => void) => void;
  submitPage: (page: PageState) => void;
  toggleModal: (options?: ExpenseModal) => void;
  toggleDeleteModal: (options?: DeleteModal) => void;
}

const defaultExpenseForm = (): ExpenseForm => {
  return {
    fees: [],
    mileage: [],
    other: [],
    supplies: [],
  };
};

const getReducerActions = function (params: ActionMethodParameters<ExpensePageAction>) {
  const dispatch = params.dispatch;
  return {
    showLoader: () => dispatch({ type: ExpensePageActionType.ShowLoader }),
    initialLoad: (form: ExpenseForm) => {
      dispatch({
        type: ExpensePageActionType.InitialLoad,
        form: form,
      });
    },
    showInvalid: (form: ExpenseForm) => {
      toastUtils.showInvalidMessage();
      dispatch({
        type: ExpensePageActionType.ShowInvalid,
        form: form,
      });
    },
    showSuccess: (form: ExpenseForm) => {
      toastUtils.showSaveSuccessMessage();
      dispatch({
        type: ExpensePageActionType.ShowSuccess,
        form: form,
      });
    },
    showError: (error: string) => {
      toastUtils.showErrorMessage();
      dispatch({
        type: ExpensePageActionType.ShowError,
        error: error,
      });
    },
    savePageAction: (updater: (form: ExpenseForm) => void) => {
      dispatch({
        type: ExpensePageActionType.Save,
        updater: updater,
      });
    },
    submitPageAction: (form: ExpenseForm) => {
      dispatch({
        type: ExpensePageActionType.Submit,
        form: form,
      });
    },
    toggleModalAction: (options?: ExpenseModal) => {
      dispatch({
        type: ExpensePageActionType.ToggleExpenseModal,
        options: options,
      });
    },
    toggleDeleteModalAction: (
      isVisible: boolean,
      title?: string,
      message?: string,
      onConfirm?: () => void
    ) => {
      dispatch({
        type: ExpensePageActionType.ToggleDeleteModal,
        isVisible: isVisible,
        title: title,
        message: message,
        onConfirm: onConfirm,
      });
    },
  };
};

export const getInventoryPageActions = (
  params: ActionMethodParameters<ExpensePageAction>
): InventoryActionMethods => {
  const { apiErrorHandler, dataClientFactory } = params;
  const reducerActions = getReducerActions(params);

  if (dataClientFactory === undefined) {
    throw new Error("Unable to retrieve Gain Loss Page without a DataClientFactory.");
  }

  const {
    showLoader,
    showError,
    initialLoad,
    showInvalid,
    savePageAction,
    submitPageAction,
    toggleModalAction,
    toggleDeleteModalAction,
  } = reducerActions;

  const defaultErrorHandlingOptions = (error: unknown, invalidObject: ExpenseForm) => {
    apiErrorHandler.handle({
      error: error,
      errorAction: () => reducerActions.showError("Unable to load Gain Loss Page."),
      invalidAction: () => reducerActions.showInvalid(invalidObject),
    });
  };

  const unloadPage = () => undefined;

  const loadPage = async () => {
    showLoader();

    try {
      const expenseClient = dataClientFactory.getClient(ExpenseClient);
      const expensees = await expenseClient.get();

      if (expensees) {
        initialLoad(expensees);
      } else {
        showError("Unable to load Gain Loss Page.");
      }
    } catch (error: unknown) {
      defaultErrorHandlingOptions(error, defaultExpenseForm());
    }
  };

  const savePage = (updater: (form: ExpenseForm) => void) => savePageAction(updater);
  const submitPage = async (page: PageState) => {
    showLoader();

    const { form } = page;

    try {
      const expenseClient = dataClientFactory.getClient(ExpenseClient);
      const expenseForm = await expenseClient.save(form);

      if (expenseForm) {
        submitPageAction(expenseForm);
        return;
      }

      showError("Unable to save Expense Form");
    } catch (error: unknown) {
      apiErrorHandler.handle({
        error: error,
        errorAction: () => showError("Unable to save Expense Form"),
        invalidAction: () => showInvalid(form ?? defaultExpenseForm()),
      });
    }
  };

  const toggleModal = (options?: ExpenseModal) => toggleModalAction(options);

  const toggleDeleteModal = (options?: DeleteModal) =>
    toggleDeleteModalAction(
      options !== undefined,
      options?.title,
      options?.message,
      options?.onConfirm
    );

  return {
    loadPage,
    unloadPage,
    savePage,
    submitPage,
    toggleModal,
    toggleDeleteModal,
  };
};
