import { SettingsForm, SettingsService } from "api";
import { ActionMethodParameters } from "hooks/useStore";
import toastUtils from "utils/toastUtils";
import { SettingsPageAction, SettingsPageActionType } from "./settingsPageStoreActions";
const getReducerActions = function (params: ActionMethodParameters<SettingsPageAction>) {
  const dispatch = params.dispatch;
  return {
    showLoader: () => dispatch({ type: SettingsPageActionType.ShowLoader }),
    initialLoad: (form: SettingsForm) =>
      dispatch({
        type: SettingsPageActionType.InitialLoad,
        form: form,
      }),
    showInvalid: (form: SettingsForm) => {
      toastUtils.showInvalidMessage();
      dispatch({
        type: SettingsPageActionType.ShowInvalid,
        form: form,
      });
    },
    showSuccess: (form: SettingsForm) => {
      toastUtils.showSaveSuccessMessage();
      dispatch({
        type: SettingsPageActionType.ShowSuccess,
        form: form,
      });
    },
    showError: (error: string) => {
      toastUtils.showErrorMessage();
      dispatch({
        type: SettingsPageActionType.ShowError,
        error: error,
      });
    },
    editPageAction: () => {
      dispatch({
        type: SettingsPageActionType.ChangePageViews,
        isEditing: true,
      });
    },
    savePageAction: (updater: (form: SettingsForm) => void) => {
      dispatch({
        type: SettingsPageActionType.Save,
        updater: updater,
      });
    },
    submitPageAction: (form: SettingsForm) => {
      toastUtils.showSaveSuccessMessage();
      dispatch({
        type: SettingsPageActionType.Submit,
        form: form,
      });
    },
  };
};

export const getSettingsPageActions = function (
  params: ActionMethodParameters<SettingsPageAction>
) {
  const { apiErrorHandler, identity } = params;
  const reducerActions = getReducerActions(params);

  const { showLoader, initialLoad, savePageAction, editPageAction, submitPageAction } =
    reducerActions;

  const defaultErrorHandlingOptions = (error: unknown, invalidObject: SettingsForm) => {
    apiErrorHandler.handle({
      error: error,
      errorAction: () => reducerActions.showError("Unable to load Settings Page."),
      invalidAction: () => reducerActions.showInvalid(invalidObject),
    });
  };

  const loadPage = async (userId: string | undefined) => {
    if (userId === undefined) {
      return;
    }

    showLoader();

    try {
      await identity.initializeAuthorization();
      const settings = await SettingsService.getSettings();
      initialLoad(settings);
    } catch (error: unknown) {
      defaultErrorHandlingOptions(error, {});
    }
  };

  const unloadPage = () => undefined;

  const savePage = (updater: (form: SettingsForm) => void) => savePageAction(updater);
  const editPage = () => editPageAction();
  const submitPage = async (form: SettingsForm) => {
    showLoader();

    try {
      const settings = await SettingsService.postSettings({ requestBody: form });
      submitPageAction(settings);
    } catch (error: unknown) {
      defaultErrorHandlingOptions(error, form);
    }
  };

  return {
    loadPage,
    unloadPage,
    editPage,
    savePage,
    submitPage,
  };
};
