import Button from "components/Button";
import ProgressIndicator from "components/ProgressIndicator";
import { useEffectOnLoad } from "hooks/useEffectOnLoad";
import { useContext, useState } from "react";
import { SiteContext } from "site/SiteProvider";
import useSettingsPageStore from "./store/useSettingsPageStore";

import Input, { InputType } from "components/Input";
import { PickList } from "components/PickList";
import uuid from "utils/uuid";
import LayoutContent from "components/LayoutContent";
import { SettingsFormCategory } from "api/models/SettingsFormCategory";

const SettingsPage = () => {
  const [newCategory, setNewCategory] = useState<string>("");
  const [state, actions] = useSettingsPageStore();
  const site = useContext(SiteContext);

  useEffectOnLoad(() => actions.loadPage(site.userId), actions.unloadPage);

  if (state.isLoading) {
    return (
      <div>
        <ProgressIndicator isLoading={true} />
      </div>
    );
  }

  const form = state.page.form;
  const disabledCategories = form.disabledCategories ?? [];
  const activeCategories = form.activeCategories ?? [];

  return (
    <>
      <LayoutContent
        label="Settings"
        icon="cog"
        primaryButton={
          <Button
            type="success"
            label="Save"
            icon="save"
            onClick={() => {
              actions.submitPage(form);
            }}
          />
        }
        secondaryButton={
          <Button
            type="warning"
            subtype="outlined"
            label="Discard"
            icon="trash"
            onClick={() => {
              actions.loadPage(site.userId);
            }}
          />
        }
      >
        <h2>Categories</h2>
        <div className="formgrid grid mb-4">
          <div className="field col-12 lg:col-9 xl:col-6">
            <Input
              id="category"
              type={InputType.Text}
              label="New"
              icon="box"
              value={newCategory}
              onChange={(newCategory: string | null | undefined) => {
                if (newCategory) {
                  setNewCategory(newCategory);
                }
              }}
            />
          </div>
          <div className="field col-12 flex align-items-end">
            <Button
              label="Disabled"
              icon="plus"
              type="primary"
              onClick={() => {
                actions.savePage((form) => {
                  const newDisabledCategories = form.disabledCategories ?? [];
                  newDisabledCategories.unshift({
                    key: uuid.new(),
                    display: newCategory,
                  });

                  form.disabledCategories = newDisabledCategories;
                });
                setNewCategory("");
              }}
            />
            <Button
              label="Active"
              icon="plus"
              type="secondary"
              subtype="outlined"
              className="ml-3"
              onClick={() => {
                actions.savePage((form) => {
                  const newActiveCategories = form.activeCategories ?? [];
                  newActiveCategories.unshift({
                    key: uuid.new(),
                    display: newCategory,
                  });

                  form.activeCategories = newActiveCategories;
                });
                setNewCategory("");
              }}
            />
          </div>
        </div>
        <div className="formgrid grid">
          <div className="field col-12 lg:col-9 xl:col-6 mb-3">
            <PickList
              leftHeaderLabel="Disabled"
              rightHeaderLabel="Active"
              leftData={disabledCategories}
              rightData={activeCategories}
              isDisabled={false}
              onChange={(
                newDisabledCategories: SettingsFormCategory[],
                newActiveCategories: SettingsFormCategory[]
              ) => {
                actions.savePage((form) => {
                  form.disabledCategories = newDisabledCategories;
                  form.activeCategories = newActiveCategories;
                });
              }}
            />
          </div>
        </div>
      </LayoutContent>
    </>
  );
};

export default SettingsPage;
