import { PrimeIcons } from "utils/PrimeReact/PrimeIcons";
import style from "./index.module.scss";
import Icon from "components/Icon";
import uuid from "utils/uuid";
import { Link } from "react-router-dom";
import { Routes } from "MainRoutes";

export interface IconSummaryIcon {
  tooltip: string;
  iconName: PrimeIcons;
}

export interface IconSummaryProps {
  label: string;
  icons: IconSummaryIcon[];
}

const IconSummary = (props: IconSummaryProps) => {
  const { label, icons } = props;

  return (
    <>
      <label className={style.label}>{label}</label>
      <div className={style.icons}>
        <Link to={Routes.expenses}>
          {icons.map((i) => (
            <Icon
              key={uuid.new()}
              name={i.iconName}
              size="large"
              tooltipOptions={{
                tooltip: i.tooltip,
                position: "right",
                mouseTrack: true,
                mouseTrackTop: 15,
                mouseTrackLeft: 15,
              }}
              disableBoop
            />
          ))}
        </Link>
      </div>
    </>
  );
};

export default IconSummary;
