import {
  ExpenseFormFee,
  ExpenseFormFeeAmount,
  ExpenseFormMileage,
  ExpenseFormOther,
  ExpenseFormSupply,
  ExpenseFormSupplyPurchase,
} from "api/GeneratedApiClients";
import { tableFieldsFactory } from "components/Table";
import moment, { Moment } from "moment";

import style from "./index.module.scss";
import Icon from "components/Icon";
import { stringUtils } from "utils/stringUtils";
import dateUtils from "utils/dateUtils";

interface FieldsFactoryActions<T> {
  onEdit: (form: T) => void;
  onDelete: (form: T) => void;
}

export const feesFieldsFactory = (options: FieldsFactoryActions<ExpenseFormFee>) => {
  const { onEdit, onDelete } = options;

  const fields = tableFieldsFactory<ExpenseFormFee>({
    name: {
      label: "Name",
      sortable: true,
      body: (name: string, feeForm: ExpenseFormFee) => {
        return (
          <button type="button" className={style.linkButton} onClick={() => onEdit(feeForm)}>
            {name}
          </button>
        );
      },
      width: "020",
    },
    description: {
      label: "Description",
      sortable: true,
      width: "040",
    },
    amounts: {
      label: "Count",
      body: (amounts: ExpenseFormFeeAmount[]) => {
        return amounts.length;
      },
      sortable: true,
      width: "020",
    },
    lastUpdated: {
      label: "Updated",
      body: (lastUpdated: Moment) => {
        return moment(lastUpdated).format("M/D/yyyy");
      },
      sortable: true,
      width: "020",
      isDate: true,
    },
    id: {
      label: "",
      body: (id: string, form: ExpenseFormFee) => {
        return (
          <div className="flex flex-direction-row">
            <Icon name="pencil" onClick={() => onEdit(form)} className="mr-3" />
            <Icon name="trash" onClick={() => onDelete(form)} className={style.delete} />
          </div>
        );
      },
      width: "010",
    },
  });

  return fields;
};

export const suppliesFieldsFactory = (options: FieldsFactoryActions<ExpenseFormSupply>) => {
  const { onEdit, onDelete } = options;

  const fields = tableFieldsFactory<ExpenseFormSupply>({
    name: {
      label: "Name",
      sortable: true,
      body: (name: string, supplyForm: ExpenseFormSupply) => {
        return (
          <button type="button" className={style.linkButton} onClick={() => onEdit(supplyForm)}>
            {name}
          </button>
        );
      },
      width: "020",
    },
    description: {
      label: "Description",
      sortable: true,
      width: "040",
    },
    purchases: {
      label: "Count",
      body: (purchases: ExpenseFormSupplyPurchase[]) => {
        return purchases.length;
      },
      sortable: true,
      width: "020",
    },
    lastUpdated: {
      label: "Updated",
      body: (lastUpdated: Moment) => {
        return moment(lastUpdated).format("M/D/yyyy");
      },
      sortable: true,
      width: "020",
      isDate: true,
    },
    id: {
      label: "",
      body: (id: string, form: ExpenseFormSupply) => {
        return (
          <div className="flex flex-direction-row">
            <Icon name="pencil" onClick={() => onEdit(form)} className="mr-3" />
            <Icon name="trash" onClick={() => onDelete(form)} className={style.delete} />
          </div>
        );
      },
      width: "010",
    },
  });

  return fields;
};

export const mileageFieldsFactory = (options: FieldsFactoryActions<ExpenseFormMileage>) => {
  const { onEdit, onDelete } = options;

  const fields = tableFieldsFactory<ExpenseFormMileage>({
    vehicle: {
      label: "Vehicle",
      sortable: true,
      body: (name: string, mileageForm: ExpenseFormMileage) => {
        return (
          <button type="button" className={style.linkButton} onClick={() => onEdit(mileageForm)}>
            {name}
          </button>
        );
      },
      width: "020",
    },
    tripReason: {
      label: "Trip Reason",
      body: (tripReason) => {
        return tripReason;
      },
      sortable: true,
      width: "040",
    },
    totalMileage: {
      label: "Trip Miles",
      body: (totalMileage: number) => {
        return stringUtils.formatAsCommaDelimitedNumber(totalMileage);
      },
      sortable: true,
      width: "020",
    },
    date: {
      label: "Date",
      body: (date: Moment | undefined) => {
        if (date === undefined || date === null) {
          return undefined;
        }

        return dateUtils.format(date);
      },
      sortable: true,
      width: "020",
      isDate: true,
    },
    id: {
      label: "",
      body: (id: string, form: ExpenseFormMileage) => {
        return (
          <div className="flex flex-direction-row">
            <Icon name="pencil" onClick={() => onEdit(form)} className="mr-3" />
            <Icon name="trash" onClick={() => onDelete(form)} className={style.delete} />
          </div>
        );
      },
      width: "010",
    },
  });

  return fields;
};

export const otherFieldsFactory = (options: FieldsFactoryActions<ExpenseFormOther>) => {
  const { onEdit, onDelete } = options;

  const fields = tableFieldsFactory<ExpenseFormOther>({
    name: {
      label: "Name",
      sortable: true,
      body: (name: string, otherForm: ExpenseFormOther) => {
        return (
          <button type="button" className={style.linkButton} onClick={() => onEdit(otherForm)}>
            {name}
          </button>
        );
      },
      width: "020",
    },
    description: {
      label: "Description",
      sortable: true,
      width: "040",
    },
    amount: {
      label: "Amount",
      body: (amount: number) => {
        return stringUtils.formatAsCurrency(amount);
      },
      sortable: true,
      width: "020",
    },
    date: {
      label: "Date",
      body: (date: Moment | undefined) => {
        if (date === undefined || date === null) {
          return undefined;
        }
        return dateUtils.format(date);
      },
      sortable: true,
      width: "020",
      isDate: true,
    },
    id: {
      label: "",
      body: (id: string, form: ExpenseFormOther) => {
        return (
          <div className="flex flex-direction-row">
            <Icon name="pencil" onClick={() => onEdit(form)} className="mr-3" />
            <Icon name="trash" onClick={() => onDelete(form)} className={style.delete} />
          </div>
        );
      },
      width: "010",
    },
  });
  return fields;
};
