import Button from "components/Button";
import Icon from "components/Icon";
import { Dialog } from "primereact/dialog";

export interface ConfirmationModalProps {
  isVisible: boolean;
  title?: string;
  message?: string;
  onConfirm: () => void;
  onHide: () => void;
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { isVisible, title, message, onConfirm, onHide } = props;

  const confirmationMessage = message !== undefined ? message : "Are you sure you want to proceed?";
  const confirmationHeader = title !== undefined ? title : "Confirmation";

  return (
    <Dialog
      visible={isVisible}
      onHide={() => onHide()}
      header={confirmationHeader}
      footer={
        <div className="flex justify-content-end">
          <Button
            label="Yes"
            type="primary"
            onClick={() => {
              onConfirm();
              onHide();
            }}
          />
          <Button label="No" type="secondary" onClick={() => onHide()} />
        </div>
      }
    >
      <div className="flex align-items-center">
        <Icon size="xxlarge" name="exclamation-triangle" className="mr-3" />
        <span>{confirmationMessage}</span>
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
