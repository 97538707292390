import { ExpenseFormMileage } from "api/GeneratedApiClients";
import Input, { InputType } from "components/Input";
import moment, { Moment } from "moment";
import Button from "components/Button";
import uuid from "utils/uuid";
import { useState } from "react";
import objectUtils from "utils/objectUtils";
import { Dialog } from "primereact/dialog";
import Icon from "components/Icon";
import { useEffectOnLoad } from "hooks/useEffectOnLoad";
import Calendar from "components/Calendar";
import dateUtils from "utils/dateUtils";
import { stringUtils } from "utils/stringUtils";

interface ExpenseMileageModalProps {
  form: ExpenseFormMileage | undefined;
  isVisible: boolean;
  onSave: (form: ExpenseFormMileage) => void;
  onHide: () => void;
}

type ExpenseFormMileagePartial = Partial<ExpenseFormMileage>;

const ExpenseMileageModal = (props: ExpenseMileageModalProps) => {
  const { form: nullableForm, isVisible, onSave, onHide } = props;

  const newForm: ExpenseFormMileagePartial = {
    id: uuid.new(),
    lastUpdated: moment(),
  };

  const title = nullableForm === undefined ? "Add Mileage" : "Edit Mileage";

  const [state, setState] = useState<ExpenseFormMileagePartial>(newForm);

  const setNewState = (updater: (newState: ExpenseFormMileagePartial) => void) => {
    const newState = objectUtils.deepCopyObject(state);
    updater(newState);
    setState(newState);
  };

  useEffectOnLoad(
    () => {
      setNewState((newState: ExpenseFormMileagePartial) => {
        newState.id = nullableForm?.id ?? uuid.new();
        newState.lastUpdated = nullableForm?.lastUpdated ?? moment();
        newState.vehicle = nullableForm?.vehicle ?? "";
        newState.tripReason = nullableForm?.tripReason ?? "";
        newState.startMileage = nullableForm?.startMileage;
        newState.endMileage = nullableForm?.endMileage;
        newState.totalMileage = nullableForm?.totalMileage;
        newState.date = nullableForm?.date;
      });
    },
    undefined,
    [nullableForm]
  );

  return (
    <Dialog
      visible={isVisible}
      header={
        <>
          <span className="pr-2">{title}</span>
          <Icon name="exclamation-circle" />
        </>
      }
      footer={
        <div className="flex align-items-center justify-content-between">
          <i>Last Update: {dateUtils.format(state.lastUpdated)}</i>
          <Button
            label="Save"
            type="primary"
            icon="plus-circle"
            onClick={() => {
              if (!state.id || !state.lastUpdated || stringUtils.isNullOrWhitespace(state.id)) {
                return;
              }

              const newForm: ExpenseFormMileage = {
                id: state.id,
                lastUpdated: state.lastUpdated,
                vehicle: state.vehicle ?? "",
                tripReason: state.tripReason ?? "",
                startMileage: state.startMileage ?? 0,
                endMileage: state.endMileage ?? 0,
                totalMileage: state.totalMileage ?? 0,
                date: state.date ?? moment(),
              };

              onSave(newForm);
            }}
          />
        </div>
      }
      closable
      closeOnEscape
      draggable
      style={{ width: "60%" }}
      onHide={() => onHide()}
    >
      <div className="formgrid grid">
        <div className="field col-4">
          <Input
            id="vehicle"
            type={InputType.Text}
            label="Vehicle"
            icon="car"
            value={state.vehicle}
            onChange={(newValue: string | undefined) => {
              setNewState((newState: ExpenseFormMileagePartial) => (newState.vehicle = newValue));
            }}
          />
        </div>
        <div className="field col-4">
          <Calendar
            id="date"
            label="Date"
            iconPosition="left"
            singleValue={state.date}
            maxDate={moment()}
            onChange={(newValue: Moment | undefined) => {
              setNewState((updater) => (updater.date = newValue ?? moment()));
            }}
          />
        </div>
      </div>
      <div className="formgrid grid">
        <div className="field col-12">
          <Input
            id="tripReason"
            type={InputType.Text}
            label="Trip Reason"
            icon="question"
            value={state.tripReason}
            onChange={(newValue: string | undefined) => {
              setNewState(
                (newState: ExpenseFormMileagePartial) => (newState.tripReason = newValue)
              );
            }}
          />
        </div>
      </div>
      <div className="formgrid grid">
        <div className="field col-4">
          <Input
            id="startMileage"
            type={InputType.Number}
            label="Start Mileage"
            decimalPlaces={0}
            value={state.startMileage}
            onChange={(newValue: number | undefined) => {
              setNewState((newState: ExpenseFormMileagePartial) => {
                newState.startMileage = newValue;

                if (newState.endMileage !== undefined && newValue !== undefined) {
                  newState.totalMileage = newState.endMileage - newValue;
                } else {
                  newState.totalMileage = undefined;
                }
              });
            }}
            selectOnFocus
          />
        </div>
        <div className="field col-4">
          <Input
            id="endMileage"
            type={InputType.Number}
            label="End Mileage"
            decimalPlaces={0}
            value={state.endMileage}
            onChange={(newValue: number | undefined) => {
              setNewState((newState: ExpenseFormMileagePartial) => {
                newState.endMileage = newValue;

                if (newState.startMileage !== undefined && newValue !== undefined) {
                  newState.totalMileage = newValue - newState.startMileage;
                } else {
                  newState.totalMileage = undefined;
                }
              });
            }}
            selectOnFocus
          />
        </div>
        <div className="field col-4">
          <Input
            id="totalMileage"
            type={InputType.Number}
            label="Total Mileage"
            decimalPlaces={0}
            value={state.totalMileage}
            onChange={() => {
              console.log("Direct update not allowed to total mileage");
            }}
            isDisabled
          />
          <i>Calculated from start and end</i>
        </div>
      </div>
    </Dialog>
  );
};

export default ExpenseMileageModal;
