import Button from "components/Button";
import Chip from "components/Chip";
import Icon from "components/Icon";
import { Routes } from "MainRoutes";
import { Dialog } from "primereact/dialog";
import { Dropdown, DropdownProps, DropdownChangeEvent } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import listUtils from "utils/listUtils";
import objectUtils from "utils/objectUtils";

export interface Category {
  id: string;
  display: string;
  isNew?: boolean;
}

export interface CategorySelectorProps {
  isVisible: boolean;
  title?: string;
  currentCategories: Category[];
  allCategories: Category[];
  onConfirm: (categories: Category[]) => void;
  onHide: () => void;
}

const CategorySelector = (props: CategorySelectorProps) => {
  const { isVisible, title, currentCategories, allCategories, onConfirm, onHide } = props;

  const header = title ?? "Modify Categories";

  const getEligibleCategories = (current: Category[], all: Category[]) => {
    return listUtils.outersect(all, current, (a: Category, b: Category) => {
      return a.display?.toLocaleLowerCase() === b.display?.toLocaleLowerCase();
    });
  };

  const [dropdownText, setDropdownText] = useState<string>("");
  const [state, setState] = useState<Category[]>(currentCategories ?? []);

  const addCategory = (newCategory: Category) => {
    const newState = [...state];
    newState.push(newCategory);
    setState(
      newState.sort((a, b) => {
        if (a?.display?.toLocaleLowerCase() > b?.display?.toLocaleLowerCase()) return 1;
        if (a?.display?.toLocaleLowerCase() < b?.display?.toLocaleLowerCase()) return -1;
        return 0;
      })
    );
    setDropdownText("");
  };

  const [eligibleCategories, setEligibleCategories] = useState<Category[]>(
    getEligibleCategories(state, allCategories) ?? []
  );

  useEffect(() => {
    const newEligibleCategories = getEligibleCategories(state, allCategories);
    setEligibleCategories(newEligibleCategories);
  }, [state, allCategories]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectedCategoryTemplate = (option: any, props: DropdownProps) => {
    if (option !== null) {
      return (
        <div className="country-item country-item-value">
          {/* <img
            alt={option.name}
            src="images/flag/flag_placeholder.png"
            onError={(e) =>
              ((e.target as any).src =
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            className={`flag flag-${option?.code?.toLowerCase()}`}
          /> */}
          <div>{option.display}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const categoryOptionTemplate = (option: Category) => {
    return (
      <div className="country-item">
        {/* <img
          alt={option.name}
          src="images/flag/flag_placeholder.png"
          onError={(e) =>
            ((e.target as any).src =
              "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
          }
          className={`flag flag-${option?.code?.toLowerCase()}`}
        /> */}
        <div>{option.display}</div>
      </div>
    );
  };

  const onCategorySubmit = (newCategory: string | Category) => {
    const categoryName = objectUtils.isString(newCategory)
      ? (newCategory as string)
      : (newCategory as Category)?.display;
    const matchingCategory = listUtils.firstOrDefault(
      eligibleCategories,
      (c) => c.display === categoryName
    );

    if (matchingCategory) {
      addCategory(matchingCategory);
      return;
    }

    setDropdownText(categoryName);
  };

  return (
    <Dialog
      visible={isVisible}
      header={header}
      blockScroll
      footer={() => {
        return (
          <div className="flex justify-content-between flex-wrap">
            <div className="flex align-items-center">
              <i>
                To manage all categories, please visit the{" "}
                <Link to={Routes.settings}>
                  <Icon name="cog" /> Settings
                </Link>{" "}
                page
              </i>
            </div>
            <Button
              type="secondary"
              subtype="text-only"
              label="Done"
              onClick={() => onConfirm(state)}
            />
          </div>
        );
      }}
      onHide={() => {
        onHide();
      }}
      breakpoints={{ "960px": "75vw", "640px": "100vw" }}
      style={{ width: "50vw" }}
    >
      <div style={{ width: "100%", marginTop: "1em" }}>
        <div>
          {state.map((c) => {
            return (
              <Chip
                key={c.display}
                value={c}
                removable
                onRemove={(removedCategory) => {
                  const newState = state.filter(
                    (c) =>
                      c.display.toLocaleLowerCase() !== removedCategory.display.toLocaleLowerCase()
                  );
                  setState(newState);
                }}
              />
            );
          })}
        </div>
        <div className="mt-3 flex align-items-center">
          <Dropdown
            value={dropdownText}
            options={eligibleCategories}
            onChange={(category: DropdownChangeEvent) => {
              const newCategory = category.value;
              onCategorySubmit(newCategory);
            }}
            placeholder="Select a category"
            filter
            filterBy="display"
            showFilterClear
            showOnFocus
            filterInputAutoFocus
            valueTemplate={selectedCategoryTemplate}
            itemTemplate={categoryOptionTemplate}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default CategorySelector;
