import "./App.scss";
import ConfigProvider from "config/ConfigProvider";
import { BrowserRouter as Router } from "react-router-dom";
import { MainRoutes } from "MainRoutes";
import Layout from "components/Layout";
import SiteProvider from "site/SiteProvider";
import { ToastHost } from "components/Toaster";
import AuthProvider from "auth/AuthProvider";

function App() {
  return (
    <ConfigProvider>
      <AuthProvider>
        <SiteProvider>
          <Router>
            <Layout>
              <MainRoutes />
            </Layout>
          </Router>
          <ToastHost />
        </SiteProvider>
      </AuthProvider>
    </ConfigProvider>
  );
}

export default App;
