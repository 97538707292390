import { Dropdown as PrimeReactDropdown } from "primereact/dropdown";
import style from "./index.module.scss";
import { stringUtils } from "utils/stringUtils";
export interface DropdownItem {
  label: string;
  value: string;
}

export interface DropdownProps {
  label?: string;
  items: DropdownItem[];
  selectedItem: DropdownItem;
  onChange: (newItem: DropdownItem) => void;
}

const Dropdown = (props: DropdownProps) => {
  const { label, items, selectedItem, onChange } = props;

  const htmlFor = stringUtils.convertSpecialCharactersToHyphens(label);
  return (
    <>
      <label htmlFor={htmlFor} className={style.label}>
        {label ?? "Unknown"}
      </label>
      <div className="p-inputgroup">
        <span className="p-inputgroup-addon">
          <i className={`pi pi-calendar`}></i>
        </span>
        <PrimeReactDropdown
          optionLabel="label"
          options={items}
          value={selectedItem.value}
          onChange={(e) => {
            if (e && onChange) {
              const matchingDropdownValue = items.find((i) => i.value === e.value);

              if (matchingDropdownValue) {
                onChange(matchingDropdownValue);
              }
            }
          }}
        />
      </div>
    </>
  );
};

export default Dropdown;
