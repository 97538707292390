import HorizontalLine from "components/HorizontalLine";
import { PropsWithChildren } from "react";

export interface QuestionAnswerProps {
  question: string;
  answer: string;
}

const QuestionAnswer = (props: PropsWithChildren<QuestionAnswerProps>) => {
  const { question, answer, children } = props;

  return (
    <div className="mb-1">
      <h3>Q: {question}</h3>
      <h4>A: {answer}</h4>
      <div className="ml-4">{children}</div>
      <HorizontalLine />
    </div>
  );
};

export default QuestionAnswer;
