import { PropsWithChildren, useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";

interface BoopProps {
  rotation: number;
  timingMs: number;
}

// https://css-tricks.com/video-screencasts/201-doing-booping/
const Boop = (props: PropsWithChildren<BoopProps>) => {
  const { rotation, timingMs, children } = props;

  const [isBooped, setIsBooped] = useState(false);

  const style = useSpring<React.CSSProperties>({
    display: "inline-block",
    backfaceVisibility: "hidden",
    transform: isBooped ? `rotate(${rotation}deg)` : `rotate(0deg)`,
    config: {
      tension: 300,
      friction: 10,
    },
  });

  useEffect(() => {
    if (!isBooped) {
      return;
    }
    const timeoutId = window.setTimeout(() => {
      setIsBooped(false);
    }, timingMs);

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [isBooped, timingMs]);

  const trigger = () => {
    setIsBooped(true);
  };

  return (
    <animated.span onMouseEnter={trigger} style={style}>
      {children}
    </animated.span>
  );
};

export default Boop;
