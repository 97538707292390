/* eslint-disable @typescript-eslint/no-explicit-any */
import { DataTable } from "primereact/datatable";
import { Column, ColumnBodyOptions } from "primereact/column";
import { OneToOneHundredType } from "utils/numberRange";
import { Prettify } from "utils/prettify";
import moment from "moment";
import { ReactNode } from "react";

export interface BasicField {
  label: string | ReactNode;
  sortable?: true | undefined;
  width?: OneToOneHundredType;
  isDate?: true | undefined;
}

export type FieldObj<T extends object> = Partial<
  {
    [K in keyof T]: Prettify<
      {
        body?: (value: T[K], data: T, options: TableFieldOptions) => any;
      } & BasicField
    >;
  }
>;

export type TableField<T extends object> = Prettify<
  FieldObj<T> & {
    readonly key: keyof T;
  }
>;

export const tableFieldsFactory = <T extends object>(fields: FieldObj<T>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.entries(fields).map(([key, value]: [string, any]) => ({
    key: key,
    ...value,
  })) as Prettify<TablePropsField<T>>[];
};

export interface TableFieldOptions {
  field: string;
  rowIndex: number;
}

export interface TableSimpleSort<T> {
  field: keyof T;
  direction: "asc" | "desc";
}

export interface TablePropsField<T> {
  readonly key: keyof T;
  body?: ((value: T[keyof T], data: T, options: TableFieldOptions) => any) | undefined;
  label: string | ReactNode;
  sortable?: true | undefined;
  width?: OneToOneHundredType | undefined;
  isDate?: true | undefined;
}

export interface TableProps<T> {
  fields?: TablePropsField<T>[];
  values: T[];
  simpleSort?: TableSimpleSort<T>;
  itemsPerPage?: number[];
  size?: "small" | "large" | undefined;
}

const Table = (props: TableProps<any>) => {
  const { fields, values, simpleSort, itemsPerPage, size } = props;

  const columns = fields
    ?.filter((field) => field !== undefined)
    .map((field: TablePropsField<any>) => {
      const key = field.key;
      const fieldType = field as any as {
        body?: (value: any, data: any, options: TableFieldOptions) => any;
      } & BasicField;

      const { label, body, width, isDate, sortable } = fieldType;

      const widthForStyle = width === undefined ? undefined : parseInt(width) + "%";

      let bodyOrDateBody = body;
      if (isDate && bodyOrDateBody === undefined) {
        bodyOrDateBody = (dateField: any) => {
          if (dateField === null || dateField === undefined) {
            return undefined;
          }

          return moment(dateField).format("M/D/yyyy");
        };
      }

      const finalBody =
        bodyOrDateBody !== undefined
          ? (data: any, options: ColumnBodyOptions) => {
              if (bodyOrDateBody) {
                return bodyOrDateBody(data[options.field], data, options);
              }

              return undefined;
            }
          : undefined;

      return (
        <Column
          key={key as any}
          field={key as any}
          header={label}
          body={finalBody}
          dataType={isDate === true ? "date" : undefined}
          sortable={key === simpleSort?.field || sortable}
          style={{ width: widthForStyle }}
        />
      );
    });

  const sortOrder = () => {
    const direction = simpleSort?.direction;

    if (direction !== undefined) {
      if (direction === "asc") {
        return 1;
      }

      if (direction === "desc") {
        return -1;
      }
    }

    return 0;
  };

  const paginatorTemplate = {
    layout:
      "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
  };

  const defaultListOfItemsPerPage: number[] = [10, 20, 50];

  const selectedItemsPerPage =
    itemsPerPage !== undefined && itemsPerPage.length > 0
      ? itemsPerPage
      : defaultListOfItemsPerPage;

  const defaultItemsPerPage = selectedItemsPerPage[0];

  return (
    <DataTable
      className="resale-minder-table"
      value={values}
      sortField={simpleSort?.field as any}
      sortOrder={sortOrder()}
      size={size}
      stripedRows
      paginator
      paginatorTemplate={paginatorTemplate as any}
      pageLinkSize={3}
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      rows={defaultItemsPerPage}
      rowsPerPageOptions={itemsPerPage}
      responsiveLayout="scroll"
      footer=""
    >
      {columns}
    </DataTable>
  );
};

export default Table;
