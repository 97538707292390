import {
  InventoryItemClient,
  InventoryItemDocumentClient,
  InventoryItemForm,
  InventoryItemFormDocument,
} from "api/GeneratedApiClients";
import { Routes } from "MainRoutes";
import inventoryConstants from "pages/inventoryConstants";
import toastUtils from "utils/toastUtils";
import uuid from "utils/uuid";
import { PageState } from "./useInventoryItemStore";
import { ActionMethodParameters } from "../../../hooks/useStore";
import { InventoryItemPageAction, InventoryItemPageActionType } from "./inventoryItemActions";

export interface InventoryItemActionMethods {
  loadPage: (id: string) => void;
  unloadPage: () => void;
  editPage: () => void;
  savePage: (updater: (form: InventoryItemForm) => void) => void;
  submitPage: (page: PageState) => void;
  showFileUploader: () => void;
  hideFileUploader: () => void;
  showCamera: () => void;
  hideCamera: () => void;
  showDeleteDocumentConfirmation: (document: InventoryItemFormDocument) => void;
  hideDeleteDocumentConfirmation: () => void;
  confirmDeleteDocument: () => void;
  showCategoryEditor: () => void;
  hideCategoryEditor: () => void;
  toggleListingHelper: (forceOption?: boolean) => void;
}

const getReducerActions = function (params: ActionMethodParameters<InventoryItemPageAction>) {
  const dispatch = params.dispatch;
  return {
    showLoader: (isVisible?: boolean) =>
      dispatch({ type: InventoryItemPageActionType.ShowLoader, isVisible: isVisible }),
    initialLoad: (form: InventoryItemForm, isNew: boolean) => {
      dispatch({
        type: InventoryItemPageActionType.InitialLoad,
        form: form,
        isNew: isNew,
      });
    },
    showInvalid: (form: InventoryItemForm) => {
      toastUtils.showInvalidMessage();
      dispatch({
        type: InventoryItemPageActionType.ShowInvalid,
        form: form,
      });
    },
    showSuccess: (form: InventoryItemForm) => {
      toastUtils.showSaveSuccessMessage();
      dispatch({
        type: InventoryItemPageActionType.ShowSuccess,
        form: form,
      });
    },
    showError: (error: string) => {
      toastUtils.showErrorMessage(error);
      dispatch({
        type: InventoryItemPageActionType.ShowError,
        error: error,
      });
    },
    savePageAction: (updater: (form: InventoryItemForm) => void) => {
      dispatch({
        type: InventoryItemPageActionType.Save,
        updater: updater,
      });
    },
    submitPageAction: (form: InventoryItemForm) => {
      toastUtils.showSaveSuccessMessage();
      dispatch({
        type: InventoryItemPageActionType.Submit,
        form: form,
      });
    },
    editPageAction: () => {
      dispatch({
        type: InventoryItemPageActionType.ChangePageViews,
        isEditing: true,
      });
    },
    toggleFileUploader: (flag: boolean) => {
      dispatch({
        type: InventoryItemPageActionType.ChangePageViews,
        isUploadingFile: flag,
      });
    },
    toggleCamera: (flag: boolean) => {
      dispatch({
        type: InventoryItemPageActionType.ChangePageViews,
        isTakingPicture: flag,
      });
    },
    toggleCategoryEditor: (flag: boolean) => {
      dispatch({
        type: InventoryItemPageActionType.ChangePageViews,
        isEditingCategories: flag,
      });
    },
    toggleDeleteDocument: (document?: InventoryItemFormDocument | undefined) => {
      dispatch({
        type: InventoryItemPageActionType.DeleteDocument,
        document: document,
        isConfirmingDeleteDocument: document !== undefined,
      });
    },
    confirmDeleteDocument: () => {
      dispatch({
        type: InventoryItemPageActionType.ConfirmDeleteDocument,
      });
    },
    toggleListingHelper: (forcedOption?: boolean) => {
      dispatch({
        type: InventoryItemPageActionType.ToggleListingHelper,
        forcedOption: forcedOption,
      });
    },
  };
};

export const getInventoryItemPageActions = (
  params: ActionMethodParameters<InventoryItemPageAction>
): InventoryItemActionMethods => {
  const { apiErrorHandler, navigate, dataClientFactory } = params;

  if (dataClientFactory === undefined) {
    throw new Error("Unable to retrieve Inventory Items without a DataClientFactory.");
  }

  const reducerActions = getReducerActions(params);

  const {
    showLoader,
    initialLoad,
    savePageAction,
    editPageAction,
    submitPageAction,
    toggleFileUploader,
    toggleCamera,
    toggleCategoryEditor,
    toggleDeleteDocument,
    showError,
    showInvalid,
  } = reducerActions;

  const defaultErrorHandlingOptions = (error: unknown, invalidObject: InventoryItemForm | null) => {
    const showErrorAction = () => showError("Unable to load Inventory Item.");
    apiErrorHandler.handle({
      error: error,
      errorAction: showErrorAction,
      invalidAction: () =>
        invalidObject === null ? showErrorAction() : showInvalid(invalidObject),
    });
  };

  const unloadPage = () => undefined;

  const loadPage = async (id: string) => {
    const isNewInventoryItem = id === inventoryConstants.newInventoryItemId;
    if (isNewInventoryItem) {
      showLoader(false);
      return;
    }

    showLoader();

    try {
      const inventoryItemClient = dataClientFactory.getClient(InventoryItemClient);
      const inventoryItemForm = await inventoryItemClient.getInventoryItem(id);

      if (inventoryItemForm !== null) {
        initialLoad(inventoryItemForm, false);
      } else {
        defaultErrorHandlingOptions(
          new Error("Unable to initially retrieve inventory item"),
          inventoryItemForm
        );
      }
    } catch (error: unknown) {
      defaultErrorHandlingOptions(error, null);
    }
  };

  const submitPage = async (page: PageState) => {
    showLoader();

    const { form } = page;

    try {
      form.id = page.isNew ? uuid.new() : form.id;

      const localImages = form.documents?.filter((d) => d.imageUrl?.startsWith("blob:")) || [];

      for (const localImage of localImages) {
        if (
          form.id !== undefined &&
          localImage.id !== undefined &&
          localImage.imageUrl !== undefined
        ) {
          const response = await fetch(localImage.imageUrl as string);
          const blob = await response.blob();
          const contentType = blob.type;

          const inventoryItemDocumentClient = dataClientFactory.getClient(
            InventoryItemDocumentClient
          );
          const documentItemForm = await inventoryItemDocumentClient.postDocument(
            form.id,
            localImage.id,
            contentType,
            blob
          );

          if (documentItemForm && documentItemForm?.imageUrl !== undefined) {
            localImage.id = documentItemForm.newInventoryItemDocumentId;
            localImage.containerName = documentItemForm.containerName;
            localImage.blobFolderPath = documentItemForm.blobFolderPath;
            localImage.imageUrl = documentItemForm.imageUrl;
          }
        }
      }

      const inventoryItemClient = dataClientFactory.getClient(InventoryItemClient);
      const inventoryItemForm = await inventoryItemClient.postInventoryItem(form);

      const isNewInventoryItem = page.isNew && inventoryItemForm?.id !== undefined;
      if (isNewInventoryItem) {
        toastUtils.showSaveSuccessMessage();
        navigate.toId(Routes.item, inventoryItemForm.id);
        return;
      }

      if (inventoryItemForm) {
        submitPageAction(inventoryItemForm);
        return;
      }

      showError(`Unable to load Inventory Item with name: "${form?.name}"`);
    } catch (error: unknown) {
      apiErrorHandler.handle({
        error: error,
        errorAction: () => showError(`Unable to load Inventory Item with name: "${form?.name}"`),
        invalidAction: () => showInvalid(form),
      });
    }
  };

  const savePage = (updater: (form: InventoryItemForm) => void) => savePageAction(updater);
  const editPage = () => editPageAction();
  const showFileUploader = () => toggleFileUploader(true);
  const hideFileUploader = () => toggleFileUploader(false);
  const showCamera = () => toggleCamera(true);
  const hideCamera = () => toggleCamera(false);
  const showDeleteDocumentConfirmation = (document: InventoryItemFormDocument) =>
    toggleDeleteDocument(document);
  const hideDeleteDocumentConfirmation = () => toggleDeleteDocument();
  const showCategoryEditor = () => toggleCategoryEditor(true);
  const hideCategoryEditor = () => toggleCategoryEditor(false);
  const confirmDeleteDocument = () => reducerActions.confirmDeleteDocument();
  const toggleListingHelper = (forcedOption?: boolean) =>
    reducerActions.toggleListingHelper(forcedOption);

  return {
    loadPage,
    unloadPage,
    editPage,
    savePage,
    submitPage,
    showFileUploader,
    hideFileUploader,
    showCamera,
    hideCamera,
    showDeleteDocumentConfirmation,
    hideDeleteDocumentConfirmation,
    confirmDeleteDocument,
    showCategoryEditor,
    hideCategoryEditor,
    toggleListingHelper,
  };
};
