import { SearchForm } from "api/GeneratedApiClients";
import uuid from "utils/uuid";
import useStore from "hooks/useStore";
import objectUtils from "utils/objectUtils";
import { getSearchPageActions, SearchActionMethods } from "./searchActionMethods";
import { SearchPageAction, SearchPageActionType } from "./searchActions";

export interface PageState {
  form: SearchForm;
  hasSearchedAtLeastOnce: boolean;
  isLoading: boolean;
}

const initialPageState: PageState = {
  form: {
    id: uuid.empty(),
    query: "",
    results: [],
    recentQueries: [],
  },
  hasSearchedAtLeastOnce: false,
  isLoading: false,
};

const pageReducer = function (readonlyState: PageState, action: SearchPageAction): PageState {
  const mutableState = objectUtils.deepCopyObject(readonlyState);

  switch (action.type) {
    case SearchPageActionType.ShowLoader:
      mutableState.isLoading = true;
      break;

    case SearchPageActionType.InitialLoad:
      mutableState.form = action.form;
      mutableState.hasSearchedAtLeastOnce = false;
      mutableState.isLoading = false;
      break;
    case SearchPageActionType.ShowInvalid:
    case SearchPageActionType.ShowSuccess:
      mutableState.form = action.form;
      mutableState.hasSearchedAtLeastOnce = false;
      mutableState.isLoading = false;
      break;
    case SearchPageActionType.ShowError:
      mutableState.isLoading = false;
      break;
    case SearchPageActionType.Save:
      if (mutableState.form && action.updater) {
        action.updater(mutableState.form);
        mutableState.hasSearchedAtLeastOnce = true;
      }
      break;
    case SearchPageActionType.Submit:
      mutableState.form = action.form;
      mutableState.hasSearchedAtLeastOnce = true;
      mutableState.isLoading = false;
      break;
  }

  return mutableState;
};

const useSearchStore = (): [PageState, SearchActionMethods] => {
  return useStore(initialPageState, pageReducer, getSearchPageActions);
};

export default useSearchStore;
