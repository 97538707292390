import { toaster } from "components/Toaster";
import { stringUtils } from "./stringUtils";

const showSaveSuccessMessage = function () {
  toaster.showSuccess("Success", "Saved Successfully!");
};

const showInvalidMessage = function () {
  toaster.showWarn(
    "Validation Error",
    "Something on the page is invalid. Please check the page for errors, address them, and then try again."
  );
};

const showErrorMessage = function (message?: string) {
  const errorDetails = !stringUtils.isNullOrWhitespace(message)
    ? (message as string)
    : "An error occurred. Please try again. Contact support if the issue keeps happening.";

  toaster.showError("Error", errorDetails, true);
};

const toastUtils = {
  showSaveSuccessMessage,
  showInvalidMessage,
  showErrorMessage,
};

export default toastUtils;
