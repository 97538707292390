import { InventoryForm, InventoryService } from "api";
import toastUtils from "utils/toastUtils";
import { ActionMethodParameters } from "../../../hooks/useStore";
import { InventoryPageAction, InventoryPageActionType } from "./inventoryActions";

export interface InventoryActionMethods {
  loadPage: () => void;
  unloadPage: () => void;
}

const getReducerActions = function (params: ActionMethodParameters<InventoryPageAction>) {
  const dispatch = params.dispatch;
  return {
    showLoader: () => dispatch({ type: InventoryPageActionType.ShowLoader }),
    initialLoad: (form: InventoryForm) => {
      dispatch({
        type: InventoryPageActionType.InitialLoad,
        form: form,
      });
    },
    showInvalid: (form: InventoryForm) => {
      toastUtils.showInvalidMessage();
      dispatch({
        type: InventoryPageActionType.ShowInvalid,
        form: form,
      });
    },
    showSuccess: (form: InventoryForm) => {
      toastUtils.showSaveSuccessMessage();
      dispatch({
        type: InventoryPageActionType.ShowSuccess,
        form: form,
      });
    },
    showError: (error: string) => {
      toastUtils.showErrorMessage();
      dispatch({
        type: InventoryPageActionType.ShowError,
        error: error,
      });
    },
  };
};

export const getInventoryPageActions = (
  params: ActionMethodParameters<InventoryPageAction>
): InventoryActionMethods => {
  const { apiErrorHandler, identity } = params;
  const reducerActions = getReducerActions(params);

  const { showLoader, initialLoad } = reducerActions;

  const defaultErrorHandlingOptions = (error: unknown, invalidObject: InventoryForm) => {
    apiErrorHandler.handle({
      error: error,
      errorAction: () => reducerActions.showError("Unable to load Inventory Page."),
      invalidAction: () => reducerActions.showInvalid(invalidObject),
    });
  };

  const unloadPage = () => undefined;

  const loadPage = async () => {
    showLoader();

    try {
      await identity.initializeAuthorization();
      const inventoryForm = await InventoryService.getInventory();
      initialLoad(inventoryForm);
    } catch (error: unknown) {
      defaultErrorHandlingOptions(error, {});
    }
  };

  return {
    loadPage,
    unloadPage,
  };
};
