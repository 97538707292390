import React from "react";
import style from "./index.module.scss";
import Icon from "components/Icon";

interface Props {
  isLoading: boolean;
  fullPage?: true;
}

const ProgressIndicator: React.FC<Props> = (props: Props) => {
  const { isLoading, fullPage } = props;

  if (!isLoading) {
    return <div />;
  }

  if (fullPage) {
    return (
      <div className={style.fullPageContainer}>
        <Icon className={style.loader} name="spinner" isSpinning size="xxlarge" />
        {/* <img key="penguin-loader" className={style.LoadingImage} alt="Loading" /> */}
      </div>
    );
  }

  return (
    <div className={style.subPageContainer}>
      <Icon className={style.loader} name="spinner" isSpinning size="xlarge" />
      {/* <img key="penguin-loader" className={style.LoadingImage} alt="Loading" /> */}
    </div>
  );
};

export default ProgressIndicator;
