import classNames from "classnames";
import { Ripple as PrimeReactRipple } from "primereact/ripple";
import { PropsWithChildren } from "react";

export interface RippleProps {
  className?: string | undefined;
}

const Ripple = (props: PropsWithChildren<RippleProps>) => {
  const { className, children } = props;

  const rippleClassName = "p-ripple";

  const classNameStrings =
    className === undefined ? classNames(rippleClassName) : classNames(rippleClassName, className);

  return (
    <div className={classNameStrings}>
      {children}
      <PrimeReactRipple />
    </div>
  );
};

export default Ripple;
