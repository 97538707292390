import { Panel as ReactPanel, PanelHeaderTemplateOptions } from "primereact/panel";
import { PropsWithChildren, useState } from "react";
import style from "./index.module.scss";
import classNames from "classnames";

interface BasePanelProps {
  isCollapsed?: boolean | undefined;
}

interface BasicHeaderPanelProps extends BasePanelProps {
  header: string;
}

interface TemplateHeaderPanelProps extends BasePanelProps {
  headerTemplate: JSX.Element;
}

type PanelProps = BasicHeaderPanelProps | TemplateHeaderPanelProps;

const Panel = (props: PropsWithChildren<PanelProps>) => {
  const { isCollapsed, children } = props;

  const header = (props as BasicHeaderPanelProps)?.header ?? undefined;
  const headerTemplate = (props as TemplateHeaderPanelProps)?.headerTemplate ?? undefined;

  const [isCollapsedState, setIsCollapsedState] = useState<boolean>(isCollapsed ?? false);

  const toggle = () => {
    setIsCollapsedState(!isCollapsedState);
  };

  const template = (options: PanelHeaderTemplateOptions) => {
    const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up";
    const collapsedPanelClassName = isCollapsedState ? style.collapsedPanel : undefined;
    const className = classNames(
      options.className,
      "justify-content-between",
      "cursor-pointer",
      collapsedPanelClassName
    );
    const titleClassName = `${options.titleClassName} pl-1`;

    if (headerTemplate !== undefined) {
      return (
        <div className={className} onClick={toggle}>
          {headerTemplate}
          <button className={options.togglerClassName} onClick={options.onTogglerClick}>
            <span className={toggleIcon}></span>
          </button>
        </div>
      );
    }

    return (
      <div className={className} onClick={toggle}>
        <span className={titleClassName}>{header}</span>
        <button className={options.togglerClassName} onClick={options.onTogglerClick}>
          <span className={toggleIcon}></span>
        </button>
      </div>
    );
  };

  return (
    <ReactPanel
      headerTemplate={template}
      toggleable
      onToggle={toggle}
      collapsed={isCollapsedState}
      collapseIcon="pi pi-chevron-down"
      expandIcon="pi pi-chevron-up"
      className="mb-5"
    >
      {children}
    </ReactPanel>
  );
};

export default Panel;
