import { GainLossForm } from "api/GeneratedApiClients";

export enum GainLossPageActionType {
  ShowLoader = 0,
  InitialLoad = 1,
  ShowSuccess = 2,
  ShowError = 3,
  ShowInvalid = 4,
  Save = 5,
}

interface ShowLoaderAction {
  type: GainLossPageActionType.ShowLoader;
}

interface InitialLoadAction {
  type: GainLossPageActionType.InitialLoad;
  form: GainLossForm;
}

interface ShowSuccessAction {
  type: GainLossPageActionType.ShowSuccess;
  form: GainLossForm;
}

interface ShowInvalidAction {
  type: GainLossPageActionType.ShowInvalid;
  form: GainLossForm;
}

interface ShowErrorAction {
  type: GainLossPageActionType.ShowError;
  error: string;
}
interface SavePageAction {
  type: GainLossPageActionType.Save;
  updater: (form: GainLossForm) => void;
}

export type GainLossPageAction =
  | ShowLoaderAction
  | InitialLoadAction
  | ShowSuccessAction
  | ShowInvalidAction
  | ShowErrorAction
  | SavePageAction;
