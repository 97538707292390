// https://www.primefaces.org/primeblocks-react/#/application/dashboard
// See "Sidebar"

import Icon from "components/Icon";
import Ripple from "components/Ripple";
import { Panel, PanelHeaderTemplateOptions } from "primereact/panel";
import { PropsWithChildren } from "react";
import { PrimeIcons } from "utils/PrimeReact/PrimeIcons";
import style from "./index.module.scss";

export interface SidebarCategoryProps {
  label: string;
  collapsed?: true;
  isVisible?: boolean | undefined;
}

const SidebarCategory = (props: PropsWithChildren<SidebarCategoryProps>) => {
  const { label, collapsed, isVisible, children } = props;

  if (isVisible === false) {
    return <></>;
  }

  const openIcon: PrimeIcons = "angle-down";
  const closeIcon: PrimeIcons = "angle-up";

  const template = (options: PanelHeaderTemplateOptions) => {
    return (
      <div onClick={options.onTogglerClick}>
        <Ripple className={style.sidebarCategory}>
          <span>{label}</span>
          <Icon name={options.collapsed ? openIcon : closeIcon} />
        </Ripple>
      </div>
    );
  };

  return (
    <Panel
      headerTemplate={template}
      toggleable
      collapsed={collapsed}
      className={style.sidebarCategoryPanel}
    >
      {children}
    </Panel>
  );
};

export default SidebarCategory;
