import moment from "moment";
import { useState } from "react";
import style from "./index.module.scss";

interface InventoryNoteProps {
  key: string | undefined;
  name: string;
  date: Date;
  dateAsText: string;
  noteText: string;
}

const InventoryNote = (props: InventoryNoteProps) => {
  const { name, date, dateAsText, noteText } = props;

  const [dateDisplay, setDateDisplay] = useState<string>(dateAsText);

  const toggleDateDisplay = () => {
    if (dateDisplay === dateAsText) {
      const momentDate = moment(date).format("M/D/YYYY H:MM A");
      setDateDisplay(momentDate);
      return;
    }

    setDateDisplay(dateAsText);
  };

  return (
    <div className={style.container}>
      <div className="flex align-center">
        <span className="mr-3 text-900 font-medium">{name}</span>
        <span onClick={toggleDateDisplay} className={`${style.date} text-500 text-sm`}>
          {dateDisplay}
        </span>
      </div>
      <p>{noteText}</p>
    </div>
  );
};

export default InventoryNote;
