import LayoutContent from "components/LayoutContent";
import { PanelHeaderTemplateOptions } from "primereact/panel";
import LoginIssues from "./loginIssues";
import UploadMultipleInventoryItems from "./uploadMultipleInventoryItems";

export interface HelpPanelProps {
  template: (title: string, options: PanelHeaderTemplateOptions) => JSX.Element;
}

const HelpPage = () => {
  return (
    <LayoutContent label="Help">
      <h2>FAQ</h2>
      <LoginIssues />
      <UploadMultipleInventoryItems />
    </LayoutContent>
  );
};

export default HelpPage;
