const isNullOrWhitespace = (
  s: string | null | undefined,
  returnTrueIfUndefinedString = false
): boolean => {
  if (s === null || s === undefined) {
    return true;
  }

  if (s.trim() === "undefined") {
    if (returnTrueIfUndefinedString) {
      return true;
    } else {
      console.log(
        "Discovered string is equivalent to the string \"undefined\". You may want to enable the flag 'returnTrueIfUndefinedString' in `stringUtils.isNullOrWhitespace`"
      );
    }
  }

  if (s.split(" ").join("") === "") {
    return true;
  }

  return false;
};

const utf8ToHex = (str: string) => {
  return Array.from(str)
    .map((c: string) =>
      c.charCodeAt(0) < 128
        ? c.charCodeAt(0).toString(16)
        : encodeURIComponent(c).replace(/%/g, "").toLowerCase()
    )
    .join("");
};

const firstOrDefault = (str: string | string[] | null | undefined): string | null | undefined => {
  if (str === undefined) {
    return undefined;
  }

  if (str === null) {
    return null;
  }

  if (str instanceof Array) {
    return str.length > 0 ? str[0] : undefined;
  }

  return str;
};

const formatAsCurrency = (num: number | null | undefined): string => {
  const value = num === null || num === undefined ? 0 : num;

  const usdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  });

  if (value < 0) {
    return `(${usdFormatter.format(value * -1)})`;
  }

  return usdFormatter.format(value);
};

const formatAsCommaDelimitedNumber = (
  num: number | null | undefined,
  decimals?: number
): string => {
  return (
    num?.toLocaleString("en-US", {
      minimumFractionDigits: decimals !== undefined ? decimals : 0,
    }) ?? ""
  );
};

const convertSpecialCharactersToHyphens = (str: string | null | undefined) => {
  return str?.replace(/[^a-zA-Z0-9_-]/g, "-");
};

export const stringUtils = {
  isNullOrWhitespace,
  utf8ToHex,
  firstOrDefault,
  formatAsCurrency,
  formatAsCommaDelimitedNumber,
  convertSpecialCharactersToHyphens,
};
